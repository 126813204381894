import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { device } from '../../../css/sizes';
import { ConfigGadget } from '@supersonic/config';
import { GadgetForm } from '../gadgetForm';
import { SearchSummary } from '../searchSummary';
import { useDropdown } from '../../../hooks/useDropdown';
import { Elevation } from '../../elevation';
import { XLg } from '../../icon';

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.sand_lighter1};
  padding: ${(props) => props.theme.spacing.unit * 2}px
    ${(props) => props.theme.spacing.unit * 2}px
    ${(props) => props.theme.spacing.unit * 1}px;
  @media ${device.lg} {
    background: ${(props) => props.theme.colors.sand_darker1};
    padding: ${(props) => props.theme.spacing.unit * 2}px
      ${(props) => props.theme.spacing.unit * 10}px;
  }
`;
const ContentWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  position: relative;
`;
const SearchSummaryWrapper = styled.div`
  @media ${device.xl} {
    display: none;
  }
`;
const GadgetDropdownElevation = styled(Elevation)<{
  isOpenOnSmallScreen: boolean;
}>`
  display: ${(props) => (props.isOpenOnSmallScreen ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  border-radius: ${(props) => props.theme.spacing.unit}px;
  background: ${(props) => props.theme.colors.white};
  z-index: 2;
  top: 0;
  padding: ${(props) => props.theme.spacing.unit * 2}px;
  @media ${device.xl} {
    display: block;
    position: static;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
  }
`;
const GadgetDropdownHeader = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.unit}px;
  display: flex;
  justify-content: flex-end;
  @media ${device.xl} {
    display: none;
  }
`;
const GadgetDropdownCloseButton = styled.button`
  padding: ${(props) => props.theme.spacing.unit}px;
  margin: -${(props) => props.theme.spacing.unit}px;
  color: ${(props) => props.theme.colors.black};
  border: none;
  background: none;
`;

export type Props = {
  config: ConfigGadget;
  locale: string;
  timezone: string;
};

export const SecondaryGadget = ({ config, locale, timezone }: Props) => {
  const [isGadgetDropdownOpen, setGadgetDropdownOpen] = useState(false);
  const searchSummaryWrapperRef = useRef<HTMLDivElement | null>(null);
  const gadgetDropdownRef = useRef<HTMLDivElement | null>(null);
  useDropdown({
    isOpen: isGadgetDropdownOpen,
    onRequestClose: () => setGadgetDropdownOpen(false),
    getTriggerElement: () => searchSummaryWrapperRef.current,
    getDropdownElement: () => gadgetDropdownRef.current,
  });

  return (
    <Wrapper>
      <ContentWrapper ref={searchSummaryWrapperRef}>
        <SearchSummaryWrapper>
          <SearchSummary
            locale={locale}
            timezone={timezone}
            onClick={() => setGadgetDropdownOpen(true)}
          />
        </SearchSummaryWrapper>
        <GadgetDropdownElevation
          ref={gadgetDropdownRef}
          level={1}
          isOpenOnSmallScreen={isGadgetDropdownOpen}
        >
          <GadgetDropdownHeader>
            <GadgetDropdownCloseButton
              onClick={() => setGadgetDropdownOpen(false)}
              aria-label="Close gadget"
            >
              <XLg width={16} height={16} />
            </GadgetDropdownCloseButton>
          </GadgetDropdownHeader>
          <GadgetForm config={config} locale={locale} timezone={timezone} />
        </GadgetDropdownElevation>
      </ContentWrapper>
    </Wrapper>
  );
};
