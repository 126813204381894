import React, { useRef } from 'react';
import { useCalendar, useButton } from 'react-aria';
import styled from 'styled-components';
import { useCalendarState } from '@react-stately/calendar';
import { createCalendar, CalendarDate } from '@internationalized/date';
import { CalendarGrid } from '../calendarGrid';
import { WeekDays } from '../weekDays';
import { Button } from '../../../button';

type Props = {
  value: CalendarDate | null;
  onChange: (newDate: CalendarDate) => void;
  minValue: CalendarDate;
  timezone: string;
  locale: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const MonthName = styled.div.attrs({ className: 'font-echo' })`
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing.unit * 2}px;
`;
const MiddleSection = styled.div`
  flex: 1;
  margin: 0 ${(props) => props.theme.spacing.unit * 3}px;
`;
const CalendarGridWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing.unit * 0.5}px;
  min-height: 248px;
`;

const ButtonWrapper = styled.div`
  flex: 0 0 auto;
`;

export const DesktopCalendar = (props: Props) => {
  const state = useCalendarState({
    value: props.value,
    onChange: props.onChange,
    minValue: props.minValue,
    locale: props.locale,
    createCalendar,
  });
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(
      {
        value: props.value,
        onChange: props.onChange,
        minValue: props.minValue,
      },
      state
    );
  const prevButton = useButton(prevButtonProps, prevButtonRef);
  const nextButton = useButton(nextButtonProps, nextButtonRef);

  return (
    <Wrapper {...calendarProps} data-testid="desktop-calendar">
      <ButtonWrapper>
        <Button
          type="secondary"
          iconOnly={true}
          size="small"
          icon="ArrowRight"
          iconRotated={true}
          buttonProps={prevButton.buttonProps}
          ref={prevButtonRef}
        />
      </ButtonWrapper>
      <MiddleSection>
        <MonthName>{title}</MonthName>
        <WeekDays
          size="regular"
          locale={props.locale}
          timezone={props.timezone}
        />
        <CalendarGridWrapper>
          <CalendarGrid state={state} locale={props.locale} />
        </CalendarGridWrapper>
      </MiddleSection>
      <ButtonWrapper>
        <Button
          type="secondary"
          iconOnly={true}
          size="small"
          icon="ArrowRight"
          buttonProps={nextButton.buttonProps}
          ref={nextButtonRef}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};
