import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import equal from 'fast-deep-equal';
import { Value } from './types';
import { useDestinationsSelector } from './useDestinationsSelector';
import { InputField, InputFieldHandle } from '../../inputField';
import { DesktopGadgetDropdown } from '../desktopGadgetDropdown';
import { MobileGadgetModal } from '../mobileGadgetModal';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { device } from '../../../css/sizes';
import { useCombobox } from 'downshift';
import { Menu } from './Menu';
import { getDestinationName } from './utils';
import { useStore } from '../../../storeProvider';
import { EMPTY_INPUT_TEXT } from './constants';

export type Props = {
  value: Value;
  fieldLabel: string;
  isOpen: boolean;
  error: boolean;
  onChange: (value: Value) => void;
  onOpen: () => void;
  onClose: () => void;
  onBack: () => void;
};

const Wrapper = styled.div`
  position: relative;
`;
const DestinationSelectorDesktopGadgetDropdown = styled(DesktopGadgetDropdown)`
  width: 340px;
`;

export const DestinationsSelector = (props: Props) => {
  const destinationsStore = useStore((state) => state.destinations);
  const isTabletOrDesktop = useMediaQuery(device.md);
  const selector = useDestinationsSelector({
    value: props.value,
    onChange: props.onChange,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<InputFieldHandle>(null);
  // this ref and timeout are needed to avoid calling onChange twice due to downshift bug
  // https://github.com/downshift-js/downshift/issues/1447
  const lastSelectedItem = useRef<Value>(null);
  useEffect(() => {
    setTimeout(() => {
      lastSelectedItem.current = null;
    }, 200);
  }, [props.value]);
  const [menuType, setMenuType] = useState<
    'popular destinations' | 'suggested destinations'
  >('popular destinations');

  useEffect(() => {
    destinationsStore.loadPopularDestinations();
  }, []);

  const items =
    menuType === 'popular destinations'
      ? destinationsStore.popularDestinations
      : destinationsStore.suggestedDestinations;

  const {
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
  } = useCombobox({
    onInputValueChange({ inputValue, type }) {
      if (type === useCombobox.stateChangeTypes.InputChange && inputValue) {
        setMenuType('suggested destinations');
        destinationsStore.loadSuggestedDestinations(inputValue);
      } else if (menuType !== 'popular destinations') {
        setMenuType('popular destinations');
      }
    },
    items,
    selectedItem: props.value,
    isOpen: props.isOpen,
    itemToString: (item) =>
      item ? getDestinationName(item) : EMPTY_INPUT_TEXT,
    onIsOpenChange: ({ isOpen, type }) => {
      if (
        !isTabletOrDesktop &&
        type === useCombobox.stateChangeTypes.InputBlur
      ) {
        return;
      }
      if (isOpen) {
        props.onOpen();
      } else {
        props.onClose();
      }
    },
    onSelectedItemChange: ({ selectedItem, type }) => {
      if (!selectedItem || equal(lastSelectedItem.current, selectedItem)) {
        return;
      }
      lastSelectedItem.current = selectedItem;
      selector.selectDestination(selectedItem);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return { ...changes, inputValue: '' };
        case useCombobox.stateChangeTypes.InputBlur:
          return isTabletOrDesktop
            ? {
                ...changes,
                inputValue: props.value
                  ? getDestinationName(props.value)
                  : EMPTY_INPUT_TEXT,
              }
            : changes;
        case useCombobox.stateChangeTypes.InputFocus:
          return { ...changes, inputValue: '' };
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return { ...changes, inputValue: '', isOpen: true };
        default:
          return changes;
      }
    },
    onStateChange: ({ type }) => {
      if (
        type === useCombobox.stateChangeTypes.ItemClick ||
        type === useCombobox.stateChangeTypes.InputKeyDownEnter
      ) {
        setTimeout(() => {
          (document.activeElement as HTMLElement).blur();
        }, 0);
      }
    },
  });

  const content = (
    <div data-testid="destination-selector-content">
      <Menu
        items={items}
        menuType={menuType}
        inputValue={inputValue}
        isSuggestedDestinationsLoading={
          destinationsStore.isLoadingSuggestedDestinations
        }
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
      />
    </div>
  );

  return (
    <Wrapper ref={wrapperRef} data-testid="destination-selector">
      <InputField
        size="regular"
        labelProps={getLabelProps({
          htmlFor: isTabletOrDesktop
            ? 'destination-selector-input'
            : 'destination-selector-button',
          // @ts-ignore data-* props are not described in downshift types, but they are passed down anyway
          'data-testid': 'destination-selector-input-field',
        })}
        leftIcon="GeoAlt"
        fieldLabel={props.fieldLabel}
        ref={inputFieldRef}
        active={props.isOpen}
        error={props.error}
        {...(isTabletOrDesktop
          ? {
              type: 'text-input',
              inputProps: getInputProps({
                id: 'destination-selector-input',
                placeholder: EMPTY_INPUT_TEXT,
              }),
            }
          : {
              type: 'button',
              label: props.value
                ? getDestinationName(props.value)
                : EMPTY_INPUT_TEXT,
              buttonProps: getToggleButtonProps({
                id: 'destination-selector-button',
                'aria-label': 'Select destination',
              }),
            })}
        {...(selector.allowClear
          ? {
              rightIcon: 'XLg',
              onRightIconClick: selector.clear,
              rightIconClickableAriaLabel: 'Clear',
            }
          : {})}
      />
      {isTabletOrDesktop ? (
        <DestinationSelectorDesktopGadgetDropdown
          testId="destination-selector-desktop-dropdown"
          isOpen={props.isOpen}
          onRequestClose={() => undefined}
          getTriggerElement={() => wrapperRef.current}
          noPaddingOnContent={true}
          restrictHeightToFitInViewport={true}
          maxHeight={400}
          contentWrapperProps={getMenuProps({}, { suppressRefError: true })}
          headerText={
            menuType === 'popular destinations'
              ? 'Start typing destination, resort or hotel name'
              : undefined
          }
        >
          {content}
        </DestinationSelectorDesktopGadgetDropdown>
      ) : (
        <MobileGadgetModal
          testId="destination-selector-mobile-modal"
          isOpen={props.isOpen}
          headerText="Find destinations"
          onClose={props.onClose}
          onBack={props.onBack}
          noPaddingOnContent={true}
          contentWrapperProps={getMenuProps({}, { suppressRefError: true })}
          headerAdditionalContent={
            <InputField
              size="regular"
              type="text-input"
              inputProps={getInputProps(
                {
                  placeholder: 'Where would you like to go?',
                },
                { suppressRefError: true }
              )}
            />
          }
        >
          {content}
        </MobileGadgetModal>
      )}
    </Wrapper>
  );
};
