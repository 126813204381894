import React from 'react';
import styled from 'styled-components';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

type Props = {
  priceLimitation: [number, number];
  priceRange: [number, number];
  onChange: (newPriceRange: [number, number]) => void;
  onAfterChange: () => void;
};

const RangeStyled = styled(Range)`
  &.rc-slider {
    padding: 4px 0 9px 0;
    height: auto;
    margin-bottom: ${(props) => props.theme.spacing.unit * 2}px;
  }
  & {
    .rc-slider-track {
      background: ${(props) =>
        props.theme.colors[props.theme.budgetHistogram.primaryColor]};
      height: 2px;
    }
    .rc-slider-rail {
      background: ${(props) => props.theme.colors.grey2};
      height: 2px;
    }
    .rc-slider-handle {
      width: 20px;
      height: 20px;
      border: ${(props) =>
        `8px solid ${
          props.theme.colors[props.theme.budgetHistogram.primaryColor]
        }`};
      background: white;
      margin-top: -10px;
    }
    .rc-slider-handle:active {
      box-shadow: none;
    }
    .rc-slider-handle-dragging {
      border: ${(props) =>
        `8px solid ${
          props.theme.colors[props.theme.budgetHistogram.primaryColor]
        }`}!important;
      box-shadow: 0 0 0 4px
        ${(props) =>
          props.theme.colors[
            props.theme.budgetHistogram.primaryColor
          ]}33 !important;
    }
  }
`;

const PriceRange = ({
  priceLimitation,
  priceRange,
  onChange,
  onAfterChange,
}: Props) => {
  return (
    <div
      data-testid="price-range"
      data-price-range-min={priceRange[0]}
      data-price-range-max={priceRange[1]}
    >
      <RangeStyled
        value={priceRange}
        min={priceLimitation[0]}
        max={priceLimitation[1]}
        // @ts-ignore issue in rc-slider types
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
    </div>
  );
};

export default PriceRange;
