import React, { useEffect, useState } from 'react';
import { type HeadFC, graphql, PageProps } from 'gatsby';
import { Layout, Results, useStore } from '@supersonic/components';
import {
  ConfigFooter,
  ConfigGadget,
  ConfigHeader,
  ConfigResults,
  ConfigTheme,
  ConfigResultsPage,
} from '@supersonic/config';
import { SearchResultsTypes } from '@supersonic/store';

type DataProps = {
  config: {
    holidaysResultsPage: ConfigResultsPage;
    theme: ConfigTheme;
    components: {
      footer: ConfigFooter;
      header: ConfigHeader;
      results: ConfigResults;
      gadget: ConfigGadget;
    };
  };
};

export type PageContextProps = {
  slug: string;
  results: SearchResultsTypes.ResultItem[];
  totalDeals: number;
  partners: SearchResultsTypes.Partners;
  searchCriteria: SearchResultsTypes.ExposedResultsSearchCriteria;
};

const PageContent = ({
  resultsConfig,
  resultsPageConfig,
  exposedResults,
  partners,
  totalDeals,
  searchCriteria,
}: {
  resultsConfig: ConfigResults;
  resultsPageConfig: ConfigResultsPage;
  exposedResults: PageContextProps['results'];
  partners: PageContextProps['partners'];
  totalDeals: PageContextProps['totalDeals'];
  searchCriteria: SearchResultsTypes.ExposedResultsSearchCriteria;
}) => {
  const searchResults = useStore((state) => state.searchResults);

  const [isClient, setClient] = useState(false);
  useEffect(() => {
    searchResults.setSearchParamsFromExposedResultsSearchCriteria(
      searchCriteria
    );
    searchResults.setSearchResults(exposedResults, partners, totalDeals);
    setClient(true);
  }, []);

  return (
    <Results
      dividerColor={resultsConfig?.theme?.dividerColor}
      compareMoreRowColor={resultsConfig?.theme?.compareMoreRowColor}
      linkColor={resultsConfig?.theme?.linkColor}
      linkHoverColor={resultsConfig?.theme?.linkHoverColor}
      labelTextColor={resultsConfig?.theme?.labelTextColor}
      priceTextColor={resultsConfig?.theme?.priceTextColor}
      uspBackgroundColor={resultsConfig?.theme?.uspBackgroundColor}
      uspIconColor={resultsConfig?.theme?.uspIconColor}
      outboundInboundLabelColor={
        resultsConfig?.theme?.outboundInboundLabelColor
      }
      flightInfoItemBackgroundColor={
        resultsConfig?.theme?.flightInfoItemBackgroundColor
      }
      flightInfoItemTextColor={resultsConfig?.theme?.flightInfoItemTextColor}
      boardBasisBackgroundColor={
        resultsConfig?.theme?.boardBasisBackgroundColor
      }
      yourSelectedPropertyBackgroundColor={
        resultsConfig?.theme?.yourSelectedPropertyBackgroundColor
      }
      offers={isClient ? searchResults.results : exposedResults || []}
      totalDeals={isClient ? searchResults.totalDeals : totalDeals || 0}
      searchProgress={100}
      totalResults={181}
      resultsPerPage={resultsPageConfig.resultsPerPage}
      currentPage={searchResults.currentPage}
    />
  );
};

const ExposedResultsPage = ({
  data,
  pageContext,
}: PageProps<DataProps, PageContextProps>) => {
  const themeConfig = data.config.theme;
  const footerConfig = data.config.components.footer;
  const headerConfig = data.config.components.header;
  const resultsConfig = data.config.components.results;
  const gadgetConfig = data.config.components.gadget;
  const resultsPageConfig = data.config.holidaysResultsPage;

  return (
    <Layout
      themeConfig={themeConfig}
      headerConfig={headerConfig}
      footerConfig={footerConfig}
      gadgetConfig={gadgetConfig}
      resultsPageConfig={resultsPageConfig}
    >
      <h1>{pageContext.slug}</h1>

      <PageContent
        exposedResults={pageContext.results}
        partners={pageContext.partners}
        totalDeals={pageContext.totalDeals}
        resultsConfig={resultsConfig}
        resultsPageConfig={resultsPageConfig}
        searchCriteria={pageContext.searchCriteria}
      />
    </Layout>
  );
};

export default ExposedResultsPage;

export const Head = (data) => {
  //data and pageContext comes down with the first param for some reason 🤷‍♂️ not what it says here https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/#properties
  return (
    <>
      <title>{data.pageContext.slug}</title>
      <meta name="twitter:url" content={`${data.pageContext.slug}`} />
    </>
  );
};

export const query = graphql`
  query SearchResultsPageQuery {
    config {
      holidaysResultsPage {
        brokerApi
        resultsPerPage
        title
        urlStem
      }
      ...Theme
      components {
        ...Footer
        ...Header
        ...Results
        ...Gadget
      }
    }
  }
`;
