import React, { useRef } from 'react';
import styled from 'styled-components';
import { Value } from './types';
import { useAirportsSelector } from './useAirportsSelector';
import { InputField, InputFieldHandle } from '../../inputField';
import { DesktopGadgetDropdown } from '../desktopGadgetDropdown';
import { MobileGadgetModal } from '../mobileGadgetModal';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { device } from '../../../css/sizes';
import { AIRPORT_GROUPS, AIRPORTS, MAX_NUMBER_OF_AIRPORTS } from './constants';
import { Checkbox } from '../../checkbox';
import {
  canSelectAirport,
  canSelectAirportsGroup,
  isAirportSelected,
  isAirportsGroupSelected,
} from './utils';

export type Props = {
  value: Value;
  fieldLabel: string;
  isOpen: boolean;
  error: boolean;
  onChange: (value: Value) => void;
  onOpen: () => void;
  onClose: () => void;
  onBack: () => void;
};

const Wrapper = styled.div`
  position: relative;
`;

const AirportsSelectorDesktopGadgetDropdown = styled(DesktopGadgetDropdown)`
  width: 600px;
  @media ${device.lg} {
    width: 880px;
  }
`;

const CheckboxGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${(props) => props.theme.spacing.unit * 2}px;
  margin-bottom: ${(props) => props.theme.spacing.unit * 3}px;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  @media ${device.md} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
const CheckboxGroupLabel = styled.div.attrs({
  className: 'font-echo font-echo--bold',
})`
  margin-bottom: ${(props) => props.theme.spacing.unit * 3}px;
`;

export const AirportsSelector = (props: Props) => {
  const isTabletOrDesktop = useMediaQuery(device.md);
  const selector = useAirportsSelector({
    value: props.value,
    onChange: props.onChange,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<InputFieldHandle>(null);

  const content = (
    <div data-testid="airports-selector-content">
      <CheckboxGroupLabel>By region</CheckboxGroupLabel>
      <CheckboxGroupWrapper>
        {AIRPORT_GROUPS.map((airportsGroup) => (
          <Checkbox
            key={airportsGroup.name}
            checked={isAirportsGroupSelected(airportsGroup, props.value)}
            text={airportsGroup.name}
            ariaLabel={airportsGroup.name}
            disabled={
              !isAirportsGroupSelected(airportsGroup, props.value) &&
              !canSelectAirportsGroup(airportsGroup, props.value)
            }
            onChange={() => {
              if (isAirportsGroupSelected(airportsGroup, props.value)) {
                selector.unselectAirportsGroup(airportsGroup);
              } else {
                selector.selectAirportsGroup(airportsGroup);
              }
            }}
          />
        ))}
      </CheckboxGroupWrapper>
      <CheckboxGroupLabel>By airport</CheckboxGroupLabel>
      <CheckboxGroupWrapper>
        {AIRPORTS.map((airport) => (
          <Checkbox
            key={airport.code}
            checked={isAirportSelected(airport.code, props.value)}
            text={airport.name}
            ariaLabel={airport.name}
            disabled={
              !isAirportSelected(airport.code, props.value) &&
              !canSelectAirport(props.value)
            }
            onChange={() => {
              if (isAirportSelected(airport.code, props.value)) {
                selector.unselectAirport(airport);
              } else {
                selector.selectAirport(airport);
              }
            }}
          />
        ))}
      </CheckboxGroupWrapper>
    </div>
  );

  return (
    <Wrapper ref={wrapperRef} data-testid="airports-selector">
      <InputField
        type="button"
        size="regular"
        label={selector.label}
        labelProps={{
          htmlFor: 'airports-selector-button',
          'data-testid': 'airports-selector-input-field',
        }}
        leftIcon="Airplane"
        fieldLabel={props.fieldLabel}
        ref={inputFieldRef}
        active={props.isOpen}
        error={props.error}
        buttonProps={{
          id: 'airports-selector-button',
          'aria-label': 'Select airports',
          onClick: () => (props.isOpen ? props.onClose() : props.onOpen()),
        }}
        {...(selector.allowClear
          ? {
              rightIcon: 'XLg',
              onRightIconClick: selector.clear,
              rightIconClickableAriaLabel: 'Clear',
            }
          : {})}
      />
      {isTabletOrDesktop ? (
        <AirportsSelectorDesktopGadgetDropdown
          testId="airports-selector-desktop-dropdown"
          isOpen={props.isOpen}
          onRequestClose={(reason) => {
            props.onClose();
            if (reason === 'escape-key') {
              inputFieldRef.current?.focus();
            }
          }}
          getTriggerElement={() => wrapperRef.current}
          headerText={`Select up to ${MAX_NUMBER_OF_AIRPORTS} airports`}
          footerPrimaryButton={{
            onClick: props.onClose,
            text: 'Done',
            disabled: !selector.allowDone,
          }}
          footerSecondaryButton={{
            onClick: selector.clear,
            text: 'Clear',
            disabled: !selector.allowClear,
          }}
          restrictHeightToFitInViewport
        >
          {content}
        </AirportsSelectorDesktopGadgetDropdown>
      ) : (
        <MobileGadgetModal
          testId="airports-selector-mobile-modal"
          isOpen={props.isOpen}
          headerText="Choose your airports"
          contentHeaderText={`Select up to ${MAX_NUMBER_OF_AIRPORTS} airports`}
          onClose={props.onClose}
          onBack={props.onBack}
          footerPrimaryButton={{
            onClick: props.onClose,
            text: 'Done',
            disabled: !selector.allowDone,
          }}
          footerSecondaryButton={{
            onClick: selector.clear,
            text: 'Clear',
            disabled: !selector.allowClear,
          }}
        >
          {content}
        </MobileGadgetModal>
      )}
    </Wrapper>
  );
};
