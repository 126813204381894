import React, { useState } from 'react';
import styled, { ThemeColor } from 'styled-components';
import { device } from '../../../css/sizes';
import { SubnavLink } from '../subnavLink';
import { Chevron } from '../../icon';
import {
  ConfigNavigationSubsection,
  ConfigNavigationSubnavTheme,
} from '@supersonic/config';

const SubNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  @media ${device.lg} {
    background: ${(props) => props.theme.colors.white};
    padding-top: ${(props) => props.theme.spacing._3};
    &:before {
      content: '';
      background: ${(props) => props.theme.colors.sand};
      border-bottom-left-radius: ${(props) => props.theme.spacing._1};
      position: absolute;
      left: 0;
      top: 0;
      height: calc(100% + ${(props) => props.theme.spacing._5});
      width: 27%;
      @media ${device.xxl} {
        width: 302px;
      }
    }
  }
`;

const SubsectionButton = styled.button.attrs({
  className: 'font-echo',
})<{
  toggle: boolean;
}>`
  align-items: center;
  background: transparent;
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey2};
  color: ${(props) => props.theme.colors.black};
  display: flex;
  padding: ${(props) => props.theme.spacing._2} 0;
  text-align: left;
  width: 100%;
  position: relative;
  transition: background .3s ease-out;
  @media ${device.lg} {
    background: ${(props) =>
      props.toggle ? props.theme.colors.white : props.theme.colors.sand};
    border: 0;
    padding: ${(props) => props.theme.spacing._2}
    ${(props) => props.theme.spacing._2} ${(props) => props.theme.spacing._2}
    ${(props) => props.theme.spacing._5};
    width: 27%;
  }
  @media ${device.xxl} {
    width: 302px;
  }
  &:hover, &:focus-visible {
    background: ${(props) => props.theme.colors.white};
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px ${(props) => props.theme.spacing._05} ${(props) =>
  props.theme.colors.black} inset;
`;

const SubsectionButtonChevron = styled.span<{
  toggle: boolean;
  chevronColor?: ThemeColor;
}>`
  color: ${(props) =>
    props.chevronColor
      ? props.theme.colors[props.chevronColor]
      : props.theme.colors.black};
  flex: none;
  margin-left: auto;
  transition: transform 0.3s;
  transform: ${(props) => (props.toggle ? 'rotate(180deg)' : 'rotate(0deg)')};
  @media ${device.lg} {
    opacity: ${(props) => (props.toggle ? '1' : '0')};
    transform: rotate(270deg) scale(0.67);
  }
`;

const LinksList = styled.ul.attrs({
  className: 'font-foxtrot',
})<{
  toggle: boolean;
}>`
  display: ${(props) => (props.toggle ? 'grid' : 'none')};
  list-style: none;
  padding: ${(props) => props.theme.spacing._2} 0 0;
  @media ${device.lg} {
    position: absolute;
    right: 0;
    top: 0;
    width: 73%;
    padding: ${(props) => props.theme.spacing._5};
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.xxl} {
    width: calc(100% - 302px);
  }
`;

interface SubNavProps {
  subnav?: ConfigNavigationSubsection[];
  theme?: ConfigNavigationSubnavTheme;
  name: string;
}

export const SubNav3Level = ({ subnav, theme, name }: SubNavProps) => {
  const [subsectionOpen, setSubsectionOpen] = useState(0);

  const ifDesktop = () => {
    const mql = window.matchMedia(device.lg);
    return mql.matches;
  };

  const handeMouseEnter = (index: number) => {
    if (ifDesktop()) {
      setSubsectionOpen(index);
    }
  };

  const handeMouseLeave = (index: number) => {
    if (ifDesktop()) {
      setSubsectionOpen(index);
    }
  };

  return (
    <SubNavContainer>
      {subnav &&
        subnav.map((item, index) => {
          return (
            <div key={index}>
              <SubsectionButton
                onClick={() => setSubsectionOpen(index)}
                onMouseEnter={() => handeMouseEnter(index)}
                onMouseLeave={() => handeMouseLeave(index)}
                toggle={subsectionOpen === index}
                aria-expanded={subsectionOpen === index}
                aria-controls={`${name
                  .toLowerCase()
                  .replaceAll(' ', '-')
                  .replaceAll(' ', '-')}-${item.title
                  .toLowerCase()
                  .replaceAll(' ', '-')}-sub-menu`}
              >
                {item.title}
                <SubsectionButtonChevron
                  chevronColor={theme?.chevronColor}
                  toggle={subsectionOpen === index}
                >
                  <Chevron height={24} width={24} />
                </SubsectionButtonChevron>
              </SubsectionButton>

              <LinksList
                toggle={subsectionOpen === index}
                id={`${name.toLowerCase().replaceAll(' ', '-')}-${item.title
                  .toLowerCase()
                  .replaceAll(' ', '-')}-sub-menu`}
              >
                {item.links &&
                  item.links.map((link, idx) => {
                    return (
                      <li key={idx}>
                        <SubnavLink href={link.link} text={link.text} />
                      </li>
                    );
                  })}
              </LinksList>
            </div>
          );
        })}
    </SubNavContainer>
  );
};
