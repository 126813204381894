import React, { useMemo } from 'react';
import { useDateFormatter } from 'react-aria';
import { startOfWeek, today } from '@internationalized/date';
import styled from 'styled-components';
import { device } from '../../../../css/sizes';

type Size = 'regular' | 'small';

const Wrapper = styled.div.attrs({ className: 'font-golf' })<{ size: Size }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: ${(props) =>
    props.size === 'small'
      ? props.theme.spacing.unit * 2.75
      : props.theme.spacing.unit * 4.75}px;
  gap: ${(props) => props.theme.spacing.unit * 1.5}px;
  justify-items: center;
  align-items: center;
  color: ${(props) => props.theme.colors.grey6};
  @media ${device.md} {
    gap: ${(props) => props.theme.spacing.unit}px;
  }
`;

type Props = {
  size: Size;
  timezone: string;
  locale: string;
};

export const WeekDays = (props: Props) => {
  const dayFormatter = useDateFormatter({
    weekday: 'short',
    timeZone: props.timezone,
  });
  const weekDays = useMemo(() => {
    const weekStart = startOfWeek(today(props.timezone), props.locale);
    return [...new Array(7).keys()].map((index) => {
      const date = weekStart.add({ days: index });
      const dateDay = date.toDate(props.timezone);
      return dayFormatter.format(dateDay);
    });
  }, [props.timezone, props.locale, dayFormatter]);

  return (
    <Wrapper size={props.size}>
      {weekDays.map((day, index) => (
        <div key={`${index}-${day}`}>{day}</div>
      ))}
    </Wrapper>
  );
};
