import React, { useEffect } from 'react';
import { type HeadFC, graphql, PageProps } from 'gatsby';
import {
  Layout,
  Results,
  useStore,
  breakpointsNum,
} from '@supersonic/components';
import {
  ConfigFooter,
  ConfigHeader,
  ConfigResults,
  ConfigTheme,
  ConfigGadget,
  ConfigResultsPage,
} from '@supersonic/config';

type DataProps = {
  config: {
    holidaysResultsPage: ConfigResultsPage;
    theme: ConfigTheme;
    components: {
      footer: ConfigFooter;
      header: ConfigHeader;
      results: ConfigResults;
      gadget: ConfigGadget;
    };
  };
};

const PageContent = ({
  resultsConfig,
  resultsPageConfig,
}: {
  resultsConfig: ConfigResults;
  resultsPageConfig: ConfigResultsPage;
}) => {
  const [searchResults, advertising] = useStore((state) => [
    state.searchResults,
    state.advertising,
  ]);
  useEffect(() => {
    const init = async () => {
      searchResults.setSearchParamsFromUrl();
      searchResults.setSearchQueryParams();
      await searchResults.loadSearch();
      advertising.loadConditionalUnits(
        (window.innerWidth < breakpointsNum.md && 'mobile') ||
          (window.innerWidth < breakpointsNum.lg && 'tablet') ||
          'desktop'
      );
    };
    init();
  }, []);
  return (
    <Results
      dividerColor={resultsConfig?.theme?.dividerColor}
      compareMoreRowColor={resultsConfig?.theme?.compareMoreRowColor}
      linkColor={resultsConfig?.theme?.linkColor}
      linkHoverColor={resultsConfig?.theme?.linkHoverColor}
      labelTextColor={resultsConfig?.theme?.labelTextColor}
      priceTextColor={resultsConfig?.theme?.priceTextColor}
      uspBackgroundColor={resultsConfig?.theme?.uspBackgroundColor}
      uspIconColor={resultsConfig?.theme?.uspIconColor}
      outboundInboundLabelColor={
        resultsConfig?.theme?.outboundInboundLabelColor
      }
      flightInfoItemBackgroundColor={
        resultsConfig?.theme?.flightInfoItemBackgroundColor
      }
      flightInfoItemTextColor={resultsConfig?.theme?.flightInfoItemTextColor}
      boardBasisBackgroundColor={
        resultsConfig?.theme?.boardBasisBackgroundColor
      }
      yourSelectedPropertyBackgroundColor={
        resultsConfig?.theme?.yourSelectedPropertyBackgroundColor
      }
      offers={searchResults.results}
      totalDeals={searchResults.totalDeals}
      totalResults={searchResults.resultsTotal}
      resultsPerPage={resultsPageConfig.resultsPerPage}
      currentPage={searchResults.currentPage}
      searchProgress={searchResults.pollingCompletion}
      filterData={searchResults.filterData}
      inlineAdSlot0={advertising.inlineAdSlot0}
      inlineAdSlot1={advertising.inlineAdSlot1}
      ribbonAdSlot0={advertising.ribbonAdSlot0}
    />
  );
};

const IndexPage = ({ data }: PageProps<DataProps>) => {
  const themeConfig = data.config.theme;
  const footerConfig = data.config.components.footer;
  const headerConfig = data.config.components.header;
  const resultsConfig = data.config.components.results;
  const resultsPageConfig = data.config.holidaysResultsPage;
  const gadgetConfig = data.config.components.gadget;

  return (
    <main>
      <Layout
        themeConfig={themeConfig}
        headerConfig={headerConfig}
        footerConfig={footerConfig}
        gadgetConfig={gadgetConfig}
        resultsPageConfig={resultsPageConfig}
      >
        <PageContent
          resultsConfig={resultsConfig}
          resultsPageConfig={resultsPageConfig}
        />
      </Layout>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Results Page</title>;

// this is duplicated on the index page. If we miss something, it throws an error. Maybe we can pass this query down from gatsby - just do the one query
export const query = graphql`
  query SearchResultsPageQuery {
    config {
      holidaysResultsPage {
        brokerApi
        advertisingApi
        site
        resultsPerPage
        title
        urlStem
      }
      ...Theme
      components {
        ...Footer
        ...Header
        ...Results
        ...Gadget
      }
    }
  }
`;
