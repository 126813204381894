import React from 'react';
import { BaseSvg, type BaseSvgProps } from '../../../baseSvg';

export const Icelolly = (baseSvgProps: Omit<BaseSvgProps, 'children'>) => {
  return (
    <BaseSvg
      {...baseSvgProps}
      height={baseSvgProps.height || 24}
      width={baseSvgProps.width || 80}
      viewBox={baseSvgProps.viewBox || '0 0 80 24'}
      ariaHidden={'ariaHidden' in baseSvgProps ? baseSvgProps.ariaHidden : true}
      ariaLabel={baseSvgProps.ariaLabel || 'Icelolly'}
    >
      <path d="M1.962 9.189A1.53 1.53 0 0 0 .428 10.71v8.2a1.53 1.53 0 0 0 1.534 1.523 1.53 1.53 0 0 0 1.533-1.522V10.71c0-.848-.685-1.522-1.533-1.522ZM19.782 9.189c-3.137 0-5.332 2.532-5.332 5.622v.035c0 3.3 2.393 5.576 5.634 5.576 1.952 0 3.404-.767 4.414-2.01l-1.754-1.556c-.848.79-1.591 1.103-2.613 1.103-1.371 0-2.335-.72-2.637-2.114h7.515c.035-.523.035-.801.035-.801-.011-3.056-1.66-5.855-5.262-5.855Zm-2.335 4.751c.244-1.394 1.069-2.3 2.335-2.3 1.29 0 2.091.93 2.277 2.3h-4.612ZM29.215 5.344h-3.056v14.834h3.056V5.344Z" />
      <path d="M10.338 17.785c-1.673 0-2.742-1.324-2.742-2.985v-.035c0-1.592 1.092-2.94 2.614-2.94 1.092 0 1.766.465 2.451 1.186l1.87-2.01c-1.01-1.127-2.277-1.812-4.31-1.812-3.299 0-5.657 2.532-5.657 5.622v.035c0 3.078 2.382 5.576 5.623 5.576 2.149 0 3.369-.825 4.414-1.975l-1.789-1.812c-.743.709-1.452 1.15-2.474 1.15ZM57.257 15.95l-2.683-6.564h-3.241l4.565 10.78-.639 1.987c-.046.14-.081.302-.081.453 0 .767.627 1.394 1.394 1.394.639 0 1.173-.43 1.347-1.01l4.31-12.71.337-.905h-3.183l-2.126 6.575ZM36.22 9.189c-3.358 0-5.855 2.52-5.855 5.622v.035c0 3.102 2.474 5.576 5.82 5.576 3.357 0 5.854-2.52 5.854-5.622v-.035c0-3.102-2.474-5.576-5.82-5.576Zm2.8 5.657c0 1.592-1.046 2.94-2.8 2.94-1.697 0-2.835-1.395-2.835-2.986v-.035c0-1.592 1.046-2.94 2.8-2.94 1.696 0 2.834 1.395 2.834 2.986v.035ZM46.152 5.344h-3.056v14.834h3.056V5.344ZM50.787 5.309h-3.055v14.834h3.055V5.31ZM1.962 4.867c-.883 0-1.603.72-1.603 1.603s.72 1.604 1.603 1.604 1.603-.72 1.603-1.604c0-.882-.72-1.603-1.603-1.603Z" />
      <path d="M66.62.825C66.051.279 65.284 0 64.332 0c-.964 0-1.731.279-2.3.825-.57.546-.86 1.266-.86 2.172V8.4c0 .093.07.162.163.162h1.963v2.463c0 .14.023.279.081.395.14.337.465.581.837.616H64.425c.383-.035.697-.279.848-.616.046-.128.081-.255.081-.395V8.561h1.963c.093 0 .163-.07.163-.162V3.009c0-.906-.29-1.638-.86-2.184ZM65.54 4.17v2.614h-2.416V2.985c0-.336.116-.627.348-.87.233-.245.523-.36.872-.36.336 0 .627.115.86.36a1.2 1.2 0 0 1 .348.87V4.17h-.012ZM67.817 19.504c-.21.221-.465.407-.744.535-.29.14-.627.197-1.01.197-.384 0-.72-.058-1.023-.174a2.022 2.022 0 0 1-1.22-1.243 2.863 2.863 0 0 1-.162-.976c0-.325.058-.639.174-.93.116-.29.279-.545.488-.766.21-.22.465-.395.767-.523.302-.127.627-.186.987-.186s.697.07 1 .198c.301.128.557.314.766.534l-.72.732a1.32 1.32 0 0 0-.453-.348 1.387 1.387 0 0 0-.593-.128c-.22 0-.406.035-.569.116a1.2 1.2 0 0 0-.627.767 2.04 2.04 0 0 0-.082.523c0 .174.024.337.082.51a1.4 1.4 0 0 0 .232.454c.105.14.232.244.395.325.163.081.349.128.57.128.22 0 .429-.047.615-.128s.337-.197.465-.348l.662.731Z" />
      <path d="M67.689 17.843c0-.325.058-.639.174-.93.116-.29.279-.545.488-.766.21-.22.465-.395.767-.523.302-.127.627-.186.987-.186s.686.07.988.186c.302.128.546.302.766.523.21.22.372.476.488.767.116.29.175.604.175.93 0 .325-.059.638-.175.928-.116.29-.279.547-.488.767-.209.22-.464.395-.766.523a2.494 2.494 0 0 1-.988.186c-.36 0-.685-.058-.987-.186a2.365 2.365 0 0 1-.767-.523 2.399 2.399 0 0 1-.488-.767 2.492 2.492 0 0 1-.174-.929Zm2.416 1.429c.22 0 .407-.046.57-.128.162-.081.29-.197.394-.337.105-.139.186-.29.233-.464.046-.163.081-.337.081-.5 0-.162-.023-.325-.081-.488a1.342 1.342 0 0 0-.233-.464 1.383 1.383 0 0 0-.395-.337 1.247 1.247 0 0 0-.569-.128c-.22 0-.406.046-.569.128-.163.081-.29.197-.395.337-.105.139-.186.29-.232.464a1.943 1.943 0 0 0-.082.488c0 .163.024.337.082.5.046.162.127.325.232.464.105.14.232.256.395.337.163.093.349.128.57.128ZM72.893 15.601h1.08l.024.511c.058-.058.128-.127.197-.209.07-.07.163-.14.268-.197.104-.058.232-.105.36-.14.14-.035.29-.058.476-.058.592 0 1.046.221 1.347.65.233-.232.465-.406.71-.499.243-.104.522-.15.835-.15.57 0 1 .162 1.313.499.302.337.453.79.453 1.359v2.73H78.83v-2.719c0-.29-.07-.51-.22-.662-.14-.15-.337-.232-.593-.232-.15 0-.29.035-.418.093a.993.993 0 0 0-.325.255 1.02 1.02 0 0 0-.21.384c-.046.14-.069.302-.069.465v2.439h-1.127v-2.718c0-.29-.07-.511-.22-.662-.14-.151-.337-.233-.593-.233-.151 0-.29.035-.418.093a.993.993 0 0 0-.325.256 1.02 1.02 0 0 0-.21.383 1.5 1.5 0 0 0-.07.465v2.44h-1.126V15.6h-.012ZM62.938 18.773a.766.766 0 1 1-.767-.767c.43 0 .767.337.767.767Z" />
    </BaseSvg>
  );
};
