import React from 'react';
import styled, { css, ThemeColor } from 'styled-components';
import { device } from '../../css/sizes';
import { tracking } from './utils';
import { Container } from '../container';
import { Link } from '../link';
import {
  Facebook,
  Icelolly,
  Instagram,
  Pinterest,
  TravelSupermarket,
  Twitter,
  YouTube,
} from '../icon';
import {
  ConfigFooterColumn,
  ConfigFooterLink,
  ConfigFooterLogo,
  ConfigFooterSocialLink,
} from '@supersonic/config';

const iconComponents = {
  facebook: Facebook,
  icelolly: Icelolly,
  instagram: Instagram,
  pinterest: Pinterest,
  travelsupermarket: TravelSupermarket,
  twitter: Twitter,
  youtube: YouTube,
};

const renderSocialMediaIcon = (name: string) => {
  const iconName = name.toLowerCase();
  if (iconName in iconComponents) {
    const Component = iconComponents[iconName as keyof typeof iconComponents];
    return <Component ariaLabel={name} height={24} width={24} />;
  }
  return null;
};

const renderCompanyLogos = (name: ConfigFooterLogo, idx: number) => {
  const Component = iconComponents[name];
  return <Component key={idx} />;
};

const FooterContainer = styled.footer<{ backgroundColor?: ThemeColor }>`
  background: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.grey4};
  padding: ${(props) => props.theme.spacing._8} 0;
`;

const PlainText = css`
  margin: 0;
`;

const CopyrightText = styled.p.attrs({
  className: 'font-hotel',
})<{ textColor?: ThemeColor }>`
  color: ${(props) =>
    props.textColor
      ? props.theme.colors[props.textColor]
      : props.theme.colors.black};
  ${PlainText};
  text-align: center;
  @media ${device.lg} {
    text-align: right;
    margin-left: auto;
  }
`;

const unstyledList = css`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LinksGrid = styled.nav`
  display: grid;
  gap: ${(props) => props.theme.spacing._2};
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  @media ${device.lg} {
    gap: ${(props) => props.theme.spacing._3};
    grid-template-columns: repeat(6, 1fr);
  }
`;

const LinksHeading = styled.h2.attrs({
  className: 'font-echo',
})<{ headingColor?: ThemeColor }>`
  color: ${(props) =>
    props.headingColor
      ? props.theme.colors[props.headingColor]
      : props.theme.colors.black};
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacing._3};
`;

const LinksList = styled.ul.attrs({
  className: 'font-foxtrot',
})`
  ${unstyledList};
  margin-bottom: ${(props) => props.theme.spacing._5};
`;

const LinksItem = styled.li`
  margin-bottom: ${(props) => props.theme.spacing._2};
  &:last-child {
    margin-bottom: 0;
  }
`;

const SocialLinks = styled.ul<{ borderColor?: ThemeColor }>`
  ${unstyledList};
  border-top: 1px solid
    ${(props) =>
      props.borderColor
        ? props.theme.colors[props.borderColor]
        : props.theme.colors.black};
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  gap: ${(props) => props.theme.spacing._3};
  padding: ${(props) => props.theme.spacing._4} 0;
  @media ${device.lg} {
    border: 0;
    grid-column: 5 / -1;
    justify-content: end;
    padding: 0;
  }
`;

const CompanyLogos = styled.div<{ logosColor?: ThemeColor }>`
  color: ${(props) =>
    props.logosColor
      ? props.theme.colors[props.logosColor]
      : props.theme.colors.black};
  display: flex;
  width: initial;
  @media ${device.lg} {
    width: 100%;
  }
`;

const SecondarySection = styled.div<{ borderColor?: ThemeColor }>`
  border-top: 1px solid
    ${(props) =>
      props.borderColor
        ? props.theme.colors[props.borderColor]
        : props.theme.colors.black};
  padding-top: ${(props) => props.theme.spacing._3};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing._3};
  @media ${device.lg} {
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const SecondaryLinks = styled.ul.attrs({
  className: 'font-golf',
})`
  ${unstyledList};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing._3};
  text-align: center;
  @media ${device.lg} {
    justify-content: flex-end;
    width: 100%;
  }
`;

const SecondaryLinksAndCopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing._3};
  order: 1;
  @media ${device.lg} {
    justify-content: flex-end;
    width: 100%;
    order: 0;
  }
`;

export interface FooterProps {
  backgroundColor?: ThemeColor;
  borderColor?: ThemeColor;
  columns?: ConfigFooterColumn[];
  copyright?: string;
  headingColor?: ThemeColor;
  linkColor?: ThemeColor;
  linkHoverColor?: ThemeColor;
  logos?: ConfigFooterLogo[];
  logosColor?: ThemeColor;
  secondaryLinks?: ConfigFooterLink[];
  socialLinks?: ConfigFooterSocialLink[];
  textColor?: ThemeColor;
  socialMediaIconColor?: ThemeColor;
}

export const Footer = ({
  backgroundColor,
  borderColor,
  columns,
  copyright,
  headingColor,
  linkColor,
  linkHoverColor,
  logos,
  logosColor,
  secondaryLinks,
  socialLinks,
  textColor,
  socialMediaIconColor,
}: FooterProps) => {
  return (
    <FooterContainer
      data-testid="footer-container"
      backgroundColor={backgroundColor}
    >
      <Container>
        <LinksGrid>
          {columns &&
            columns.map((column, index) => {
              return (
                <div key={index}>
                  <LinksHeading headingColor={headingColor}>
                    {column.heading}
                  </LinksHeading>
                  <LinksList>
                    {column.links.map((link, idx) => (
                      <LinksItem
                        key={idx}
                        onClick={() => tracking('Links', link.text, 'click')}
                      >
                        <Link
                          linkColor={linkColor}
                          linkHoverColor={linkHoverColor}
                          to={link.link}
                        >
                          {link.text}
                        </Link>
                      </LinksItem>
                    ))}
                  </LinksList>
                </div>
              );
            })}

          <SocialLinks
            borderColor={borderColor}
            data-testid="footer-social-links"
          >
            {socialLinks &&
              socialLinks.map((link, idx) => (
                <li
                  key={idx}
                  onClick={() =>
                    tracking(
                      'Social Media Links',
                      link.name,
                      `share ${link.name}`
                    )
                  }
                >
                  <Link
                    linkColor={socialMediaIconColor}
                    linkHoverColor={linkHoverColor}
                    to={link.link}
                    ariaLabel={`Follow us on ${link.name}`}
                  >
                    {renderSocialMediaIcon(link.name)}
                  </Link>
                </li>
              ))}
          </SocialLinks>
        </LinksGrid>

        <SecondarySection borderColor={borderColor}>
          <SecondaryLinksAndCopyrightWrapper>
            <SecondaryLinks>
              {secondaryLinks &&
                secondaryLinks.map((link, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => tracking('Legal', link.text, 'click')}
                    >
                      <Link
                        linkColor={linkColor}
                        linkHoverColor={linkHoverColor}
                        to={link.link}
                      >
                        {link.text}
                      </Link>
                    </li>
                  );
                })}
            </SecondaryLinks>

            <CopyrightText textColor={textColor}>
              Copyright © {new Date().getFullYear()} {copyright}
            </CopyrightText>
          </SecondaryLinksAndCopyrightWrapper>

          <CompanyLogos
            logosColor={logosColor}
            data-testid="footer-company-logos-container"
          >
            {logos?.map((logo, idx) => renderCompanyLogos(logo, idx))}
          </CompanyLogos>
        </SecondarySection>
      </Container>
    </FooterContainer>
  );
};
