import React, {
  HTMLAttributes,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Elevation } from '../../elevation';
import { Button } from '../../button';
import {
  useDropdown,
  GetElementFn,
  OnRequestCloseFn,
} from '../../../hooks/useDropdown';

export type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  headerText?: string;
  testId?: string;
  footerPrimaryButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  footerSecondaryButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  contentWrapperProps?: HTMLAttributes<HTMLDivElement>;
  getTriggerElement: GetElementFn;
  onRequestClose: OnRequestCloseFn;
  noPaddingOnContent?: boolean;
  className?: string;
  restrictHeightToFitInViewport?: boolean;
  maxHeight?: number;
};

const WrapperElevation = styled(Elevation)<{ maxHeight: null | number }>`
  position: absolute;
  width: 100%;
  border-radius: ${(props) => props.theme.spacing.unit * 2}px;
  background: ${(props) => props.theme.colors.white};
  z-index: 2;
  top: calc(100% + ${(props) => props.theme.spacing.unit}px);
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.maxHeight &&
    css<{ maxHeight: number | null }>`
      min-height: 200px;
      max-height: ${(props) => props.maxHeight}px;
      ${Content} {
        overflow: auto;
      }
    `};
`;
const Header = styled.div.attrs({ className: 'font-golf' })`
  padding: ${(props) => props.theme.spacing.unit * 2}px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  flex: 0 0 auto;
`;
const Content = styled.div<{ noPadding: boolean }>`
  padding: ${(props) =>
    props.noPadding
      ? 0
      : `${props.theme.spacing.unit * 3}px ${props.theme.spacing.unit * 2}px`};
  position: relative;
`;
const FooterWrapper = styled.div`
  overflow: hidden;
  padding-top: 20px;
  margin-top: -20px;
  border-bottom-left-radius: ${(props) => props.theme.spacing.unit * 2}px;
  border-bottom-right-radius: ${(props) => props.theme.spacing.unit * 2}px;
  flex: 0 0 auto;
`;
const FooterElevation = styled(Elevation)`
  padding: ${(props) => props.theme.spacing.unit * 2}px;
  border-bottom-left-radius: ${(props) => props.theme.spacing.unit * 2}px;
  border-bottom-right-radius: ${(props) => props.theme.spacing.unit * 2}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.spacing.unit * 2}px;
`;

const DesktopGadgetDropdownOpen = (props: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [availableHeight, setAvailableHeight] = useState<null | number>(null);
  useDropdown({
    getTriggerElement: props.getTriggerElement,
    getDropdownElement: () => wrapperRef.current,
    onRequestClose: props.onRequestClose,
    isOpen: true,
  });

  useLayoutEffect(() => {
    if (!props.restrictHeightToFitInViewport || !wrapperRef.current) return;
    const { bottom, y } = wrapperRef.current.getBoundingClientRect();
    const isDropdownHeightFitInViewport = window.innerHeight > bottom;
    setAvailableHeight(
      isDropdownHeightFitInViewport ? null : window.innerHeight - y
    );
  }, []);

  return (
    <WrapperElevation
      level={2}
      ref={wrapperRef}
      data-testid={props.testId}
      maxHeight={
        props.maxHeight && availableHeight
          ? Math.min(props.maxHeight, availableHeight)
          : props.maxHeight || availableHeight
      }
      className={props.className}
    >
      {props.headerText && <Header>{props.headerText}</Header>}
      <Content
        noPadding={props.noPaddingOnContent || false}
        {...props.contentWrapperProps}
      >
        {props.children}
      </Content>
      {(props.footerPrimaryButton || props.footerSecondaryButton) && (
        <FooterWrapper>
          <FooterElevation level={3}>
            {props.footerSecondaryButton && (
              <Button
                text={props.footerSecondaryButton.text}
                size="small"
                type="tertiary"
                onClick={props.footerSecondaryButton.onClick}
                disabled={props.footerSecondaryButton.disabled}
              />
            )}
            {props.footerPrimaryButton && (
              <Button
                text={props.footerPrimaryButton.text}
                size="small"
                onClick={props.footerPrimaryButton.onClick}
                disabled={props.footerPrimaryButton.disabled}
              />
            )}
          </FooterElevation>
        </FooterWrapper>
      )}
    </WrapperElevation>
  );
};

export const DesktopGadgetDropdown = (props: Props) => {
  if (!props.isOpen) return null;

  return <DesktopGadgetDropdownOpen {...props} />;
};
