import { createGlobalStyle } from 'styled-components';
import { device } from './sizes';

import { normalize } from 'styled-normalize';
//it would be ever so lovely to generate lots of/all these vars from the config files
// maybe use map? https://codesandbox.io/s/737z4ywvq?file=/src/globals.js
const GlobalStyle = createGlobalStyle`
  ${normalize}
  :root {
    font-family: ${(props) => props.theme.fontFamily};
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  iframe, img, object, svg {
    display: block;
    vertical-align: middle;
  }

  svg, img {
    max-width: 100%;
    height: auto;
  }

  button {
    cursor: pointer;
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }  

  @media (prefers-reduced-motion: reduce) {
    *, *:before, *:after {
      transition-duration: 0ms !important;
      scroll-behavior: auto!important;
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }

  /*
  how to handle different weight fonts?!
  currently have a .font-regular, .font-medium, .font-bold
  for any overrides - eg font-hotel is bold 
  getting into utility class territory here (My kingdom for a Tailwind!). Mixed with styled-components :S 
  is that good or bad?

  What about
  https://styled-system.com/articles/
  - https://varun.ca/styled-system/
  - https://codesandbox.io/s/2vv327jqjp?from-embed=&file=/src/index.js

  or a Text component?
    - could have props for size and a weight override
    <Text size={golf} weight={bold|medium}>

    or

    <TextAlpha color={primary}>foo bar</TextAlpha>
    <TextDelta color={primary}>foo bar</TextDelta>

    Could we make a h1, h2, p etc? Maybe that could be a prop too?

    This would enforce corect sizes. And reduce repeat font-size declarations?

  */


  .font-alfa {
    font-size: ${(props) => props.theme.fontSizes.alfa.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.alfa.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.alfa.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.alfa.desktop.fontSize};
      font-weight:  ${(props) => props.theme.fontSizes.alfa.desktop.fontWeight};
      line-height: ${(props) => props.theme.fontSizes.alfa.desktop.lineHeight};
    }
  }

  .font-bravo {
    font-size: ${(props) => props.theme.fontSizes.bravo.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.bravo.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.bravo.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.bravo.desktop.fontSize};
      font-weight:  ${(props) =>
        props.theme.fontSizes.bravo.desktop.fontWeight};
      line-height: ${(props) => props.theme.fontSizes.bravo.desktop.lineHeight};
    }
  }

  .font-charlie {
    font-size: ${(props) => props.theme.fontSizes.charlie.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.charlie.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.charlie.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.charlie.desktop.fontSize};
      font-weight:  ${(props) =>
        props.theme.fontSizes.charlie.desktop.fontWeight};
      line-height: ${(props) =>
        props.theme.fontSizes.charlie.desktop.lineHeight};
    }
  }

  .font-delta {
    font-size: ${(props) => props.theme.fontSizes.delta.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.delta.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.delta.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.delta.desktop.fontSize};
      font-weight:  ${(props) =>
        props.theme.fontSizes.delta.desktop.fontWeight};
      line-height: ${(props) => props.theme.fontSizes.delta.desktop.lineHeight};
    }
  }

  .font-delta-desktop {
    font-size: ${(props) => props.theme.fontSizes.delta.desktop.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.delta.desktop.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.delta.desktop.lineHeight};
  }

  .font-echo {
    font-size: ${(props) => props.theme.fontSizes.echo.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.echo.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.echo.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.echo.desktop.fontSize};
      font-weight:  ${(props) => props.theme.fontSizes.echo.desktop.fontWeight};
      line-height: ${(props) => props.theme.fontSizes.echo.desktop.lineHeight};
    }
  }

  .font-foxtrot {
    font-size: ${(props) => props.theme.fontSizes.foxtrot.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.foxtrot.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.foxtrot.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.foxtrot.desktop.fontSize};
      font-weight:  ${(props) =>
        props.theme.fontSizes.foxtrot.desktop.fontWeight};
      line-height: ${(props) =>
        props.theme.fontSizes.foxtrot.desktop.lineHeight};
    }
  }

  .font-foxtrot--bold {
    font-weight: ${(props) =>
      props.theme.fontSizes.foxtrot.boldVariant.fontWeight};
  }

  .font-golf {
    font-size: ${(props) => props.theme.fontSizes.golf.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.golf.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.golf.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.golf.desktop.fontSize};
      font-weight:  ${(props) => props.theme.fontSizes.golf.desktop.fontWeight};
      line-height: ${(props) => props.theme.fontSizes.golf.desktop.lineHeight};
    }
  }

  .font-golf--bold {
    font-weight: ${(props) =>
      props.theme.fontSizes.golf.boldVariant.fontWeight};
  }

  .font-hotel {
    font-size: ${(props) => props.theme.fontSizes.hotel.mobile.fontSize};
    font-weight:  ${(props) => props.theme.fontSizes.hotel.mobile.fontWeight};
    line-height: ${(props) => props.theme.fontSizes.hotel.mobile.lineHeight};
    @media ${device.lg} { 
      font-size: ${(props) => props.theme.fontSizes.hotel.desktop.fontSize};
      font-weight:  ${(props) =>
        props.theme.fontSizes.hotel.desktop.fontWeight};
      line-height: ${(props) => props.theme.fontSizes.hotel.desktop.lineHeight};
    }
  }

  .font-hotel--bold {
    font-weight: ${(props) =>
      props.theme.fontSizes.hotel.boldVariant.fontWeight};
  }

  .font-regular {
    font-weight: 400;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-bold {
    font-weight: 800;
  }
`;
export default GlobalStyle;
