import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTheme } from 'styled-components';

declare const window: Window & { _googCsa: Record<string, unknown>[] };

export interface AdsenseProps {
  query: string | null;
  slotId: string | null;
}

export const Adsense = ({ query, slotId }: AdsenseProps) => {
  const theme = useTheme();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '500px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (
      !window._googCsa &&
      theme.advertising.adsense.pubId &&
      slotId &&
      query &&
      inView
    ) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/adsense/search/ads.js';
      script.async = true;
      document.head.appendChild(script);

      // prettier-ignore
      // @ts-ignore google adsense
      (function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(arguments)},g[o]['t']=1*new Date})(window,'_googCsa'); // eslint-disable-line
      const pageOptions = {
        pubId: theme.advertising.adsense.pubId,
        query: query,
        styleId: slotId,
        adsafe: 'high',
      };

      const adblock1 = {
        container: 'afscontainer1',
        number: 3,
      };
      // @ts-ignore google adsense
      _googCsa('ads', pageOptions, adblock1);
    }
  }, [theme.advertising.adsense.pubId, query, slotId, inView]);

  return (
    <div ref={ref}>
      <div id="afscontainer1"></div>
    </div>
  );
};
