import React from 'react';
import { BaseSvg, type BaseSvgProps } from '../../../baseSvg';

export const List = (baseSvgProps: Omit<BaseSvgProps, 'children'>) => {
  return (
    <BaseSvg {...baseSvgProps}>
      <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    </BaseSvg>
  );
};
