import React from 'react';
import styled, { useTheme } from 'styled-components';
import { generateCloudflareImageSrc } from '../../../../utils/generateCloudflareImageSrc';
import { generateCloudflareImageSrcset } from '../../../../utils/generateCloudflareImageSrcset';
import { breakpoints, device } from '../../../../css/sizes';

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 200px;
  @media ${device.md} {
    width: 305px;
    min-height: 220px;
    height: auto;
  }
`;

type Props = { path: string };

export const CustomImage = ({ path }: Props) => {
  const theme = useTheme();
  return (
    <Image
      alt="Advertiser image"
      src={generateCloudflareImageSrc(path, theme.cloudflareImagesUrl, {
        width: 305,
        height: 220,
      })}
      sizes={`(min-width: ${breakpoints.md}) 305px, calc(100vw - ${
        theme.spacing.unit * 4
      }px)`}
      srcSet={generateCloudflareImageSrcset(path, theme.cloudflareImagesUrl, [
        [{ width: 288, height: 200 }, 288],
        [{ width: 288, height: 200, dpr: 2 }, 576],
        [{ width: 343, height: 200 }, 343],
        [{ width: 343, height: 200, dpr: 2 }, 686],
        [{ width: 382, height: 200 }, 382],
        [{ width: 382, height: 200, dpr: 2 }, 764],
        [{ width: 305, height: 220 }, 305],
        [{ width: 305, height: 220, dpr: 2 }, 610],
      ])}
    />
  );
};
