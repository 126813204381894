import React from 'react';
import styled from 'styled-components';
import { InputField } from '../../inputField';

type Props = {
  priceRangeInputMinValue: string;
  priceRangeInputMaxValue: string;
  onPriceRangeMinInputChange: (inputValue: string) => void;
  onPriceRangeMaxInputChange: (inputValue: string) => void;
  onPriceRangeMinInputFocus: () => void;
  onPriceRangeMaxInputFocus: () => void;
  onPriceRangeMinInputBlur: () => void;
  onPriceRangeMaxInputBlur: () => void;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Divider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colors.grey3};
  margin: 0 ${(props) => props.theme.spacing.unit}px;
  width: ${(props) => props.theme.spacing.unit}px;
  flex: 0 0 auto;
`;

const PriceRangeInputs = ({
  priceRangeInputMinValue,
  priceRangeInputMaxValue,
  onPriceRangeMinInputChange,
  onPriceRangeMaxInputChange,
  onPriceRangeMinInputFocus,
  onPriceRangeMaxInputFocus,
  onPriceRangeMinInputBlur,
  onPriceRangeMaxInputBlur,
}: Props) => {
  return (
    <Wrapper>
      <InputField
        type="text-input"
        size="small"
        fieldLabel="Min. Price"
        inputPrefix="£"
        inputProps={{
          value: priceRangeInputMinValue,
          onChange: (e) => {
            onPriceRangeMinInputChange(e.currentTarget.value);
          },
          onFocus: onPriceRangeMinInputFocus,
          onBlur: onPriceRangeMinInputBlur,
        }}
      />
      <Divider />
      <InputField
        type="text-input"
        size="small"
        fieldLabel="Max. Price"
        inputPrefix="£"
        inputProps={{
          value: priceRangeInputMaxValue,
          onChange: (e) => {
            onPriceRangeMaxInputChange(e.currentTarget.value);
          },
          onFocus: onPriceRangeMaxInputFocus,
          onBlur: onPriceRangeMaxInputBlur,
        }}
      />
    </Wrapper>
  );
};

export default PriceRangeInputs;
