import React, { Children, useEffect, useId, useRef, useState } from 'react';
import styled from 'styled-components';

const OverallWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitlesWrapper = styled.div<{
  position: number;
  tabsCount: number;
}>`
  background: ${(props) =>
    props.theme.colors[props.theme?.tabsConfig?.inactiveTabBackgroundColor]};
  display: inline-flex;
  margin: auto;
  border-radius: ${(props) => props.theme.spacing._4};
  position: relative;

  &:after {
    content: '';
    width: ${(props) => `${100 / props.tabsCount}%`};
    height: 100%;
    background: ${(props) =>
      props.theme.colors[props.theme?.tabsConfig?.activeTabBackgroundColor]};
    position: absolute;
    border-radius: ${(props) => props.theme.spacing._4};
    transition: transform 0.25s;
    transform: translateX(
      ${(props) => (props.position > 0 ? `${props.position}00%` : '0')}
    );
  }
`;

const TabTitle = styled.a.attrs({
  className: 'font-golf',
})<{
  active: boolean;
  noAnimation?: boolean;
}>`
  position: relative;
  z-index: 1;
  margin: auto;
  text-decoration: none;
  min-width: 125px;
  text-align: center;
  border-radius: ${(props) => props.theme.spacing._4};
  padding: ${(props) => props.theme.spacing._05}
    ${(props) => props.theme.spacing._2};
  color: ${(props) =>
    props.active
      ? props.theme.colors[props.theme?.tabsConfig?.activeTabTextColor]
      : props.theme.colors[props.theme?.tabsConfig?.inactiveTabTextColor]};
  ${(props) => (props.noAnimation ? null : 'transition: all 0.25s;')}
  transition: color 0.25s;
`;

const TabsWrapper = styled.div.attrs({
  className: 'no-scrollbar',
})<{
  noSwipe?: boolean;
  noAnimation?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  ${(props) =>
    props.noAnimation ? null : 'transition: height 0.25s ease 0.5s;'}
  ${(props) => (props.noAnimation ? null : 'scroll-behavior: smooth;')}
  ${(props) =>
    props.noAnimation || props.noSwipe
      ? 'overflow: hidden'
      : 'overflow-x: auto'};
  overflow-y: hidden;

  @media (prefers-reduced-motion: reduce) {
    overflow: hidden;
  }
`;

const TabContent = styled.div`
  scroll-snap-align: start;
  width: 100%;
  min-width: 100%;
`;

export interface TabsProps {
  autoAdjustHeight?: boolean;
  noSwipe?: boolean;
  noAnimation?: boolean;
  children: React.ReactNode;
}

export const Tabs = ({
  autoAdjustHeight,
  noSwipe,
  noAnimation,
  children,
}: TabsProps) => {
  let sizedAfterScroll = false;
  const tabsId = useId();
  const scrollerRef = useRef<HTMLDivElement>(null);
  const tabTitles: Array<string> = [];
  const [currentTab, setCurrentTab] = useState(1);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      tabTitles.push(child.props.title);
    }
  });

  const tabsCount = tabTitles.length;

  const calcWidths = () => {
    if (scrollerRef.current) {
      setWrapperWidth(scrollerRef.current.offsetWidth);
    }
  };

  const adjustHeight = () => {
    if (scrollerRef.current) {
      const currentTabElement = document.getElementById(
        `tabs${tabsId}tab${currentTab}`
      );
      scrollerRef.current.style.height = `${currentTabElement?.clientHeight}px`;
    }
  };

  const handleScroll = () => {
    if (scrollerRef.current) {
      const targetTab =
        Math.round(scrollerRef.current.scrollLeft / wrapperWidth) + 1;
      setCurrentTab(targetTab);
      if (autoAdjustHeight) {
        if (!sizedAfterScroll) {
          setTimeout(() => {
            adjustHeight();
            sizedAfterScroll = false;
          }, 100);
          sizedAfterScroll = true;
        }
      }
    }
  };

  useEffect(() => {
    calcWidths();
    window.addEventListener('resize', calcWidths);
    if (autoAdjustHeight) {
      adjustHeight();
      window.addEventListener('resize', adjustHeight);
    }
  });

  const goToTab = (
    e: React.MouseEvent<HTMLAnchorElement>,
    targetTab: number
  ) => {
    e.preventDefault();
    if (scrollerRef.current) {
      scrollerRef.current.scrollLeft = wrapperWidth * targetTab;
    }
  };

  return (
    <OverallWrapper>
      <TitlesWrapper
        role="tablist"
        position={currentTab - 1}
        tabsCount={tabsCount}
      >
        {tabTitles.map((title: string, index: number) => {
          const active = index + 1 === currentTab;
          return (
            <TabTitle
              key={index}
              role="tab"
              id={`tablist${tabsId}tab${index + 1}`}
              aria-controls={`tabs${tabsId}tab${index + 1}`}
              aria-selected={active}
              href={`#tabs${tabsId}tab${index + 1}`}
              onClick={(e) => goToTab(e, index)}
              active={active}
            >
              {title}
            </TabTitle>
          );
        })}
      </TitlesWrapper>
      <TabsWrapper
        ref={scrollerRef}
        onScroll={handleScroll}
        noSwipe={noSwipe}
        noAnimation={noAnimation}
        tabIndex={0}
      >
        {Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <Tab
                tabsId={tabsId}
                tabIndex={index + 1}
                children={child.props.children}
              ></Tab>
            );
          } else {
            return null;
          }
        })}
      </TabsWrapper>
    </OverallWrapper>
  );
};

export interface TabProps {
  title?: string;
  tabsId?: string;
  tabIndex?: number;
  children: React.ReactNode;
}

export const Tab = ({ tabsId, tabIndex, children }: TabProps) => {
  return (
    <TabContent
      id={`tabs${tabsId}tab${tabIndex}`}
      role="tabpanel"
      aria-labelledby={`tablist${tabsId}tab${tabIndex}`}
    >
      {children}
    </TabContent>
  );
};
