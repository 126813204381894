import { GA4, GA4Event } from '../../scripts/ga4';

const ga4 = new GA4();

export const tracking = (
  type: 'title' | 'image' | 'discover more',
  content_display: string
) => {
  const eventConfig: GA4Event = {
    event: 'blog',
    product: undefined,
    module: '',
    action: 'click',
    content_display: '',
  };
  eventConfig.module = type;
  eventConfig.content_display = content_display;
  ga4.send(eventConfig);
};
