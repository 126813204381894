import React, { useState, useRef } from 'react';
import { SearchResultsTypes } from '@supersonic/store';
import { FlightInfo } from '../flightInfo';
import styled, { ThemeColor, useTheme, css } from 'styled-components';
import { device } from '../../../css/sizes';
import { Button } from '../../button';
import { ArrowLeftRight, Check, Tick } from '../../icon';
import { Modal } from '../../modal';
import { ModalHeader } from '../modalHeader';
import { Accordion } from '../../accordion';
import { Tabs, Tab } from '../../tabs';
import { Tooltip, TipPositions } from '../../tooltip';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useStore } from '../../../storeProvider';
import { BOARD_BASIS_LOOKUP, USP_CODES } from '../constants';

export const RowContainer = styled.div<{
  firstRow?: boolean;
  showProviderLogo?: boolean;
  compareMoreRowBorderColor?: ThemeColor;
}>`
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media ${device.md} {
    display: grid;
    grid-template-columns: ${(props) =>
      props.showProviderLogo
        ? '80px auto 100px 110px 30px'
        : 'auto 100px 110px 30px'};

    justify-content: unset;
    border-bottom-left-radius: ${(props) => props.theme.spacing._1};
    border-bottom-right-radius: ${(props) => props.theme.spacing._1};
  }
  padding: ${(props) => props.theme.spacing._2};
  ${(props) =>
    !props.firstRow &&
    css`
      border-top: 2px solid
        ${props.compareMoreRowBorderColor
          ? props.theme.colors[props.compareMoreRowBorderColor]
          : props.theme.colors.grey2};
    `};

  > div img {
    width: 80px;
    @media ${device.md} {
      width: 56px;
    }
  }
`;

const MobileNewRowWrapper = styled.div<{
  compareMoreRowBorderColor?: ThemeColor;
}>`
  display: flex;
  justify-content: space-between;
  order: 3;
  width: calc(100% + ${(props) => props.theme.spacing._4});
  margin: ${(props) => props.theme.spacing._2} -${(props) =>
      props.theme.spacing._2} 0;
  padding: ${(props) => props.theme.spacing._2}
    ${(props) => props.theme.spacing._2} 0;
  border-top: 1px solid
    ${(props) =>
      props.compareMoreRowBorderColor
        ? props.theme.colors[props.compareMoreRowBorderColor]
        : props.theme.colors.grey2};
  @media ${device.md} {
    width: 80%;
    order: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

const CompareMoreRow = styled.div.attrs({
  className: 'font-hotel',
})<{
  compareMoreRowColor?: ThemeColor;
  compareMoreRowBorderColor?: ThemeColor;
}>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing._1}
    ${(props) => props.theme.spacing._2};
  background: ${(props) =>
    props.compareMoreRowColor
      ? props.theme.colors[props.compareMoreRowColor]
      : props.theme.colors.black};
  @media ${device.md} {
    flex-direction: row;
    padding-bottom: ${(props) => props.theme.spacing._1};
  }
  > button {
    margin-left: -${(props) => props.theme.spacing._1};
    @media ${device.smOnly} {
      display: block;
      border-radius: 0;
      text-align: center;
      height: auto;
      width: calc(100% + ${(props) => props.theme.spacing._4});
      margin: ${(props) => props.theme.spacing._1} -${(props) =>
          props.theme.spacing._1} -${(props) => props.theme.spacing._1};
      padding: ${(props) => props.theme.spacing._2} 0;
      border-top: 1px solid
        ${(props) =>
          props.compareMoreRowBorderColor
            ? props.theme.colors[props.compareMoreRowBorderColor]
            : props.theme.colors.grey2};
    }
    > span {
      @media ${device.md} {
        display: none;
      }
    }
  }
  &:last-of-type {
    border-bottom-left-radius: ${(props) => props.theme.spacing._1};
    border-bottom-right-radius: ${(props) => props.theme.spacing._1};
  }
`;

const LabelText = styled.p.attrs({
  className: 'font-hotel',
})<{ labelTextColor?: ThemeColor }>`
  margin: 0;
  color: ${(props) =>
    props.labelTextColor
      ? props.theme.colors[props.labelTextColor]
      : props.theme.colors.grey1};
`;

const RowText = styled.p.attrs({
  className: 'font-golf font-golf--bold',
})`
  margin: 0;
  display: flex;
  align-items: center;
`;

const InboundOutboundIcon = styled.span<{
  inboundOutboundIconColor?: ThemeColor;
}>`
  margin: ${(props) => props.theme.spacing._05};
  color: ${(props) =>
    props.inboundOutboundIconColor
      ? props.theme.colors[props.inboundOutboundIconColor]
      : props.theme.colors.primary};
`;

const RowPriceText = styled.p.attrs({
  className: 'font-delta-desktop',
})<{ priceTextColor?: ThemeColor }>`
  margin: -${(props) => props.theme.spacing._05} 0 0;
  @media ${device.md} {
    margin: 0;
  }
  color: ${(props) =>
    props.priceTextColor
      ? props.theme.colors[props.priceTextColor]
      : props.theme.colors.grey1};
`;

const ExpandCollapseWrapperMobile = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.md} {
    display: none;
  }
`;

const ExpandCollapseWrapperDesktop = styled.div`
  display: none;
  @media ${device.md} {
    display: flex;
    justify-content: center;
  }
`;

const UspsWrapper = styled.div`
  display: flex;
  overflow: auto;
  max-width: calc(100% + ${(props) => props.theme.spacing._2});
  padding-bottom: ${(props) => props.theme.spacing._2};
  margin: 0 -${(props) => props.theme.spacing._1} -${(props) =>
      props.theme.spacing._2};
  @media ${device.md} {
    max-width: 480px;
    margin-left: auto;
  }
`;

const UspItemWrapper = styled.div`
  margin: 0 auto;
`;

const UspItem = styled.p<{
  uspBackgroundColor?: ThemeColor;
  uspIconColor?: ThemeColor;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${(props) => props.theme.spacing._05};
  padding: ${(props) => props.theme.spacing._05}
    ${(props) => props.theme.spacing._1};
  border-radius: ${(props) => props.theme.spacing._2};
  color: ${(props) =>
    props.uspIconColor
      ? props.theme.colors[props.uspIconColor]
      : props.theme.colors.grey3};
  background: ${(props) =>
    props.uspBackgroundColor
      ? props.theme.colors[props.uspBackgroundColor]
      : props.theme.colors.grey3};

  &:first-of-type {
    @media ${device.md} {
      margin-left: 0;
    }
    > svg {
      max-width: none;
    }
  }
`;

const UspLabel = styled.span`
  color: black;
  margin-left: ${(props) => props.theme.spacing._05};
  white-space: pre;
`;

const WhatsIncludedList = styled.ul.attrs({
  className: 'font-golf',
})`
  display: flex;
  flex-wrap: wrap;
  margin: ${(props) => props.theme.spacing._4} 0 0;
  padding: 0;
  color: ${(props) => props.theme.colors.grey7};
  > li {
    width: 50%;
    display: flex;
    align-items: flex-start;
    margin-bottom: ${(props) => props.theme.spacing._1};
    > svg {
      flex-shrink: 0;
      margin: 5px ${(props) => props.theme.spacing._05} 0 0;
    }
  }
`;

const BoardBasisExplained = styled.div<{
  backgroundColor?: ThemeColor;
}>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.grey1};
  padding: ${(props) => props.theme.spacing._2};
  border-radius: ${(props) => props.theme.spacing._1};
  margin-top: ${(props) => props.theme.spacing._2};
`;

const BoardBasisHeading = styled.h3.attrs({
  className: 'font-hotel',
})<{
  textColor?: ThemeColor;
}>`
  margin: 0;
  color: ${(props) =>
    props.textColor
      ? props.theme.colors[props.textColor]
      : props.theme.colors.black};
`;

const BoardBasisLabel = styled.h3.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})`
  margin: ${(props) => props.theme.spacing._1} 0 0;
`;

const BoardBasisDesc = styled.p.attrs({
  className: 'font-golf',
})`
  margin: ${(props) => props.theme.spacing._1} 0 0;
`;

const ModalHeaderWrapper = styled.div`
  @media ${device.md} {
    padding: 0 ${(props) => props.theme.spacing._4};
  }
`;

const ModalHeaderContainer = styled.div`
  max-width: 628px;
  margin: auto;
`;

const ModalRowsWrapper = styled.div<{
  backgroundColor?: ThemeColor;
}>`
  background: ${(props) => props.theme.colors.sand};
  @media ${device.md} {
    padding: ${(props) => props.theme.spacing._4}
      ${(props) => props.theme.spacing._4} ${(props) => props.theme.spacing._8};
  }
`;

const ModalRowsWrapperInner = styled.div`
  overflow: hidden;
  max-width: 628px;
  margin: auto;
  @media ${device.md} {
    border-radius: ${(props) => props.theme.spacing._1};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }
`;

const StyledAccordion = styled(Accordion)`
  background: #fff;
`;

const FlightsAndIncluded = styled.div<{
  compareMoreRowBorderColor?: ThemeColor;
}>`
  margin: ${(props) => props.theme.spacing._2};
  padding: ${(props) => props.theme.spacing._2};
  border-radius: ${(props) => props.theme.spacing._1};
  border: 1px solid
    ${(props) =>
      props.compareMoreRowBorderColor
        ? props.theme.colors[props.compareMoreRowBorderColor]
        : props.theme.colors.grey2};
`;
export interface Props {
  deal: SearchResultsTypes.Deal;
  priceMode: string;
  showProviderLogo?: boolean;
  showCompareMore?: boolean;
  compareMoreRowColor?: ThemeColor;
  linkColor?: ThemeColor;
  linkHoverColor?: ThemeColor;
  labelTextColor?: ThemeColor;
  priceTextColor?: ThemeColor;
  uspBackgroundColor?: ThemeColor;
  uspIconColor?: ThemeColor;
  outboundInboundLabelColor?: ThemeColor;
  flightInfoItemBackgroundColor?: ThemeColor;
  flightInfoItemTextColor?: ThemeColor;
  boardBasisBackgroundColor?: ThemeColor;
  accommodation: SearchResultsTypes.Accommodation;
  firstRow?: boolean;
}

export const ResultRow = ({
  deal,
  priceMode,
  showProviderLogo,
  showCompareMore,
  compareMoreRowColor,
  linkColor,
  linkHoverColor,
  labelTextColor,
  priceTextColor,
  uspBackgroundColor,
  uspIconColor,
  outboundInboundLabelColor,
  flightInfoItemBackgroundColor,
  flightInfoItemTextColor,
  boardBasisBackgroundColor,
  accommodation,
  firstRow,
}: Props) => {
  const isDesktop = useMediaQuery(device.lg);
  const store = useStore((state) => state.searchResults);
  const partner = store.getPartner(deal.partnerId);
  const config = store.config;
  const theme = useTheme();
  const uspsWrapperRef = useRef(null);
  const [compareMoreDealsResults, setCompareMoreDealsResults] = useState<
    SearchResultsTypes.ResultItem[]
  >([]);
  const [showModal, setShowModal] = useState(false);

  const showModalToggleHandler = async () => {
    if (showModal) {
      setShowModal(!showModal);
    } else {
      const currentSearch = store.searchParams;
      if (currentSearch && !compareMoreDealsResults.length) {
        const paramsString = [
          `area=${currentSearch.destinationArea}`,
          `country=${currentSearch.destinationCountry}`,
          currentSearch.destinationRegion
            ? `region=${currentSearch.destinationRegion}`
            : null,
          `departureDate=${currentSearch.departureDate}`,
          `duration=${currentSearch.duration}`,
          `placeKey=${currentSearch.destinationId}`,
          `adults=${currentSearch.adults}`,
          `childAges=${currentSearch.childAges}`,
          `departureAirports=${currentSearch.departureAirports}`,
          `partnerIDs=${deal.partnerId}`,
        ].join('&');
        const response = await fetch(
          `${config?.brokerApi}/v2/packages/hotel/${deal.accommodationId}?${paramsString}`,
          {
            credentials: 'include',
          }
        );
        const data = await response.json();
        setCompareMoreDealsResults(data.results);
        setShowModal(!showModal);
      } else {
        setShowModal(!showModal);
      }
    }
  };

  const [detailsAccordionExpanded, setDetailsAccordionExpanded] =
    useState(false);

  const toggleDetailsAccordionExpanded = async () => {
    setDetailsAccordionExpanded(!detailsAccordionExpanded);
  };

  const formatDate = (inputDate: string, days?: number) => {
    const date = new Date(inputDate);
    if (days) {
      date.setDate(date.getDate() + days);
    }
    return date.toLocaleDateString(theme.dates.locale, {
      timeZone: theme.dates.timezone,
      day: 'numeric',
      month: 'short',
    });
  };

  const formatPrice = (priceObj: SearchResultsTypes.Price) => {
    const price =
      priceMode === 'perperson' ? priceObj.averagePerPerson : priceObj.total;
    return new Intl.NumberFormat(theme.currency.locale, {
      style: 'currency',
      currency: theme.currency.code,
      minimumFractionDigits: 0,
    }).format(price);
  };

  const compareMoreContent = () => {
    return (
      <ModalRowsWrapper backgroundColor={compareMoreRowColor}>
        <ModalRowsWrapperInner>
          {compareMoreDealsResults.length &&
            compareMoreDealsResults[0].partnerOffers[0].offers.map(
              (deal, index) => (
                <ResultRow
                  key={index}
                  deal={deal}
                  priceMode={priceMode}
                  showCompareMore={false}
                  compareMoreRowColor={compareMoreRowColor}
                  linkColor={linkColor}
                  linkHoverColor={linkHoverColor}
                  labelTextColor={labelTextColor}
                  priceTextColor={priceTextColor}
                  uspBackgroundColor={uspBackgroundColor}
                  uspIconColor={uspIconColor}
                  accommodation={accommodation}
                />
              )
            )}
        </ModalRowsWrapperInner>
      </ModalRowsWrapper>
    );
  };

  const goToDeal = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <RowContainer
        firstRow={firstRow}
        data-testid="results-row-container"
        showProviderLogo={showProviderLogo}
      >
        {showProviderLogo && (
          <div>
            <img
              width="56"
              alt={partner?.partnerName}
              src={`https://d2lqjbz18tdbni.cloudfront.net/88x40/${deal.partnerId}.png`}
              loading="lazy"
            />
          </div>
        )}
        <MobileNewRowWrapper>
          <div>
            <LabelText labelTextColor={labelTextColor}>Board basis</LabelText>
            <RowText>{deal.boardBasis.name}</RowText>
          </div>
          <div>
            <LabelText labelTextColor={labelTextColor}>
              {formatDate(deal.departureDate)} -{' '}
              {formatDate(deal.departureDate, deal.durationNights)}
            </LabelText>
            <RowText>
              {deal.flights.outbound.departureAirport.code}
              <InboundOutboundIcon>
                <ArrowLeftRight
                  ariaLabel="Arrow left right"
                  height={12}
                  width={12}
                />
              </InboundOutboundIcon>
              {deal.flights.inbound.departureAirport.code}
            </RowText>
          </div>
          <ExpandCollapseWrapperMobile>
            <Button
              type="aux"
              icon={
                detailsAccordionExpanded ? 'ChevronContract' : 'ChevronExpand'
              }
              iconOnly={true}
              iconRotated={detailsAccordionExpanded}
              ariaLabel="View details"
              onClick={toggleDetailsAccordionExpanded}
              ariaExpanded={detailsAccordionExpanded}
              ariaControls={`details-${deal.offerId}`}
              id={`sm-details-toggle-button-${deal.offerId}`}
            />
          </ExpandCollapseWrapperMobile>
        </MobileNewRowWrapper>
        <div>
          <LabelText labelTextColor={labelTextColor}>
            {priceMode === 'perperson' ? 'Price pp' : 'Total price'}
          </LabelText>
          <RowPriceText
            priceTextColor={priceTextColor}
            data-testid="price-text"
          >
            {formatPrice(deal.price)}
          </RowPriceText>
        </div>
        <div>
          <Button
            text="View deal"
            size="small"
            onClick={() => goToDeal(deal.deepLink)}
          />
        </div>
        <ExpandCollapseWrapperDesktop>
          <Button
            type="aux"
            icon="Chevron"
            iconOnly={true}
            iconRotated={detailsAccordionExpanded}
            ariaLabel="View details"
            onClick={toggleDetailsAccordionExpanded}
            ariaExpanded={detailsAccordionExpanded}
            ariaControls={`details-${deal.offerId}`}
            id={`md-details-toggle-button-${deal.offerId}`}
          />
        </ExpandCollapseWrapperDesktop>
      </RowContainer>
      <StyledAccordion
        id={`details-${deal.offerId}`}
        expanded={detailsAccordionExpanded}
        ariaLabelledby={`sm-details-toggle-button-${deal.offerId} md-details-toggle-button-${deal.offerId}`}
      >
        <FlightsAndIncluded>
          <Tabs autoAdjustHeight={true}>
            <Tab title="Flights">
              <FlightInfo
                deal={deal}
                direction="outbound"
                outboundInboundLabelColor={outboundInboundLabelColor}
                flightInfoItemBackgroundColor={flightInfoItemBackgroundColor}
                flightInfoItemTextColor={flightInfoItemTextColor}
              />
              <FlightInfo
                deal={deal}
                direction="inbound"
                outboundInboundLabelColor={outboundInboundLabelColor}
                flightInfoItemBackgroundColor={flightInfoItemBackgroundColor}
                flightInfoItemTextColor={flightInfoItemTextColor}
              />
            </Tab>
            <Tab title="What's Included">
              <WhatsIncludedList>
                {partner &&
                  partner.inclusions.map((item, index) => {
                    return (
                      <li key={index}>
                        <Check
                          ariaLabel="check"
                          height={12}
                          width={12}
                          color={uspIconColor}
                        />{' '}
                        <span>{item}</span>
                      </li>
                    );
                  })}
              </WhatsIncludedList>

              {BOARD_BASIS_LOOKUP[deal.boardBasis.code] && (
                <BoardBasisExplained
                  backgroundColor={boardBasisBackgroundColor}
                  data-testid="board-basis-explained"
                >
                  <BoardBasisHeading textColor={outboundInboundLabelColor}>
                    Board basis
                  </BoardBasisHeading>
                  <BoardBasisLabel>{deal.boardBasis.name}</BoardBasisLabel>
                  {BOARD_BASIS_LOOKUP[deal.boardBasis.code].desc.map((item) => {
                    return (
                      <BoardBasisDesc key={item.title}>
                        <strong>{item.title}:</strong> {item.desc}
                      </BoardBasisDesc>
                    );
                  })}
                </BoardBasisExplained>
              )}
            </Tab>
          </Tabs>
        </FlightsAndIncluded>
      </StyledAccordion>
      {(showCompareMore || deal.usps) && (
        <CompareMoreRow
          data-testid="compare-more-row-container"
          compareMoreRowColor={compareMoreRowColor}
        >
          {showCompareMore && (
            <>
              <Button
                text={
                  <>
                    Compare more<span> deals</span> +
                  </>
                }
                type="aux"
                onClick={showModalToggleHandler}
              />
              <Modal
                showModal={showModal}
                onClose={showModalToggleHandler}
                ariaLabelledby="comparemore-modal-heading"
                maxWidth="845px"
              >
                <ModalHeaderWrapper>
                  <ModalHeaderContainer>
                    <ModalHeader
                      accommodationName={accommodation.accommodationName}
                      accommodationLocation={`${accommodation.regionName}, ${accommodation.areaName}, ${accommodation.countryName}`}
                      imageUrl={accommodation.images[0].sources[0].url}
                      provider={partner?.partnerName}
                      providerImage={`https://d2lqjbz18tdbni.cloudfront.net/88x40/${deal.partnerId}.png`}
                      starRating={accommodation.rating}
                    />
                  </ModalHeaderContainer>
                </ModalHeaderWrapper>

                {compareMoreContent()}
              </Modal>
            </>
          )}
          <UspsWrapper tabIndex={0} ref={uspsWrapperRef}>
            {deal.usps &&
              deal.usps.map((usp, index) => {
                return (
                  <UspItemWrapper key={index}>
                    <Tooltip
                      width={230}
                      text={USP_CODES[usp.code].tooltip}
                      scrollingParent={uspsWrapperRef}
                      tipPosition={
                        isDesktop ? TipPositions.Bottom : TipPositions.Top
                      }
                    >
                      <UspItem
                        uspBackgroundColor={uspBackgroundColor}
                        uspIconColor={uspIconColor}
                        data-testid="usp-item"
                      >
                        <Tick ariaLabel="tick" height={12} width={12} />
                        <UspLabel>{usp.name}</UspLabel>
                      </UspItem>
                    </Tooltip>
                  </UspItemWrapper>
                );
              })}
          </UspsWrapper>
        </CompareMoreRow>
      )}
    </>
  );
};
