import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { AirportsSelector } from '../airportsSelector';
import { DestinationsSelector } from '../destinationSelector';
import { DateSelector } from '../dateSelector';
import { DurationSelector } from '../durationSelector';
import { PassengersSelector } from '../passengersSelector';
import { Button } from '../../button';
import type { FormValues, SubmittedFormValues } from './types';
import {
  getInitialValues,
  validate,
  mapFormValuesToSearchParams,
} from './utils';
import { ConfigGadget } from '@supersonic/config';
import { useStore } from '../../../storeProvider';
import { device } from '../../../css/sizes';
import { DEFAULT_INITIAL_VALUES } from './constants';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  @media ${device.xl} {
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing.unit}px;
  }
`;
const FieldsWrapper = styled.div`
  & > * {
    margin-bottom: ${(props) => props.theme.spacing.unit}px;
  }
  @media ${device.md} {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: ${(props) => props.theme.spacing.unit}px;
    margin-bottom: ${(props) => props.theme.spacing.unit}px;
    & > * {
      margin-bottom: 0;
    }
    & > *:nth-child(1),
    & > *:nth-child(2) {
      grid-column-start: span 3;
    }
    & > *:nth-child(3),
    & > *:nth-child(4),
    & > *:nth-child(5) {
      grid-column-start: span 2;
    }
  }
  @media ${device.xl} {
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing.unit}px;
    margin-bottom: 0;
    flex: 1;
    & > * {
      flex: 1 1 0;
      width: 0;
    }
  }
`;

const MobileSubmitButton = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.xl} {
    display: none;
  }
`;
const LargeDesktopSubmitButton = styled.div`
  display: none;
  @media ${device.xl} {
    display: block;
  }
`;

export type Props = {
  config: ConfigGadget;
  locale: string;
  timezone: string;
};

export const GadgetForm = ({ config, locale, timezone }: Props) => {
  const [
    searchParams,
    getSearchResultsPageUrl,
    currentDestination,
    destinationsConfig,
    loadCurrentDestination,
  ] = useStore((state) => [
    state.searchResults.searchParams,
    state.searchResults.getSearchResultsPageUrl,
    state.destinations.currentDestination,
    state.destinations.config,
    state.destinations.loadCurrentDestination,
  ]);

  const [initialValues, setInitialValues] = useState<FormValues>(
    DEFAULT_INITIAL_VALUES
  );
  const [isAirportsSelectorOpen, setAirportsSelectorOpen] = useState(false);
  const [isDestinationSelectorOpen, setDestinationSelectorOpen] =
    useState(false);
  const [isDateSelectorOpen, setDateSelectorOpen] = useState(false);
  const [isDurationSelectorOpen, setDurationSelectorOpen] = useState(false);
  const [isPassengersSelectorOpen, setPassengersSelectorOpen] = useState(false);
  const { values, setFieldValue, handleSubmit, errors, touched } =
    useFormik<FormValues>({
      initialValues,
      enableReinitialize: true,
      validate,
      onSubmit: (values) => {
        const searchParams = mapFormValuesToSearchParams(
          values as SubmittedFormValues
        );
        window.location.href = getSearchResultsPageUrl(searchParams);
      },
    });

  useEffect(() => {
    if (searchParams && currentDestination) {
      setInitialValues(getInitialValues(searchParams, currentDestination));
    } else if (searchParams && destinationsConfig) {
      loadCurrentDestination(searchParams);
    }
    if (!searchParams || !destinationsConfig) return;
  }, [!!currentDestination, !!searchParams, !!destinationsConfig]);

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsWrapper>
        <AirportsSelector
          value={values.airports}
          fieldLabel={config.airportsSelector.fieldLabel}
          isOpen={isAirportsSelectorOpen}
          error={!!(errors.airports && touched.airports)}
          onOpen={() => setAirportsSelectorOpen(true)}
          onClose={() => setAirportsSelectorOpen(false)}
          onBack={() => setAirportsSelectorOpen(false)}
          onChange={(value) => setFieldValue('airports', value)}
        />
        <DestinationsSelector
          value={values.destination}
          fieldLabel={config.destinationSelector.fieldLabel}
          isOpen={isDestinationSelectorOpen}
          error={!!(errors.destination && touched.destination)}
          onOpen={() => setDestinationSelectorOpen(true)}
          onClose={() => setDestinationSelectorOpen(false)}
          onBack={() => setDestinationSelectorOpen(false)}
          onChange={(value) => setFieldValue('destination', value)}
        />
        <DateSelector
          value={values.date}
          fieldLabel={config.dateSelector.fieldLabel}
          isOpen={isDateSelectorOpen}
          error={!!(errors.date && touched.date)}
          onOpen={() => setDateSelectorOpen(true)}
          onClose={() => setDateSelectorOpen(false)}
          onBack={() => setDateSelectorOpen(false)}
          onChange={(value) => setFieldValue('date', value)}
          locale={locale}
          timezone={timezone}
        />
        <DurationSelector
          value={values.duration}
          fieldLabel={config.durationSelector.fieldLabel}
          isOpen={isDurationSelectorOpen}
          onOpen={() => setDurationSelectorOpen(true)}
          onClose={() => setDurationSelectorOpen(false)}
          onBack={() => setDurationSelectorOpen(false)}
          onChange={(value) => setFieldValue('duration', value)}
        />
        <PassengersSelector
          value={values.passengers}
          fieldLabel={config.passengersSelector.fieldLabel}
          isOpen={isPassengersSelectorOpen}
          error={!!(errors.passengers && touched.passengers)}
          onOpen={() => setPassengersSelectorOpen(true)}
          onClose={() => setPassengersSelectorOpen(false)}
          onBack={() => setPassengersSelectorOpen(false)}
          onChange={(value) => setFieldValue('passengers', value)}
        />
      </FieldsWrapper>
      <LargeDesktopSubmitButton>
        <Button
          iconOnly={true}
          icon="Search"
          buttonProps={{ type: 'submit' }}
          ariaLabel="Search"
        />
      </LargeDesktopSubmitButton>
      <MobileSubmitButton>
        <Button
          iconOnly={false}
          iconPos="left"
          icon="Search"
          text="Search"
          buttonProps={{ type: 'submit' }}
        />
      </MobileSubmitButton>
    </Form>
  );
};
