import { SearchResultsTypes, DestinationsTypes } from '@supersonic/store';
import { getInitialValues } from '../gadgetForm/utils';
import { getValueLabel as getAirportsValueLabel } from '../airportsSelector/utils';
import { getValueLabel as getDestinationValueLabel } from '../destinationSelector/utils';
import { getValueLabel as getDateValueLabel } from '../dateSelector/utils';
import { getValueLabel as getDurationValueLabel } from '../durationSelector/utils';
import { getValueLabel as getPassengersValueLabel } from '../passengersSelector/utils';

export const getSummaryTexts = (
  searchParams: SearchResultsTypes.SearchParams,
  currentDestination: DestinationsTypes.Destination,
  locale: string,
  timezone: string
) => {
  const values = getInitialValues(searchParams, currentDestination);

  const airportsLabel =
    values.airports.length > 1
      ? `${values.airports.length} Airports`
      : getAirportsValueLabel(values.airports);
  const destinationLabel = getDestinationValueLabel(values.destination);
  const dateLabel = getDateValueLabel(values.date, locale, timezone);
  const durationLabel = getDurationValueLabel(values.duration);
  const passengersLabel = getPassengersValueLabel(values.passengers);

  return {
    primary: `${airportsLabel} to ${destinationLabel}`,
    secondary: [dateLabel, durationLabel, passengersLabel].join('・'),
  };
};
