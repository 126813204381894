import styled, { css } from 'styled-components';
import { CalendarState } from '@react-stately/calendar';
import { CalendarDate, isSameMonth } from '@internationalized/date';
import React from 'react';
import { useCalendarCell } from 'react-aria';
import { isThemeSpacing } from '../../../../utils/isThemeSpacing';

export const CalendarDay = styled.div<{
  isSelected: boolean;
  isOutsideVisibleRange: boolean;
  isOutsideMonth: boolean;
  isDisabled: boolean;
}>`
  color: ${(props) => props.theme.colors.black};
  display: ${(props) =>
    props.isOutsideVisibleRange || props.isOutsideMonth ? 'none' : 'flex'};
  width: ${(props) => props.theme.spacing.unit * 4.75}px;
  height: ${(props) => props.theme.spacing.unit * 4.75}px;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) =>
    isThemeSpacing(props.theme.buttonConfig.iconOnlyBorderRadius, props.theme)
      ? props.theme.spacing[props.theme.buttonConfig.iconOnlyBorderRadius]
      : '100%'};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    props.isDisabled
      ? css`
          color: ${(props) => props.theme.colors.grey3};
        `
      : css`
          &:hover,
          &:focus-visible {
            background: ${(props) =>
              props.theme.colors[props.theme.buttonConfig.tertiary.hoverColor]};
            color: ${(props) =>
              props.theme.colors[props.theme.buttonConfig.tertiary.textColor]};
          }
          &:focus-visible {
            outline: 2px solid
              ${(props) =>
                props.theme.colors[
                  props.theme.buttonConfig.primary.focusColor
                ]};
          }
        `}
  ${(props) =>
    props.isSelected &&
    css`
      background: ${(props) =>
        props.theme.colors[props.theme.buttonConfig.primary.backgroundColor]};
      color: ${(props) => props.theme.colors.white};
      &:hover,
      &:focus-visible {
        background: ${(props) =>
          props.theme.colors[props.theme.buttonConfig.primary.hoverColor]};
        color: ${(props) => props.theme.colors.white};
      }
    `};
`;

type Props = {
  state: CalendarState;
  date: CalendarDate;
  currentMonth: CalendarDate;
};

export const CalendarCell = ({ state, date, currentMonth }: Props) => {
  const ref = React.useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);
  const isOutsideMonth = !isSameMonth(currentMonth, date);

  return (
    <div {...cellProps}>
      <CalendarDay
        {...buttonProps}
        ref={ref}
        className="font-golf"
        isSelected={isSelected}
        isOutsideVisibleRange={isOutsideVisibleRange}
        isOutsideMonth={isOutsideMonth}
        isDisabled={isDisabled}
      >
        {formattedDate}
      </CalendarDay>
    </div>
  );
};
