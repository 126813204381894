import React, { useRef } from 'react';
import styled from 'styled-components';
import { Value } from './types';
import { usePassengersSelector } from './usePassengersSelector';
import { InputField, InputFieldHandle } from '../../inputField';
import { Controls } from './controls';
import { Select } from '../../select';
import { DesktopGadgetDropdown } from '../desktopGadgetDropdown';
import { MobileGadgetModal } from '../mobileGadgetModal';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { device } from '../../../css/sizes';
import { generateChildAgeOptions } from './utils';

export type Props = {
  value: Value;
  fieldLabel: string;
  isOpen: boolean;
  error: boolean;
  onChange: (value: Value) => void;
  onOpen: () => void;
  onClose: () => void;
  onBack: () => void;
};

const Wrapper = styled.div`
  position: relative;
`;
const PassengersSelectorDesktopGadgetDropdown = styled(DesktopGadgetDropdown)`
  width: 300px;
  right: 0;
`;

export const PassengersSelector = (props: Props) => {
  const isTabletOrDesktop = useMediaQuery(device.md);
  const selector = usePassengersSelector({
    value: props.value,
    onChange: props.onChange,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<InputFieldHandle>(null);

  const content = (
    <div data-testid="passengers-selector-content">
      <Controls
        text="Adults"
        counter={props.value.adults}
        onMinusButtonClick={selector.removeAdult}
        onPlusButtonClick={selector.addAdult}
        isMinusButtonDisabled={!selector.allowRemoveAdult}
        isPlusButtonDisabled={!selector.allowAddAdult}
        minusButtonAriaLabel="Remove adult"
        plusButtonAriaLabel="Add adult"
      />
      <Controls
        text="Children"
        subText="0-17 years old"
        counter={props.value.childAges.length}
        onMinusButtonClick={selector.removeChild}
        onPlusButtonClick={selector.addChild}
        isMinusButtonDisabled={!selector.allowRemoveChild}
        isPlusButtonDisabled={!selector.allowAddChild}
        minusButtonAriaLabel="Remove child"
        plusButtonAriaLabel="Add child"
      />
      {props.value.childAges.map((childAge, index) => (
        <Select
          key={`${childAge}${index}`}
          text={`Age of child ${index + 1}`}
          options={generateChildAgeOptions()}
          selectedOption={childAge}
          onSelect={(newChildAge) => {
            if (typeof newChildAge === 'number') {
              selector.setChildAge(newChildAge, index);
            }
          }}
          openOnTop={isTabletOrDesktop ? false : index > 1}
          disableBodyScrollWhenOpen={!isTabletOrDesktop}
        />
      ))}
    </div>
  );

  return (
    <Wrapper ref={wrapperRef} data-testid="passengers-selector">
      <InputField
        type="button"
        size="regular"
        label={selector.label}
        labelProps={{
          htmlFor: 'passengers-selector-button',
          'data-testid': 'passengers-selector-input-field',
        }}
        leftIcon="People"
        fieldLabel={props.fieldLabel}
        ref={inputFieldRef}
        active={props.isOpen}
        error={props.error}
        buttonProps={{
          id: 'passengers-selector-button',
          'aria-label': 'Select passengers',
          onClick: () => (props.isOpen ? props.onClose() : props.onOpen()),
        }}
        {...(selector.allowClear
          ? {
              rightIcon: 'XLg',
              onRightIconClick: selector.clear,
              rightIconClickableAriaLabel: 'Reset',
            }
          : {})}
      />
      {isTabletOrDesktop ? (
        <PassengersSelectorDesktopGadgetDropdown
          testId="passengers-selector-desktop-dropdown"
          isOpen={props.isOpen}
          onRequestClose={(reason) => {
            props.onClose();
            if (reason === 'escape-key') {
              inputFieldRef.current?.focus();
            }
          }}
          getTriggerElement={() => wrapperRef.current}
          headerText="Select party quantity"
          footerPrimaryButton={{ onClick: props.onClose, text: 'Done' }}
          footerSecondaryButton={{
            onClick: selector.clear,
            text: 'Reset',
            disabled: !selector.allowClear,
          }}
        >
          {content}
        </PassengersSelectorDesktopGadgetDropdown>
      ) : (
        <MobileGadgetModal
          testId="passengers-selector-mobile-modal"
          isOpen={props.isOpen}
          headerText="Who is coming?"
          contentHeaderText="Select party quantity"
          onClose={props.onClose}
          onBack={props.onBack}
          footerPrimaryButton={{ onClick: props.onClose, text: 'Done' }}
          footerSecondaryButton={{
            onClick: selector.clear,
            text: 'Reset',
            disabled: !selector.allowClear,
          }}
        >
          {content}
        </MobileGadgetModal>
      )}
    </Wrapper>
  );
};
