import React from 'react';
import { device } from '../../css/sizes';
import styled, { ThemeColor } from 'styled-components';
import { RowContainer } from '../results/resultRow';

export interface SkeletonBlockProps {
  w?: string;
  h?: string;
  hLg?: string;
  rightMargin?: boolean;
  bottomMargin?: boolean;
  noAnimation?: boolean;
  squareCorners?: boolean;
}

export const SkeletonBlock = styled.span<SkeletonBlockProps>`
  display: block;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.grey2},
    ${(props) => props.theme.colors.white},
    ${(props) => props.theme.colors.grey2}
  );
  background-size: 300% 100%;
  height: ${(props) => props.h || props.theme.spacing._3};
  @media ${device.lg} {
    height: ${(props) => props.hLg};
  }
  width: ${(props) => props.w};
  margin-right: ${(props) => props.rightMargin && props.theme.spacing._1};
  margin-bottom: ${(props) => props.bottomMargin && props.theme.spacing._1};
  animation: ${(props) =>
    props.noAnimation ? null : `gradientAnimation 3s ease-in-out infinite`};
  border-radius: ${(props) =>
    props.squareCorners ? null : props.theme.spacing._1};

  @keyframes gradientAnimation {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

const SkeletonRow = styled.div<{
  compareMoreRowBorderColor?: ThemeColor;
}>`
  margin-top: -1px;
  border-top: 2px solid
    ${(props) =>
      props.compareMoreRowBorderColor
        ? props.theme.colors[props.compareMoreRowBorderColor]
        : props.theme.colors.grey2};
`;

const SkeletonBlockWrapper = styled.div`
  @media ${device.smOnly} {
    width: 25%;
    :first-of-type span {
      margin-left: ${(props) => props.theme.spacing._1};
    }
    :last-of-type span {
      margin-right: 0;
    }
  }
`;

export const SkeletonResultRow = () => {
  return (
    <SkeletonRow>
      <RowContainer showProviderLogo={true}>
        <SkeletonBlock w="56px" h="40px" />
        <SkeletonBlockWrapper>
          <SkeletonBlock rightMargin bottomMargin />
          <SkeletonBlock rightMargin />
        </SkeletonBlockWrapper>
        <SkeletonBlockWrapper>
          <SkeletonBlock rightMargin bottomMargin />
          <SkeletonBlock rightMargin />
        </SkeletonBlockWrapper>
        <SkeletonBlockWrapper>
          <SkeletonBlock rightMargin h="40px" />
        </SkeletonBlockWrapper>
        <SkeletonBlock />
      </RowContainer>
      <SkeletonBlock squareCorners noAnimation h="42px" />
    </SkeletonRow>
  );
};
