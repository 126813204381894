import React, { HTMLAttributes, useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import { Elevation } from '../../elevation';
import { Button } from '../../button';
import { Modal } from '../../modal';
import { ChevronLeft, XLg } from '../../icon';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade';

export type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  headerText: string;
  headerAdditionalContent?: React.ReactNode;
  contentHeaderText?: string;
  testId?: string;
  onClose: () => void;
  onBack: () => void;
  disableBackButton?: boolean;
  footerPrimaryButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  footerSecondaryButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  contentWrapperProps?: HTMLAttributes<HTMLDivElement>;
  noPaddingOnContent?: boolean;
};

const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const HeaderElevation = styled(Elevation)`
  padding: ${(props) => props.theme.spacing.unit * 2}px;
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderAdditionalContent = styled.div`
  margin-top: ${(props) => props.theme.spacing.unit * 3}px;
`;
const ContentWrapper = styled.div<{ noPadding: boolean }>`
  padding: ${(props) =>
    props.noPadding
      ? 0
      : `${props.theme.spacing.unit * 3}px ${props.theme.spacing.unit * 2}px`};
  overflow: auto;
  flex: 1;
`;
const ContentHeader = styled.div.attrs({ className: 'font-golf' })`
  padding-bottom: ${(props) => props.theme.spacing.unit * 2}px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  margin-bottom: ${(props) => props.theme.spacing.unit * 3}px;
`;
const FooterElevation = styled(Elevation)`
  display: flex;
  padding: ${(props) => props.theme.spacing.unit * 2}px;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.spacing.unit * 2}px;
`;
const HeaderText = styled.div.attrs({ className: 'font-echo' })`
  color: ${(props) => props.theme.colors.primary};
`;
const IconButton = styled.button`
  padding: ${(props) => props.theme.spacing.unit}px;
  margin: -${(props) => props.theme.spacing.unit}px;
  color: ${(props) => props.theme.colors.black};
  border: none;
  background: none;
  &:disabled {
    color: ${(props) => props.theme.colors.grey3};
  }
`;

export const MobileGadgetModal = (props: Props) => {
  const headerTextId = useId();
  const [contentWrapperElement, setContentWrapperElement] =
    useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (props.isOpen && contentWrapperElement) {
      disableBodyScroll(contentWrapperElement);
    } else if (!props.isOpen) {
      clearAllBodyScrollLocks();
    }
  }, [props.isOpen, contentWrapperElement]);

  return (
    <Modal
      showModal={props.isOpen}
      onClose={() => props.onClose()}
      hideCloseButton
      ariaLabelledby={headerTextId}
      fullscreen={true}
      disableBodyScrollWhenOpen={false}
    >
      <ModalContent data-testid={props.testId}>
        <HeaderElevation level={3}>
          <HeaderContent>
            <IconButton
              onClick={props.onBack}
              disabled={props.disableBackButton}
              aria-label="Back"
              type="button"
            >
              <ChevronLeft width={16} height={16} />
            </IconButton>
            <HeaderText id={headerTextId}>{props.headerText}</HeaderText>
            <IconButton
              onClick={props.onClose}
              aria-label="Close"
              type="button"
            >
              <XLg width={20} height={20} />
            </IconButton>
          </HeaderContent>
          {props.headerAdditionalContent && (
            <HeaderAdditionalContent>
              {props.headerAdditionalContent}
            </HeaderAdditionalContent>
          )}
        </HeaderElevation>
        <ContentWrapper
          ref={setContentWrapperElement}
          noPadding={props.noPaddingOnContent || false}
          {...props.contentWrapperProps}
        >
          {props.contentHeaderText && (
            <ContentHeader>{props.contentHeaderText}</ContentHeader>
          )}
          {props.children}
        </ContentWrapper>
        {(props.footerPrimaryButton || props.footerSecondaryButton) && (
          <FooterElevation level={2}>
            {props.footerSecondaryButton && (
              <Button
                text={props.footerSecondaryButton.text}
                disabled={props.footerSecondaryButton.disabled}
                onClick={props.footerSecondaryButton.onClick}
                type="tertiary"
                size="small"
              />
            )}
            {props.footerPrimaryButton && (
              <Button
                text={props.footerPrimaryButton.text}
                disabled={props.footerPrimaryButton.disabled}
                onClick={props.footerPrimaryButton.onClick}
                type="primary"
                size="small"
              />
            )}
          </FooterElevation>
        )}
      </ModalContent>
    </Modal>
  );
};
