import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import * as Icon from '../icon';
import { IconByName } from '../iconByName';

export const Wrapper = styled.div<{
  $active?: boolean;
  $invertHighlight: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing._1}
    ${(props) => props.theme.spacing._2};
  ${(props) =>
    !props.$invertHighlight ? 'transition: background 0.15s ease-in;' : null}
  background: ${(props) =>
    props.$active
      ? props.$invertHighlight
        ? props.theme.colors[props.theme.lineItem.invertBgHoverColor]
        : props.theme.colors[props.theme.lineItem.bgHoverColor]
      : 'none'};
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${(props) =>
      props.$invertHighlight
        ? props.theme.colors[props.theme.lineItem.invertBgHoverColor]
        : props.theme.colors[props.theme.lineItem.bgHoverColor]};
  }
  &:focus-visible {
    outline: ${(props) =>
      `3px solid ${props.theme.colors[props.theme.lineItem.primaryColor]}`};
    outline-offset: 0;
  }
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors[props.theme.lineItem.primaryColor]};
  margin-right: ${(props) => props.theme.spacing.unit * 1.5}px;
  flex: 0 0 auto;
`;
const Text = styled.div.attrs({ className: 'font-foxtrot' })<{
  $active?: boolean;
  $textUnderlineOnActive: boolean;
  $invertHighlight: boolean;
}>`
  color: ${(props) =>
    props.$active && props.$invertHighlight
      ? props.theme.colors[props.theme.lineItem.invertPrimaryColor]
      : props.theme.colors[props.theme.lineItem.primaryColor]};
  text-decoration: ${(props) =>
    props.$active && props.$textUnderlineOnActive ? 'underline' : 'none'};
  ${Wrapper}:hover &&, ${Wrapper}:focus && {
    text-decoration: ${(props) =>
      props.$textUnderlineOnActive ? 'underline' : 'none'};
    color: ${(props) =>
      props.$invertHighlight
        ? props.theme.colors[props.theme.lineItem.invertPrimaryColor]
        : props.theme.colors[props.theme.lineItem.primaryColor]};
  }
`;
const Subtext = styled.div.attrs({ className: 'font-hotel' })`
  color: ${(props) => props.theme.colors.grey7};
`;

export type Props = {
  text: string;
  textUnderlineOnActive: boolean;
  invertHighlight: boolean;
  subText?: string;
  icon?: keyof typeof Icon;
  active?: boolean;
  wrapperProps?: ComponentProps<typeof Wrapper>;
};

export const LineItem = (props: Props) => {
  return (
    <Wrapper
      {...props.wrapperProps}
      $active={props.active}
      $invertHighlight={props.invertHighlight}
    >
      {props.icon && (
        <IconWrapper>
          <IconByName
            name={props.icon}
            width={16}
            height={16}
            ariaLabel={`${props.icon} icon`}
          />
        </IconWrapper>
      )}
      <div>
        <Text
          $active={props.active}
          $textUnderlineOnActive={props.textUnderlineOnActive}
          $invertHighlight={props.invertHighlight}
        >
          {props.text}
        </Text>
        {props.subText && <Subtext>{props.subText}</Subtext>}
      </div>
    </Wrapper>
  );
};

LineItem.defaultProps = {
  textUnderlineOnActive: true,
  invertHighlight: false,
};
