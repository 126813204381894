import * as React from 'react';
import { type HeadFC, graphql, PageProps, HeadProps } from 'gatsby';
import { Layout, Blog } from '@supersonic/components';
import blogDummyData from '@supersonic/components/src/components/blog/resources/blog-dummy-data.json';
import {
  ConfigFooter,
  ConfigGadget,
  ConfigHeader,
  ConfigTheme,
  ConfigResultsPage,
} from '@supersonic/config';

type DataProps = {
  config: {
    holidaysResultsPage: ConfigResultsPage;
    theme: ConfigTheme;
    components: {
      footer: ConfigFooter;
      header: ConfigHeader;
      gadget: ConfigGadget;
    };
    titleSuffix: string;
    title: string;
  };
};

const IndexPage = ({ data }: PageProps<DataProps>) => {
  const themeConfig = data.config.theme;
  const footerConfig = data.config.components.footer;
  const headerConfig = data.config.components.header;
  const gadgetConfig = data.config.components.gadget;
  const resultsPageConfig = data.config.holidaysResultsPage;
  return (
    <main>
      <Layout
        themeConfig={themeConfig}
        headerConfig={headerConfig}
        footerConfig={footerConfig}
        gadgetConfig={gadgetConfig}
        resultsPageConfig={resultsPageConfig}
      >
        <Blog
          posts={blogDummyData.posts}
          blogHeadContent={blogDummyData.blogHeadContent}
        ></Blog>
      </Layout>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = (data: HeadProps<DataProps>) => {
  //maybe move this stuff to a seperate SEO component?
  //issue with Gatsby Head API: https://github.com/gatsbyjs/gatsby/discussions/35841
  return (
    <>
      <title>Home page {data.data.config.titleSuffix}</title>;
    </>
  );
};

export const query = graphql`
  query allSiteConfig {
    config {
      holidaysResultsPage {
        urlStem
      }
      title
      titleSuffix
      ...Theme
      components {
        ...Footer
        ...Header
        ...Gadget
      }
    }
  }
`;
