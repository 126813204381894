import { StateCreator } from 'zustand';
import { Slices } from '../types';
import {
  SearchResultsSlice,
  ExposedResultsSearchCriteria,
  OrderByOption,
  SearchParams,
  SearchResultsConfig,
} from './types';
import { getCookie } from '../utils/cookies';

export const createSearchResultsSlice =
  (
    config: SearchResultsConfig
  ): StateCreator<Slices, [], [], SearchResultsSlice> =>
  (set, get) => ({
    config,
    queryParams: '',
    currentPage: 1,
    filterData: [],
    orderBy: 'recommended',
    orderDirection: null,
    partners: null,
    pollingCompletion: 10,
    results: [],
    resultsTotal: null,
    searchParams: null,
    searchedProviders: 0,
    totalDeals: null,
    watchUrls: 0,
    watchUrlsPartnerIds: [],
    setSearchResults: (results, partners, totalDeals) =>
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          results: results,
          partners: partners,
          totalDeals: totalDeals,
        },
      })),
    setSearchQueryParams: () => {
      const params = [];
      const pageRequestId = getCookie('page-request-id');
      const sessionId = getCookie('session-id');
      if (pageRequestId) {
        params.push(`pageRequestId=${pageRequestId}`);
      }
      if (sessionId) {
        params.push(`sessionId=${sessionId}`);
      }

      const urlParams = new URL(document.location.href).searchParams;
      if (urlParams.get('pinnedAccommodationId')) {
        params.push(
          `pinnedAccommodationID=${urlParams.get('pinnedAccommodationId')}`
        );
      }

      const queryParamsStr = params.length > 0 ? `&${params.join('&')}` : '';

      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          queryParams: queryParamsStr,
        },
      }));
    },
    loadSearch: async () => {
      const config = get().searchResults.config;
      const searchParams = get().searchResults.searchParams;
      const queryParams = get().searchResults.queryParams;
      if (!searchParams) return;

      const fetchInitialResults = async (
        brokerApi: string
      ): Promise<{
        watchUrls: string[];
        metaUrl: string;
      }> => {
        const paramsString = get().searchResults.getOffersApiParams();
        const response = await fetch(
          `${brokerApi}/v2/packages/search?${paramsString}${queryParams}`,
          {
            credentials: 'include',
          }
        );
        const data = await response.json();
        if (data.results) {
          set((state) => ({
            ...state,
            searchResults: {
              ...state.searchResults,
              results: data.results,
              partners: data.partners,
            },
          }));
        }
        const urls: string[] = [];
        const watchUrlsPartnerIds: string[] = [];
        if (data.watchUrls) {
          for (const [key, value] of Object.entries(data.watchUrls)) {
            urls.push(value as string);
            watchUrlsPartnerIds.push(key);
          }
        }
        set((state) => ({
          ...state,
          searchResults: {
            ...state.searchResults,
            watchUrls: urls.length,
            watchUrlsPartnerIds: watchUrlsPartnerIds,
          },
        }));

        return {
          watchUrls: urls,
          metaUrl: data.metaUrl,
        };
      };

      const fetchMeta = async (brokerApi: string, metaUrl: string) => {
        const data = await (
          await fetch(`${brokerApi}${metaUrl}`, {
            credentials: 'include',
          })
        ).json();
        set((state) => ({
          ...state,
          searchResults: {
            ...state.searchResults,
            totalDeals: data.matchingOffersTotal,
            pollingCompletion: 100,
            filterData: data.filters,
            resultsTotal: data.matchingResultsTotal,
          },
        }));
        return data;
      };

      const fetchWatchUrl = async (brokerApi: string, watchUrl: string) => {
        const data = await (
          await fetch(`${brokerApi}${watchUrl}`, {
            credentials: 'include',
          })
        ).json();
        set((state) => {
          const newSearchedProviders =
            state.searchResults.searchedProviders + 1;

          return {
            ...state,
            searchResults: {
              ...state.searchResults,
              searchedProviders: newSearchedProviders,
              pollingCompletion:
                Math.ceil(
                  (newSearchedProviders / state.searchResults.watchUrls) * 100
                ) || state.searchResults.pollingCompletion,
              results: data.results
                ? data.results
                : state.searchResults.results,
              partners: data.partners
                ? data.partners
                : state.searchResults.partners,
            },
          };
        });
        return data;
      };

      const { watchUrls, metaUrl } = await fetchInitialResults(
        config.brokerApi
      );
      await Promise.all(
        watchUrls.map(
          async (watchUrl) => await fetchWatchUrl(config.brokerApi, watchUrl)
        )
      );
      await fetchMeta(config.brokerApi, metaUrl);
    },
    changeResults: async () => {
      const brokerApi = get().searchResults.config.brokerApi;
      const paramsString = get().searchResults.getOffersApiParams();
      const queryParams = get().searchResults.queryParams;
      window.scrollTo({ top: 0 });
      const response = await fetch(
        `${brokerApi}/v2/packages/search?${paramsString}${queryParams}`
      );
      const data = await response.json();
      if (data.results) {
        set((state) => ({
          ...state,
          searchResults: {
            ...state.searchResults,
            results: data.results,
            partners: data.partners,
          },
        }));
      }
    },
    setOrderBy: (obj: OrderByOption) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          orderBy: obj.filterValue,
          orderDirection: obj.orderDirection,
        },
      }));
    },
    setCurrentPage: (pageNumber: number) => {
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          currentPage: pageNumber,
        },
      }));
    },
    setSearchParamsFromExposedResultsSearchCriteria: (
      searchCriteria: ExposedResultsSearchCriteria
    ) => {
      const today = new Date();
      const result = today.setDate(today.getDate() + searchCriteria.dateOffset);
      const destinationCodes = searchCriteria.destinationCode.split('-');
      const obj: SearchParams = {
        duration: String(searchCriteria.duration),
        departureDate: new Date(result).toISOString().slice(0, 10),
        departureAirports: searchCriteria.departureCodes.split(',').join('+'),
        adults: String(searchCriteria.adults),
        childAges: '0',
        destinationCountry: destinationCodes[0],
        destinationArea: destinationCodes[1],
        destinationRegion: destinationCodes[2] || undefined,
      };
      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          searchParams: obj,
        },
      }));
    },
    setSearchParamsFromUrl: () => {
      if (typeof window === 'undefined') return null;
      const url = window.location.href;
      const urlSplit = url.split('?');
      const urlParts = urlSplit[0].split('/').reverse();
      //Gatsby v5 is adding the trailing slash. Check if it's empty and then remove from the array
      if (urlParts[0] === '') {
        urlParts.shift();
      }
      const [destinationCodesString, destinationId] = urlParts[4].split('+');
      const destinationCodes = destinationCodesString.split('-');
      const destinationRegion =
        destinationCodes.length === 3 ? destinationCodes[2] : null;
      const passengers = urlParts[0];
      const passengersSplit = passengers.split('+');

      const obj = {
        duration: urlParts[1],
        departureDate: urlParts[2],
        departureAirports: urlParts[3].split('+').join(),
        destinationId: destinationId,
        destinationCountry: destinationCodes[0],
        destinationArea: destinationCodes[1],
        ...(destinationRegion && { destinationRegion: destinationRegion }),
        adults: passengersSplit[0],
        childAges: passengersSplit.splice(1).join(),
      };

      set((state) => ({
        ...state,
        searchResults: {
          ...state.searchResults,
          searchParams: obj,
        },
      }));

      return true;
    },
    getPartner: (id) => {
      const partners = get().searchResults.partners;
      return (partners && partners[id]) || null;
    },
    getSearchResultsPageUrl: (searchParams) => {
      const config = get().searchResults.config;
      const destinationCodes = [
        searchParams.destinationCountry,
        searchParams.destinationArea,
        searchParams.destinationRegion,
      ]
        .filter((value) => !!value)
        .join('-');
      const destinationString = `${destinationCodes}+${
        searchParams.destinationId || ''
      }`;
      const airportsString = searchParams.departureAirports
        .split(',')
        .join('+');
      const dateString = searchParams.departureDate;
      const durationString = searchParams.duration;
      const passengersString = [
        searchParams.adults,
        ...searchParams.childAges.split(','),
      ]
        .filter((value) => !!value)
        .join('+');
      return `/${config.resultsPageUrlStem}/${destinationString}/${airportsString}/${dateString}/${durationString}/${passengersString}`;
    },
    getOffersApiParams: () => {
      const config = get().searchResults.config;
      const searchParams = get().searchResults.searchParams;
      if (!searchParams) return;
      const offersApiParamsString = [
        `perPage=${config.resultsPerPage}`,
        `area=${searchParams.destinationArea}`,
        `country=${searchParams.destinationCountry}`,
        searchParams.destinationRegion
          ? `region=${searchParams.destinationRegion}`
          : null,
        `page=${get().searchResults.currentPage}`,
        `orderBy=${get().searchResults.orderBy}`,
        get().searchResults.orderDirection
          ? `orderDirection=${get().searchResults.orderDirection}`
          : null,
        `departureDate=${searchParams.departureDate}`,
        `duration=${searchParams.duration}`,
        searchParams.destinationId
          ? `placeKey=${searchParams.destinationId}`
          : null,
        `adults=${searchParams.adults}`,
        `childAges=${searchParams.childAges}`,
        `departureAirports=${searchParams.departureAirports}`,
      ];
      return offersApiParamsString.join('&');
    },
  });
