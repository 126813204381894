import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../css/sizes';
import { XLg } from '../icon';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import type { BodyScrollOptions } from 'body-scroll-lock-upgrade';
import FocusLock from 'react-focus-lock';

const Overlay = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const OverlayCloseLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div<{
  maxWidth?: string;
  fullscreen?: boolean;
}>`
  ${(props) =>
    props.fullscreen
      ? css``
      : css<{
          maxWidth?: string;
          fullscreen?: boolean;
        }>`
          margin: ${(props) => props.theme.spacing._2}
            ${(props) => props.theme.spacing._2} auto;
          max-height: 86vh;
          max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
          border-radius: ${(props) => props.theme.spacing._1};
          @media ${device.md} {
            margin: auto;
            width: 80%;
            height: auto;
          }
        `};
  background: #fff;
  width: 100%;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const CloseButton = styled.button`
  border: 0;
  margin: 0;
  background: none;
  color: ${(props) => props.theme.colors.black};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing.unit * 2.25}px
    ${(props) => props.theme.spacing._2};
  > svg {
    width: ${(props) => props.theme.spacing.unit * 2.5}px;
  }
  @media ${device.md} {
    padding: ${(props) => props.theme.spacing._2};
    > svg {
      width: auto;
    }
  }
`;

const StyledFocusLock = styled(FocusLock)`
  height: 100%;
`;

const handleKeydown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  callback: () => void
) => {
  if (e.key === 'Escape') callback();
};

interface FadeProps {
  show: boolean;
  onClose: () => void;
  ariaLabelledby: string;
  children: React.ReactNode;
}

const Fade = ({ show, onClose, ariaLabelledby, children }: FadeProps) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  if (shouldRender) {
    return (
      <Overlay
        role="dialog"
        aria-labelledby={ariaLabelledby}
        aria-modal="true"
        tabIndex={0}
        onKeyDown={(e) => handleKeydown(e, onClose)}
        style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} 0.25s forwards` }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </Overlay>
    );
  } else {
    return null;
  }
};

export interface ModalProps {
  showModal: boolean;
  onClose: () => void;
  ariaLabelledby: string;
  children: React.ReactNode;
  disableBodyScrollWhenOpen?: boolean;
  maxWidth?: string;
  hideCloseButton?: boolean;
  fullscreen?: boolean;
}

export const Modal = ({
  showModal,
  onClose,
  ariaLabelledby,
  children,
  maxWidth,
  hideCloseButton,
  fullscreen,
  disableBodyScrollWhenOpen,
}: ModalProps) => {
  useEffect(() => {
    if (!disableBodyScrollWhenOpen) return;
    setTimeout(() => {
      const modal = document.getElementById('modal-content');
      const options: BodyScrollOptions = {
        reserveScrollBarGap: true,
      };
      if (modal) {
        if (showModal) {
          disableBodyScroll(modal, options);
        } else {
          setTimeout(() => {
            enableBodyScroll(modal);
          }, 250);
        }
      }
    }, 50);
  });

  return (
    <Fade show={showModal} ariaLabelledby={ariaLabelledby} onClose={onClose}>
      <OverlayCloseLayer onClick={onClose}></OverlayCloseLayer>
      <ContentWrapper
        maxWidth={maxWidth}
        fullscreen={fullscreen}
        id="modal-content"
      >
        <StyledFocusLock>
          {!hideCloseButton && (
            <CloseButton onClick={onClose} aria-label="Close">
              <XLg height={16} width={16} />
            </CloseButton>
          )}
          {children}
        </StyledFocusLock>
      </ContentWrapper>
    </Fade>
  );
};

Modal.defaultProps = {
  disableBodyScrollWhenOpen: true,
};
