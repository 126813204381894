import React, { useState, useEffect, useRef } from 'react';
import { SearchResultsTypes } from '@supersonic/store';
import { ResultRow } from '../resultRow';
import { SkeletonBlock, SkeletonResultRow } from '../../skeleton';
import styled, { ThemeColor } from 'styled-components';
import { device } from '../../../css/sizes';
import { Button } from '../../button';
import { StarRating } from '../../starRating';
import { InCardCarousel } from '../inCardCarousel';
import { ModalCarousel } from '../modalCarousel';
import { ArrowsFullscreen, Share, WhatsApp, Envelope } from '../../icon';
import { Modal } from '../../modal';
import { ModalHeader } from '../modalHeader';
import { TrustYouReviews } from '../../trustYouReviews';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

const CardWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.spacing._1};
  margin-bottom: ${(props) => props.theme.spacing._3};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
`;

const CardHeader = styled.div<{ dividerColor?: ThemeColor }>`
  border-bottom: 1px solid
    ${(props) =>
      props.dividerColor
        ? props.theme.colors[props.dividerColor]
        : props.theme.colors.black};
  @media ${device.md} {
    display: flex;
    height: 220px;
  }
  overflow: hidden;
`;

const YourSelectedProperty = styled.p.attrs({
  className: 'font-golf font-golf--bold',
})<{
  yourSelectedPropertyBackgroundColor?: ThemeColor;
}>`
  background-color: ${(props) =>
    props.yourSelectedPropertyBackgroundColor
      ? props.theme.colors[props.yourSelectedPropertyBackgroundColor]
      : props.theme.colors.grey6};
  color: ${(props) => props.theme.colors.white};
  margin: 0;
  padding: ${(props) => props.theme.spacing._1}
    ${(props) => props.theme.spacing._2};
  border-top-left-radius: ${(props) => props.theme.spacing._1};
  border-top-right-radius: ${(props) => props.theme.spacing._1};
`;

const CarouselWrapper = styled.div<{
  isYourSelectedProperty?: boolean;
}>`
  position: relative;
  overflow: hidden;
  ${(props) =>
    props.isYourSelectedProperty
      ? ''
      : `border-top-left-radius: ${props.theme.spacing._1};`}
  @media ${device.md} {
    width: 305px;
  }
`;

const ZoomButton = styled.button`
  background: rgba(255, 255, 255, 0.9);
  border: 0;
  position: absolute;
  padding: ${(props) => props.theme.spacing._1};
  border-radius: ${(props) => props.theme.spacing._2};
  right: ${(props) => props.theme.spacing._1};
  top: ${(props) => props.theme.spacing._1};
  z-index: 1;
  &:focus-visible {
    outline: 4px solid ${(props) => props.theme.colors.grey7};
  }
`;

const AccommodationHeading = styled.h3.attrs({
  className: 'font-echo',
})`
  margin: 0 0 ${(props) => props.theme.spacing._1};
  color: ${(props) => props.theme.colors.black};
`;

const AccommodationLocation = styled.p.attrs({
  className: 'font-golf',
})`
  color: ${(props) => props.theme.colors.grey7};
  margin: ${(props) => props.theme.spacing._1} 0 0;
`;

const AccommodationInfo = styled.div`
  color: ${(props) => props.theme.colors.black};
  padding: ${(props) => props.theme.spacing._2};
  @media ${device.md} {
    flex: 1;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  @media ${device.lg} {
    padding: ${(props) => props.theme.spacing._3};
  }
`;

const AccommodationWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PopUpWrapper = styled.div`
  position: absolute;
  bottom: 65%;
  right: 0%;
  width: ${(props) => props.theme.spacing._11};
  height: ${(props) => props.theme.spacing._6};
  border-radius: ${(props) => props.theme.spacing._1};
  padding: ${(props) => props.theme.spacing._1};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing._1};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;

const IconLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.spacing._4};
  height: ${(props) => props.theme.spacing._4};
  border-radius: 50%;
  text-decoration: none;
`;

const WhatsAppIconWrapper = styled(IconLink)`
  background-color: #25d366;
  color: ${(props) => props.theme.colors.white};
`;

const EnvelopeIconWrapper = styled(IconLink)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
`;

const TrustYouShareWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${(props) => props.theme.spacing._3};
`;

export interface ResultCardProps {
  accommodation?: SearchResultsTypes.Accommodation;
  deals?: SearchResultsTypes.PartnerOffers[];
  priceMode: string;
  dividerColor?: ThemeColor;
  compareMoreRowColor?: ThemeColor;
  linkColor?: ThemeColor;
  linkHoverColor?: ThemeColor;
  labelTextColor?: ThemeColor;
  priceTextColor?: ThemeColor;
  uspBackgroundColor?: ThemeColor;
  uspIconColor?: ThemeColor;
  outboundInboundLabelColor?: ThemeColor;
  flightInfoItemBackgroundColor?: ThemeColor;
  flightInfoItemTextColor?: ThemeColor;
  boardBasisBackgroundColor?: ThemeColor;
  yourSelectedPropertyBackgroundColor?: ThemeColor;
  isYourSelectedProperty?: boolean;
  loaded: boolean;
}

export const ResultCard = ({
  accommodation,
  deals,
  priceMode,
  dividerColor,
  compareMoreRowColor,
  linkColor,
  linkHoverColor,
  labelTextColor,
  priceTextColor,
  uspBackgroundColor,
  uspIconColor,
  outboundInboundLabelColor,
  flightInfoItemBackgroundColor,
  flightInfoItemTextColor,
  boardBasisBackgroundColor,
  yourSelectedPropertyBackgroundColor,
  isYourSelectedProperty,
  loaded,
}: ResultCardProps) => {
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const popUpRef = useRef<HTMLDivElement | null>(null);

  const sharedText = 'Look at the amazing deals I found for this hotel!';
  const createShareUrl = () => {
    const url = new URL(window.location.href);
    if (accommodation) {
      url.searchParams.set(
        'pinnedAccommodationId',
        accommodation.accommodationId
      );
    }
    return url.href;
  };

  const isDesktop = useMediaQuery(device.lg);

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowSharePopup(false);
  };

  const handleShareClick = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setShowSharePopup((showSharePopup) => !showSharePopup);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popUpRef.current &&
        !popUpRef.current.contains(event.target as Node)
      ) {
        setShowSharePopup(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const showGalleryModalToggleHandler = () => {
    setShowGalleryModal(!showGalleryModal);
  };
  if (loaded && accommodation && deals) {
    return (
      <CardWrapper>
        {isYourSelectedProperty && (
          <YourSelectedProperty
            data-testid="results-your-selected-property"
            yourSelectedPropertyBackgroundColor={
              yourSelectedPropertyBackgroundColor
            }
          >
            Your selected property
          </YourSelectedProperty>
        )}
        <CardHeader
          dividerColor={dividerColor}
          data-testid="results-card-header"
        >
          <CarouselWrapper isYourSelectedProperty={isYourSelectedProperty}>
            <ZoomButton
              aria-label="View all"
              onClick={showGalleryModalToggleHandler}
            >
              <ArrowsFullscreen height={16} width={16} />
            </ZoomButton>
            {/* <InCardCarousel imageList={accommodation.images} /> */}
          </CarouselWrapper>
          <AccommodationInfo data-testid="results-accommodation-description">
            <AccommodationWrapper>
              <AccommodationHeading>
                {accommodation.accommodationName}
              </AccommodationHeading>
              <StarRating rating={accommodation.rating} />
              <AccommodationLocation>
                {accommodation.regionName}, {accommodation.areaName},{' '}
                {accommodation.countryName}
              </AccommodationLocation>
            </AccommodationWrapper>
            <TrustYouShareWrapper>
              <TrustYouReviews rating={9.9} reviews={3456} />
              <Button
                icon={'Share'}
                type={'aux'}
                size={'small'}
                text={'Share'}
                onClick={handleShareClick}
              ></Button>
              {showSharePopup && (
                <PopUpWrapper ref={popUpRef}>
                  <WhatsAppIconWrapper
                    href={`https://${
                      isDesktop ? 'web' : 'api'
                    }.whatsapp.com/send?text=${encodeURIComponent(
                      sharedText + ' ' + createShareUrl()
                    )}`}
                    aria-label="Share on WhatsApp"
                    target="_blank"
                    onClick={handleIconClick}
                  >
                    <WhatsApp ariaLabel="WhatsApp" width={19.9} height={20} />
                  </WhatsAppIconWrapper>
                  <EnvelopeIconWrapper
                    href={`mailto:?subject=${encodeURIComponent(sharedText)}
                       &body=${encodeURIComponent(`${createShareUrl()}`)}`}
                    aria-label="Share via Email"
                    target="_blank"
                    onClick={handleIconClick}
                  >
                    <Envelope ariaLabel="Envelope" width={19.9} height={20} />
                  </EnvelopeIconWrapper>
                </PopUpWrapper>
              )}
            </TrustYouShareWrapper>
          </AccommodationInfo>
        </CardHeader>
        {deals &&
          deals.map((offer, index) => {
            return (
              <ResultRow
                key={index}
                firstRow={index === 0 ? true : false}
                deal={offer.offers[0]}
                priceMode={priceMode}
                showProviderLogo={true}
                showCompareMore={offer.offersTotal > 1 ? true : false}
                compareMoreRowColor={compareMoreRowColor}
                linkColor={linkColor}
                linkHoverColor={linkHoverColor}
                labelTextColor={labelTextColor}
                priceTextColor={priceTextColor}
                uspBackgroundColor={uspBackgroundColor}
                uspIconColor={uspIconColor}
                outboundInboundLabelColor={outboundInboundLabelColor}
                flightInfoItemBackgroundColor={flightInfoItemBackgroundColor}
                flightInfoItemTextColor={flightInfoItemTextColor}
                boardBasisBackgroundColor={boardBasisBackgroundColor}
                accommodation={accommodation}
              />
            );
          })}
        <Modal
          showModal={showGalleryModal}
          onClose={showGalleryModalToggleHandler}
          ariaLabelledby="comparemore-modal-heading"
          maxWidth="845px"
        >
          <>
            <ModalHeader
              accommodationName={accommodation.accommodationName}
              accommodationLocation={`${accommodation.regionName}, ${accommodation.areaName}, ${accommodation.countryName}`}
              starRating={accommodation.rating}
            />
            <ModalCarousel imageList={accommodation.images} />
          </>
        </Modal>
      </CardWrapper>
    );
  } else {
    return (
      <CardWrapper>
        <CardHeader dividerColor={dividerColor}>
          <CarouselWrapper>
            <SkeletonBlock
              noAnimation
              squareCorners
              h="200px"
              hLg="100%"
              data-testid="results-card-skeleton-block"
            />
          </CarouselWrapper>
          <AccommodationInfo>
            <AccommodationWrapper>
              <AccommodationHeading>
                <SkeletonBlock />
              </AccommodationHeading>
              <SkeletonBlock />
              <AccommodationLocation>
                <SkeletonBlock />
              </AccommodationLocation>
            </AccommodationWrapper>
            <TrustYouShareWrapper>
              <SkeletonBlock w="40%" />
              <SkeletonBlock w="40%" />
            </TrustYouShareWrapper>
          </AccommodationInfo>
        </CardHeader>
        <SkeletonResultRow />
        <SkeletonResultRow />
        <SkeletonResultRow />
      </CardWrapper>
    );
  }
};
