declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

export type GA4Event = {
  event: string;
  product: undefined;
  module: string;
  action: string;
  content_display?: string;
  content_code?: string | number;
  validation?: string | number;
  open_time?: number;
};

export class GA4 {
  send = (obj: GA4Event, shouldReset = false): boolean => {
    window.dataLayer = window.dataLayer || [];

    if (window.dataLayer) {
      if (shouldReset) {
        window.dataLayer.push(function () {
          // @ts-ignore add type annotation for `this`
          this.reset();
        });
      }
      window.dataLayer.push(obj);
      return true;
    }
    return false;
  };
}

export default new GA4();
