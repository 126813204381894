import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import type {
  UseBudgetHistogramHookInput,
  UseBudgetHistogramHookResult,
  UseBudgetHistogramHookState,
} from './types';
import {
  calculateSelectedPriceLimitationFromPriceRange,
  calculateSelectedPriceLimitationAfterInputValueChange,
  initializePriceRangeAndInputs,
  reinitializePriceRangeAndInputs,
  calculatePriceBucketsWithAvailability,
} from './utils';
import { SelectedPriceLimitationChangeSource } from './types';

type InputType = 'min' | 'max';

const useBudgetHistogram = ({
  priceLimitation,
  priceBuckets,
  selectedPriceLimitation,
  onSelectedPriceLimitationChange,
  delayWhenTypingForOnSelectedPriceLimitationChange,
}: UseBudgetHistogramHookInput): UseBudgetHistogramHookResult => {
  const [state, setState] = useState<UseBudgetHistogramHookState>({
    ...initializePriceRangeAndInputs(priceLimitation, selectedPriceLimitation),
    isPriceRangeMinInputFocused: false,
    isPriceRangeMaxInputFocused: false,
  });
  useEffect(() => {
    setState({
      ...state,
      ...reinitializePriceRangeAndInputs(
        state,
        priceLimitation,
        selectedPriceLimitation
      ),
    });
  }, [
    selectedPriceLimitation[0],
    selectedPriceLimitation[1],
    state.isPriceRangeMinInputFocused,
    state.isPriceRangeMaxInputFocused,
  ]);
  const debouncedOnSelectedPriceLimitationChange = useCallback(
    debounce(
      onSelectedPriceLimitationChange,
      delayWhenTypingForOnSelectedPriceLimitationChange
    ),
    []
  );
  const handlePriceRangeChange = (newPriceRange: [number, number]) => {
    setState({
      ...state,
      priceRange: [...newPriceRange],
      priceRangeMinInputValue: `${newPriceRange[0]}`,
      priceRangeMaxInputValue:
        newPriceRange[1] >= priceLimitation[1]
          ? `${newPriceRange[1]}+`
          : `${newPriceRange[1]}`,
    });
  };
  const handlePriceRangeAfterChange = () => {
    const newSelectedPriceLimitation =
      calculateSelectedPriceLimitationFromPriceRange(
        state.priceRange,
        priceLimitation
      );
    const changes: SelectedPriceLimitationChangeSource[] = [];
    if (newSelectedPriceLimitation[0] !== selectedPriceLimitation[0]) {
      changes.push('min slider move');
    }
    if (newSelectedPriceLimitation[1] !== selectedPriceLimitation[1]) {
      changes.push('max slider move');
    }
    onSelectedPriceLimitationChange(newSelectedPriceLimitation, changes);
  };
  const handlePriceRangeInputFocus = (inputType: InputType) => () => {
    setState({
      ...state,
      isPriceRangeMinInputFocused:
        inputType === 'min' ? true : state.isPriceRangeMinInputFocused,
      isPriceRangeMaxInputFocused:
        inputType === 'max' ? true : state.isPriceRangeMaxInputFocused,
    });
  };
  const handlePriceRangeInputChange =
    (inputType: InputType) => (newValue: string) => {
      if (newValue !== '' && !newValue.match(/^[0-9]+$/)) {
        return;
      }
      const nextState: UseBudgetHistogramHookState = {
        ...state,
        priceRangeMinInputValue:
          inputType === 'min' ? newValue : state.priceRangeMinInputValue,
        priceRangeMaxInputValue:
          inputType === 'max' ? newValue : state.priceRangeMaxInputValue,
      };
      setState(nextState);
      debouncedOnSelectedPriceLimitationChange(
        calculateSelectedPriceLimitationAfterInputValueChange(
          nextState,
          priceLimitation,
          selectedPriceLimitation
        ),
        [inputType === 'min' ? 'min input type in' : 'max input type in']
      );
    };
  const handlePriceRangeInputBlur = (inputType: InputType) => () => {
    debouncedOnSelectedPriceLimitationChange.cancel();
    onSelectedPriceLimitationChange(
      calculateSelectedPriceLimitationAfterInputValueChange(
        state,
        priceLimitation,
        selectedPriceLimitation
      ),
      [inputType === 'min' ? 'min input blur' : 'max input blur']
    );
    setState({
      ...state,
      isPriceRangeMinInputFocused:
        inputType === 'min' ? false : state.isPriceRangeMinInputFocused,
      isPriceRangeMaxInputFocused:
        inputType === 'max' ? false : state.isPriceRangeMaxInputFocused,
    });
  };

  return {
    priceRange: state.priceRange,
    priceBucketsWithAvailability: calculatePriceBucketsWithAvailability(
      priceBuckets,
      priceLimitation,
      state.priceRange
    ),
    priceRangeInputMinValue: state.priceRangeMinInputValue,
    priceRangeInputMaxValue: state.priceRangeMaxInputValue,
    handlePriceRangeChange,
    handlePriceRangeAfterChange,
    handlePriceRangeMinInputFocus: handlePriceRangeInputFocus('min'),
    handlePriceRangeMaxInputFocus: handlePriceRangeInputFocus('max'),
    handlePriceRangeMinInputChange: handlePriceRangeInputChange('min'),
    handlePriceRangeMaxInputChange: handlePriceRangeInputChange('max'),
    handlePriceRangeMinInputBlur: handlePriceRangeInputBlur('min'),
    handlePriceRangeMaxInputBlur: handlePriceRangeInputBlur('max'),
  };
};

export default useBudgetHistogram;
