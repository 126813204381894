import React from 'react';

export const ZarazTestingComponent = () => {
  const handleClick = () => {
    // Temporary fix. For proper zaraz integration we'll have to add its type to window or use helper library
    // @ts-ignore: TODO
    window.zaraz.track('test-event', { eventData: 'test-data' });
  };

  return <button onClick={handleClick}>zaraz testing button</button>;
};
