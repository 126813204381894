import React, {
  useRef,
  FocusEventHandler,
  useState,
  KeyboardEventHandler,
} from 'react';
import styled, { css, ThemeColor } from 'styled-components';
import { device } from '../../../css/sizes';
import { Chevron } from '../../icon';
import { SubNav2Level } from '../subNav2Level';
import { SubNav3Level } from '../subNav3Level';
import { NavCard } from '../navCard';
import { tracking } from '../utils';

import {
  ConfigNavigationSubsection,
  ConfigNavigationCard,
  ConfigNavigationSubnavTheme,
} from '@supersonic/config';

//https://mui.com/base/api/click-away-listener/
const NavListItem = styled.li.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})`
  padding: 0 ${(props) => props.theme.spacing._2};
  @media ${device.lg} {
    align-items: stretch;
    display: flex;
    padding: 0;
  }
`;

const NavTopLink = styled.a<{
  $borderColor?: ThemeColor;
  $focusColor?: ThemeColor;
  $linkColor?: ThemeColor;
  $linkHoverColor?: ThemeColor;
  $toggle: boolean;
}>`
  align-items: end;
  color: ${(props) =>
    props.$linkColor
      ? props.theme.colors[props.$linkColor]
      : props.theme.colors.black};
  display: none;
  flex-wrap: wrap;
  padding: 0 ${(props) => props.theme.spacing._2};
  text-decoration: none;
  transition: color 0.2s ease-out;
  width: 100%;
  @media ${device.lg} {
    display: flex;
  }
  &:after {
    background: ${(props) =>
      props.$toggle
        ? props.$borderColor
          ? props.theme.colors[props.$borderColor]
          : props.theme.colors.black
        : 'transparent'};
    display: block;
    flex: none;
    height: 8px;
    width: 100%;
    @media ${device.lg} {
      content: '';
    }
  }
  &:hover,
  &:focus-visible {
    color: ${(props) =>
      props.$linkHoverColor
        ? props.theme.colors[props.$linkHoverColor]
        : props.theme.colors.black};
  }
  &:focus-visible {
    outline: none;
    box-shadow: ${(props) =>
      props.$focusColor
        ? `0px 0px 0px ${props.theme.spacing._05} ${
            props.theme.colors[props.$focusColor]
          } inset`
        : `0px 0px 0px ${props.theme.spacing._05} ${props.theme.colors.black} inset`};
    &:after {
      background: transparent;
    }
  }
`;

const NavAlign = styled.span`
  display: flex;
  width: 100%;
`;

const NavChevron = styled.span<{
  chevronColor?: ThemeColor;
  toggle: boolean;
}>`
  color: ${(props) =>
    props.chevronColor
      ? props.theme.colors[props.chevronColor]
      : 'currentColor'};
  display: flex;
  flex: none;
  margin-left: auto;
  margin-right: 3px;
  transform: ${(props) => (props.toggle ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s;
  @media ${device.lg} {
    margin-left: ${(props) => props.theme.spacing._1};
    margin-right: 0;
  }
`;

const DropDownContainer = styled.div<{
  toggle: boolean;
}>`
  background: ${(props) => props.theme.colors.white};
  height: 100vh;
  display: block;
  left: 0;
  padding: 0 ${(props) => props.theme.spacing._2};
  position: absolute;
  top: 0;
  width: 100%;
  visibility: ${(props) => (props.toggle ? 'visible' : 'hidden')};
  transform: ${(props) =>
    props.toggle ? 'translate3d(0%,0,0)' : 'translate3d(100%,0px,0)'};
  transition: ${(props) =>
    props.toggle
      ? '0.2s ease-in transform'
      : '0.2s ease-in transform, visibility 0s ease-in 0.2s'};
  z-index: 1;
  @media ${device.lg} {
    border-radius: 0 0 ${(props) => props.theme.spacing._1}
      ${(props) => props.theme.spacing._1};
    box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.2);
    display: ${(props) => (props.toggle ? 'flex' : 'none')};
    height: auto;
    padding: 0 ${(props) => props.theme.spacing._5}
      ${(props) => props.theme.spacing._5} 0;
    top: calc(100% + 1px);
    transform: translate3d(0, 0, 0);
    visibility: ${(props) => (props.toggle ? 'visible' : 'hidden')};
  }
`;

const CtaGrid = styled.div<{
  count: undefined | number;
  subnav2Level: boolean;
}>`
  align-items: start;
  display: flex;
  flex-basis: min-content;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing._3};
  margin-left: auto;
  padding-top: ${(props) => props.theme.spacing._5};
  width: 100%;
  @media ${device.lg} {
    flex: ${(props) => (props.subnav2Level ? '1.5' : '0.5')};
    flex-direction: row;
    margin-left: ${(props) => props.theme.spacing._1};
    ${(props) => {
      if (props.count === 1) {
        return `
        min-width: 240px;
        width: 240px;
        `;
      } else {
        return `
        min-width: 35%;
        width: auto;
        @media ${device.xxl} {
          flex-basis: auto;
        }
        `;
      }
    }}
  }
  @media ${device.xxl} {
    flex: none;
  }
`;

const mobileTopLevelStyles = css`
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey2};
  color: inherit;
  display: flex;
  font-weight: inherit;
  padding: ${(props) => props.theme.spacing._2} 0;
  text-decoration: none;
  width: 100%;
  @media ${device.lg} {
    display: none;
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px ${(props) => props.theme.spacing._05}
      ${(props) => props.theme.colors.black} inset;
  }
`;

const MobileTopLevelLink = styled.a`
  ${mobileTopLevelStyles}
`;

const MobileTopLevelButton = styled.button`
  ${mobileTopLevelStyles}
`;

const MobileTopLevelButtonChevron = styled.span<{
  chevronColor?: ThemeColor;
}>`
  color: ${(props) =>
    props.chevronColor
      ? props.theme.colors[props.chevronColor]
      : 'currentColor'};
  margin-left: auto;
  transform: rotate(270deg);
`;

const MobileReturnButton = styled.button.attrs({
  className: 'font-hotel',
})<{
  focusColor?: ThemeColor;
}>`
  align-items: center;
  background: ${(props) => props.theme.colors.sand};
  border: 0;
  display: flex;
  left: -${(props) => props.theme.spacing._2};
  padding: ${(props) => props.theme.spacing._2};
  position: relative;
  text-align: left;
  width: calc(100% + ${(props) => props.theme.spacing._4});
  &:focus-visible {
    outline: none;
    box-shadow: ${(props) =>
      props.focusColor
        ? `0px 0px 0px ${props.theme.spacing._05} ${
            props.theme.colors[props.focusColor]
          } inset`
        : `0px 0px 0px ${props.theme.spacing._05} ${props.theme.colors.black} inset`};
  }
  @media ${device.lg} {
    display: none;
  }
`;

const MobileReturnButtonChannelName = styled.span.attrs({
  className: 'font-hotel--bold',
})`
  margin-left: ${(props) => props.theme.spacing._05};
`;

const MobileReturnButtonChevron = styled.span`
  margin-right: ${(props) => props.theme.spacing._2};
  transform: rotate(90deg);
`;

interface NavItemProps {
  name: string;
  link: string;
  subnav?: ConfigNavigationSubsection[];
  subnavTheme?: ConfigNavigationSubnavTheme;
  borderColor?: ThemeColor;
  cards?: ConfigNavigationCard[];
  chevronColor?: ThemeColor;
  focusColor?: ThemeColor;
  linkColor?: ThemeColor;
  linkHoverColor?: ThemeColor;
  mobileChevronColor?: ThemeColor;
  tagColor?: ThemeColor;
}

export const NavItem = ({
  name,
  link,
  subnav,
  subnavTheme,
  borderColor,
  cards,
  chevronColor,
  focusColor,
  linkColor,
  linkHoverColor,
  mobileChevronColor,
  tagColor,
}: NavItemProps) => {
  const [isOpen, setOpen] = useState(false);
  const [tracked, setTracked] = useState({ open: false, close: false });
  const wrapperRef = useRef<HTMLLIElement>(null);
  const desktopTopLevelLinkRef = useRef<HTMLAnchorElement>(null);
  const mobileTopLevelButtonRef = useRef<HTMLButtonElement>(null);
  const mobileCloseSectionButtonRef = useRef<HTMLButtonElement>(null);

  // make this a global/reusable function?
  const ifDesktop = () => {
    const mql = window.matchMedia(device.lg);
    return mql.matches;
  };

  const handleMouseEnter = () => {
    if (ifDesktop()) {
      if (subnav) {
        setOpen(true);
      }
      if (!tracked.open) {
        tracking(`${name}`, undefined, 'auto-open');
        setTracked({ ...tracked, open: true });
      }
    }
  };

  const handleMouseLeave = () => {
    if (ifDesktop()) {
      if (subnav) {
        setOpen(false);
      }
      if (!tracked.close) {
        tracking(`${name}`, undefined, 'auto-close');
        setTracked({ ...tracked, open: true });
      }
    }
  };

  const handleBlur: FocusEventHandler = (e) => {
    if (!wrapperRef.current?.contains(e.relatedTarget)) {
      setOpen(false);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'Escape') {
      setOpen(false);
      if (ifDesktop()) {
        desktopTopLevelLinkRef.current?.focus();
      } else {
        mobileTopLevelButtonRef.current?.focus();
      }
    }
  };

  const handleTopLinkKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'Space') {
      e.preventDefault();
      setOpen(!isOpen);
    }
  };

  const handleMobileTopButtonClick = () => {
    setOpen(true);
    setTimeout(() => {
      mobileCloseSectionButtonRef.current?.focus();
    }, 200);
    if (!tracked.open) {
      tracking(`${name}`, undefined, 'expand');
      setTracked({ ...tracked, open: true });
    }
  };

  const handleMobileReturnButtonClick = () => {
    setOpen(false);
    mobileTopLevelButtonRef.current?.focus();
    if (!tracked.close) {
      tracking(`${name}`, undefined, 'collapse');
      setTracked({ ...tracked, open: true });
    }
  };

  let subnav2Level = true;

  if (subnav && subnav.length > 1) {
    subnav2Level = false;
  }

  const ctaCount = cards?.length;

  const subnavConditionalRender = () => {
    if (subnav && subnav2Level) {
      return (
        <SubNav2Level
          name={name}
          subnav={subnav}
          theme={subnavTheme}
        ></SubNav2Level>
      );
    } else {
      return (
        <SubNav3Level
          name={name}
          subnav={subnav}
          theme={subnavTheme}
        ></SubNav3Level>
      );
    }
  };

  return (
    <NavListItem
      onMouseEnter={subnav ? handleMouseEnter : undefined}
      onMouseLeave={subnav ? handleMouseLeave : undefined}
      onBlur={handleBlur}
      ref={wrapperRef}
      onKeyDown={handleKeyDown}
    >
      <NavTopLink
        $borderColor={borderColor}
        $focusColor={focusColor}
        $linkColor={linkColor}
        $linkHoverColor={linkHoverColor}
        $toggle={isOpen}
        href={link}
        onKeyDown={handleTopLinkKeyDown}
      >
        <NavAlign onClick={() => tracking(`${name}`, `${name}`, 'select')}>
          {name}
          {subnav && subnav.length && (
            <NavChevron chevronColor={chevronColor} toggle={isOpen}>
              <Chevron height={16} width={16} />
            </NavChevron>
          )}
        </NavAlign>
      </NavTopLink>

      {subnav ? (
        <MobileTopLevelButton
          ref={mobileTopLevelButtonRef}
          onClick={handleMobileTopButtonClick}
          aria-expanded={isOpen}
          aria-controls={`${name
            .toLowerCase()
            .replaceAll(' ', '-')}-navigation-menu`}
        >
          {name}
          <MobileTopLevelButtonChevron chevronColor={mobileChevronColor}>
            <Chevron height={24} width={24} />
          </MobileTopLevelButtonChevron>
        </MobileTopLevelButton>
      ) : (
        <MobileTopLevelLink href={link}>{name}</MobileTopLevelLink>
      )}

      {subnav ? (
        <DropDownContainer
          toggle={isOpen}
          data-testid={`${name.toLowerCase().replaceAll(' ', '-')}-subnav`}
          id={`${name.toLowerCase().replaceAll(' ', '-')}-navigation-menu`}
        >
          <MobileReturnButton
            onClick={handleMobileReturnButtonClick}
            ref={mobileCloseSectionButtonRef}
            focusColor={focusColor}
          >
            <MobileReturnButtonChevron>
              <Chevron height={14} width={14} />
            </MobileReturnButtonChevron>
            Main menu /
            <MobileReturnButtonChannelName>
              {name}
            </MobileReturnButtonChannelName>
          </MobileReturnButton>

          {subnavConditionalRender()}

          {cards && cards.length > 0 ? (
            <CtaGrid count={ctaCount} subnav2Level={subnav2Level}>
              {cards?.map((cta, index) => {
                return (
                  <NavCard
                    key={index}
                    image={`${cta.image}`}
                    subText={cta.subText}
                    tag={cta.tag}
                    title={cta.title}
                    tagColor={tagColor}
                    link={cta.link}
                  />
                );
              })}
            </CtaGrid>
          ) : null}
        </DropDownContainer>
      ) : null}
    </NavListItem>
  );
};

/*
TODOS AND THINGS

need to sort out fonts:
  - some fonts now have a different font weighting (has Keena updated the DS?)
  - need a Typography component really


*/
