export type CloudflareImageOptions = {
  format?: 'auto';
  width?: number | 'auto';
  height?: number | 'auto';
  dpr?: 1 | 2;
  fit?: 'cover' | 'contain';
};

export const generateCloudflareImageSrc = (
  imgPath: string,
  cloudflareImagesUrl: string,
  options: CloudflareImageOptions
): string => {
  const finalOptions: CloudflareImageOptions = {
    fit: 'cover',
    format: 'auto',
    ...options,
  };

  const optionsString = Object.entries(finalOptions)
    .map(([key, value]) => `${key}=${String(value)}`)
    .join(',');

  return `${cloudflareImagesUrl}/cdn-cgi/image/${optionsString}/${imgPath}`;
};
