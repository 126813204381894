import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export interface AccordionProps {
  expanded: boolean;
  id: string;
  ariaLabelledby: string;
  className?: string;
  children: React.ReactNode;
}

const Wrapper = styled.div<{ inert?: string | null; delayedOverflow: string }>`
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  &[aria-hidden='false'] {
    max-height: 1500px;
    transition: max-height 1s ease-in-out;
    overflow: ${(props) => props.delayedOverflow};
  }
`;

export const Accordion = ({
  expanded,
  id,
  ariaLabelledby,
  className,
  children,
}: AccordionProps) => {
  const [delayedOverflow, setDelayedOverflow] = useState(false);

  useEffect(() => {
    if (expanded) {
      const timeoutId = setTimeout(() => {
        setDelayedOverflow(true);
      }, 250);
      return () => clearTimeout(timeoutId);
    } else {
      setDelayedOverflow(false);
      return undefined;
    }
  }, [expanded]);

  return (
    <Wrapper
      id={id}
      className={className}
      aria-hidden={expanded ? false : true}
      aria-labelledby={ariaLabelledby}
      inert={expanded ? null : ''}
      delayedOverflow={delayedOverflow ? 'visible' : 'hidden'}
    >
      {children}
    </Wrapper>
  );
};
