import { Value } from './types';
import { getValueLabel } from './utils';
import { today } from '@internationalized/date';
import { EMPTY_INPUT_TEXT } from './constants';

export type UseDateSelectorInput = {
  value: Value;
  onChange: (value: Value) => void;
  timezone: string;
  locale: string;
};

export const useDateSelector = ({
  onChange,
  value,
  timezone,
  locale,
}: UseDateSelectorInput) => {
  const minValue = today(timezone).add({ days: 1 });
  const selectDate = (date: Value) => {
    onChange(date);
  };

  return {
    label: getValueLabel(value, locale, timezone) || EMPTY_INPUT_TEXT,
    minValue,
    selectDate,
  };
};
