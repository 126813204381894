import React, { useRef, useEffect, useState, useCallback } from 'react';
import { SearchResultsTypes } from '@supersonic/store';
import styled, { css } from 'styled-components';
import { device } from '../../../css/sizes';
import { Image } from '../../image';
import { Button } from '../../button';

export interface ModalCarouselProps {
  imageList: SearchResultsTypes.ImageList[];
}

const Wrapper = styled.div`
  padding: 0;
  background: ${(props) => props.theme.colors.sand};
  @media ${device.md} {
    padding: ${(props) => props.theme.spacing._4}
      ${(props) => props.theme.spacing._3} ${(props) => props.theme.spacing._3};
  }
`;

const CarouselScrollWrapper = styled.div.attrs({
  className: 'no-scrollbar',
})`
  width: 100%;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overflow-x: auto;
  display: flex;
  @media ${device.md} {
    border-radius: ${(props) => props.theme.spacing._1};
  }
  @media ${device.md} and (max-height: 820px) {
  }
`;

const ImageWrapper = styled.div`
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  min-width: 100%;
  height: 240px;
  max-height: 480px;
  @media ${device.md} {
    height: auto;
  }
  @media ${device.md} and (max-height: 820px) {
    height: 340px;
  }
  > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const ThumbnailScrollerContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing._1};
  position: relative;
  width: 100%;
`;

const GradientStyles = css`
  background: linear-gradient(
    90.93deg,
    #f9f7f6 2.39%,
    rgba(249, 247, 246, 0) 99.02%
  );
  position: absolute;
  top: 0;
  height: 100%;
  pointer-events: none;
  width: 50px;
  transition: opacity 0.15s;
`;

const GradientLeft = styled.span<{
  visible: boolean;
}>`
  ${GradientStyles}
  left: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const GradientRight = styled.span<{
  visible: boolean;
}>`
  ${GradientStyles}
  opacity: ${(props) => (props.visible ? 1 : 0)};
  right: 0;
  transform: rotate(180deg);
`;

const ThumbnailsScroller = styled.div.attrs({
  className: 'no-scrollbar',
})`
  width: 100%;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overflow-x: auto;
  display: flex;
  position: relative;
  @media ${device.md} {
    border-radius: ${(props) => props.theme.spacing._1};
  }
`;

const ThumbnailImageButton = styled.button<{
  active: boolean;
}>`
  border-radius: ${(props) => props.theme.spacing._1};
  overflow: hidden;
  border: 0;
  padding: 0;
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 80px;
  height: 70px;
  position: relative;
  @media ${device.md} {
    width: 126px;
    height: 96px;
  }
  > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  &:not(:first-child) {
    margin-left: ${(props) => props.theme.spacing._1};
  }
  &:after {
    content: '';
    border-radius: ${(props) => props.theme.spacing._1};
    height: 100%;
    width: 100%;
    ${(props) =>
      props.active &&
      css`
        border: ${(props) => props.theme.spacing._05} solid
          ${(props) =>
            props.theme.colors[props.theme.gallery.thumbnailHoverColor]};
      `}
    position: absolute;
    top: 0;
    left: 0;
  }
  &:hover,
  &:focus-visible {
    outline: none;
    &:after {
      border: ${(props) => props.theme.spacing._05} solid
        ${(props) =>
          props.theme.colors[props.theme.gallery.thumbnailHoverColor]};
    }
  }
`;

const CarouselControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing._2};
  padding-bottom: ${(props) => props.theme.spacing._2};
  @media ${device.md} {
    margin-top: ${(props) => props.theme.spacing._4};
    padding: 0;
  }
`;

const SlidesPosition = styled.span.attrs({
  className: 'font-foxtrot',
})`
  margin: 0 ${(props) => props.theme.spacing._3};
  width: ${(props) => props.theme.spacing._6};
  text-align: center;
`;

export const ModalCarousel = ({ imageList }: ModalCarouselProps) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const thumbnailScrollerRef = useRef<HTMLDivElement>(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [thumbnailWrapperWidth, setThumbnailWrapperWidth] = useState(0);
  const [thumbnailWidth, setThumbnailWidth] = useState(0);
  const [thumbnailsInView, setThumbnailsInView] = useState(0);
  const [gradientLeftVisible, setGradientLeftVisible] = useState(false);
  const [gradientRightVisible, setGradientRightVisible] = useState(false);

  const totalSlides = imageList.length;

  const calcWidths = () => {
    if (scrollerRef.current) {
      setWrapperWidth(scrollerRef.current.offsetWidth);
    }
    if (thumbnailScrollerRef.current) {
      setThumbnailWrapperWidth(thumbnailScrollerRef.current.offsetWidth);
      const firstThumb = thumbnailScrollerRef.current
        .children[0] as HTMLButtonElement;
      setThumbnailWidth(firstThumb.offsetWidth);
    }
  };

  const handleScroll = () => {
    if (scrollerRef.current) {
      setCurrentSlide(
        Math.round(scrollerRef.current.scrollLeft / wrapperWidth)
      );
    }
  };

  const handleThumbnailScroll = () => {
    if (thumbnailScrollerRef.current) {
      if (thumbnailScrollerRef.current.scrollLeft > thumbnailWidth) {
        setGradientLeftVisible(true);
      }
      if (thumbnailScrollerRef.current.scrollLeft === 0) {
        setGradientLeftVisible(false);
      }
      if (
        thumbnailScrollerRef.current.scrollLeft + wrapperWidth ===
        thumbnailScrollerRef.current.scrollWidth
      ) {
        setGradientRightVisible(false);
      } else {
        setGradientRightVisible(true);
      }
    }
  };

  useEffect(() => {
    if (thumbnailScrollerRef.current) {
      const thumbnail = thumbnailScrollerRef.current.children[
        currentSlide
      ] as HTMLButtonElement;
      const offset = thumbnail.offsetLeft;
      const currentScroll = thumbnailScrollerRef.current.scrollLeft;
      if (offset > currentScroll + wrapperWidth || offset < currentScroll) {
        thumbnailScrollerRef.current.scrollLeft =
          offset - wrapperWidth / 2 + thumbnailWidth;
      }
    }
    if (scrollerRef.current) {
      const nextImageWrapper = scrollerRef.current.children[currentSlide + 1];
      if (nextImageWrapper) {
        const nextImage = nextImageWrapper.children[0] as HTMLImageElement;
        nextImage.setAttribute('loading', '');
      }
    }
  }, [currentSlide, thumbnailWidth, wrapperWidth]);

  useEffect(() => {
    setThumbnailsInView(Math.floor(thumbnailWrapperWidth / thumbnailWidth));
  }, [thumbnailWrapperWidth, thumbnailWidth]);

  useEffect(() => {
    calcWidths();
    if (imageList && thumbnailsInView && imageList.length > thumbnailsInView) {
      setGradientRightVisible(true);
    } else {
      setGradientRightVisible(false);
    }
    window.addEventListener('resize', calcWidths);
  }, [imageList, thumbnailsInView]);

  const goToSlide = useCallback(
    (target: number) => {
      if (scrollerRef.current) {
        if (target >= currentSlide - 2 && target <= currentSlide + 2) {
          const mediaQuery = window.matchMedia(
            '(prefers-reduced-motion: reduce)'
          );
          if (mediaQuery.matches) {
            scrollerRef.current.style.scrollBehavior = 'auto';
          } else {
            scrollerRef.current.style.scrollBehavior = 'smooth';
          }
        } else {
          scrollerRef.current.style.scrollBehavior = 'auto';
        }
        scrollerRef.current.scrollLeft = wrapperWidth * target;
      }
    },
    [currentSlide, wrapperWidth]
  );

  useEffect(() => {
    const handleKeyDown = ({ code }: KeyboardEvent) => {
      if (code === 'ArrowRight') {
        goToSlide(currentSlide + 1);
      }
      if (code === 'ArrowLeft') {
        goToSlide(currentSlide - 1);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentSlide, goToSlide]);

  return (
    <Wrapper>
      <CarouselScrollWrapper
        data-testid="carousel-scroller"
        ref={scrollerRef}
        onScroll={handleScroll}
        tabIndex={0}
      >
        {imageList &&
          imageList.map((image, index) => {
            return (
              <ImageWrapper key={index}>
                <Image
                  src={image.sources[0].url}
                  alt=""
                  width={500}
                  height={400}
                  sizes={{
                    sm: 500,
                    md: 500,
                    lg: 500,
                  }}
                />
              </ImageWrapper>
            );
          })}
      </CarouselScrollWrapper>

      <ThumbnailScrollerContainer>
        <ThumbnailsScroller
          data-testid="thumbnail-scroller"
          ref={thumbnailScrollerRef}
          onScroll={handleThumbnailScroll}
        >
          {imageList &&
            imageList.map((image, index) => {
              return (
                <ThumbnailImageButton
                  aria-label={`Go to photo ${index}`}
                  key={index}
                  active={index === currentSlide}
                  onClick={() => goToSlide(index)}
                >
                  <Image
                    src={image.sources[0].url}
                    alt=""
                    width={126}
                    height={96}
                    sizes={{
                      sm: 100,
                      md: 100,
                      lg: 100,
                    }}
                  />
                </ThumbnailImageButton>
              );
            })}
        </ThumbnailsScroller>
        <GradientLeft visible={gradientLeftVisible} />
        <GradientRight visible={gradientRightVisible} />
      </ThumbnailScrollerContainer>

      <CarouselControls>
        <Button
          onClick={() => goToSlide(currentSlide - 1)}
          disabled={currentSlide === 0}
          icon="ChevronRight"
          iconRotated={true}
          iconOnly={true}
          ariaLabel="Previous photo"
          size="small"
        />
        <SlidesPosition data-testid="slide-counter">
          {' '}
          {(currentSlide + 1).toString()}/{totalSlides}
        </SlidesPosition>
        <Button
          onClick={() => goToSlide(currentSlide + 1)}
          disabled={currentSlide === totalSlides - 1}
          icon="ChevronRight"
          iconOnly={true}
          ariaLabel="Next photo"
          size="small"
        />
      </CarouselControls>
    </Wrapper>
  );
};
