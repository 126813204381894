import React, { useEffect, useRef, ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';
import { InputField } from '../inputField';
import { LineItem } from '../lineItem';
import { Elevation } from '../elevation';
import { Tooltip } from '../tooltip';
import { disableBodyScroll } from 'body-scroll-lock-upgrade';
import * as Icon from '../icon';

type SelectOption = {
  value: number | string;
  label: string;
};

export type Props = {
  text?: string;
  fieldLabel?: string;
  leftIcon?: keyof typeof Icon;
  options: Array<SelectOption>;
  selectedOption: null | number | string;
  width?: string;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  onSelect: (value: number | string) => void;
  openOnTop?: boolean;
  disableBodyScrollWhenOpen: boolean;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing.unit}px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Text = styled.label.attrs({ className: 'font-golf' })<{
  isOpen: boolean;
}>`
  cursor: pointer;
  pointer-events: ${(props) => (props.isOpen ? 'none' : 'auto')};
`;
const SelectWrapper = styled.div<{
  width?: string;
}>`
  width: ${(props) =>
    props.width ? props.width : `${props.theme.spacing.unit * 17}px`};
  position: relative;
`;
const OptionsWrapperElevation = styled(Elevation)<{
  isOpen: boolean;
  openOnTop: boolean;
}>`
  ${(props) =>
    props.openOnTop
      ? css`
          bottom: 0;
        `
      : css`
          margin-top: ${(props) => props.theme.spacing._1};
        `};
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  border-radius: ${(props) => props.theme.spacing.unit}px;
  position: absolute;
  width: 100%;
  max-height: ${(props) => props.theme.spacing.unit * 22}px;
  z-index: 2;
  background: ${(props) => props.theme.colors.white};
  overflow-y: auto;
`;

export const Select = (props: Props) => {
  const menuRef = useRef<HTMLElement>(null);
  const selectedOption = props.options.find(
    (option) => option.value === props.selectedOption
  );
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: props.options,
    selectedItem: selectedOption,
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return;
      props.onSelect(selectedItem.value);
    },
  });
  useEffect(() => {
    if (props.disableBodyScrollWhenOpen && isOpen && menuRef.current) {
      disableBodyScroll(menuRef.current);
    }
  }, [isOpen, props.disableBodyScrollWhenOpen]);

  return (
    <Wrapper>
      {props.text && (
        <Text {...getLabelProps()} isOpen={isOpen}>
          {props.text}
        </Text>
      )}
      <SelectWrapper width={props.width}>
        <InputField
          type="button"
          size="small"
          label={selectedOption ? selectedOption.label : ''}
          buttonProps={getToggleButtonProps()}
          leftIcon={props.leftIcon}
          rightIcon="Chevron"
          fieldLabel={props.fieldLabel}
          tooltipProps={props.tooltipProps}
        />
        <OptionsWrapperElevation
          {...getMenuProps({ ref: menuRef })}
          level={2}
          isOpen={isOpen}
          openOnTop={props.openOnTop}
        >
          {isOpen &&
            props.options.map((option, index) => (
              <LineItem
                key={`${option.value}${index}`}
                wrapperProps={getItemProps({ item: option, index })}
                text={option.label}
                active={highlightedIndex === index}
                textUnderlineOnActive={false}
                invertHighlight={true}
              />
            ))}
        </OptionsWrapperElevation>
      </SelectWrapper>
    </Wrapper>
  );
};
