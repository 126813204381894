import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { device } from '../../css/sizes';
import { XLg } from '../icon';

export enum TipPositions {
  Top,
  Bottom,
}

export enum ArrowPositions {
  Left,
  Center,
  Right,
}

const TipWrapper = styled.div<{
  iconMode?: boolean;
}>`
  cursor: help;
  display: inline-block;
  ${(props) => (props.iconMode ? 'position: relative;' : null)}

  > div {
    display: none;
    ${(props) =>
      props.iconMode
        ? `transform: translateX(-${props.theme.spacing._2});`
        : null}
  }

  @media ${device.isNotTouch} {
    &:hover > div,
    &:focus-visible > div {
      display: block;
    }
  }
`;

const TipShape = styled.div.attrs({
  className: 'font-hotel',
})<{
  tipPosition?: TipPositions;
  tipWidth?: number;
  tipLeftPos: number;
  topPos: number;
}>`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1000;
  ${(props) => (props.tipWidth ? `width: ${props.tipWidth}px;` : null)}
  left: ${(props) => props.tipLeftPos}px;
  ${(props) =>
    props.tipPosition === TipPositions.Bottom
      ? `margin-top: ${props.theme.spacing.unit * 1.5}px;`
      : `transform: translateY(calc(-100% + -${
          props.topPos
        }px)); margin-top: -${props.theme.spacing.unit * 1.5}px;`}
  color: ${(props) =>
    props.theme.colors[props.theme?.tooltipConfig?.textColor]};
  border-radius: ${(props) => props.theme.spacing._1};
  background-color: ${(props) =>
    props.theme.colors[props.theme?.tooltipConfig?.backgroundColor]};
  border-radius: ${(props) => props.theme.spacing._1};
  padding: ${(props) => props.theme.spacing._1}
    ${(props) => props.theme.spacing._2};

  & svg {
    margin: 0 -${(props) => props.theme.spacing._05} 0 ${(props) => props.theme.spacing._1};
    @media ${device.isNotTouch} {
      display: none;
    }
  }
`;

const TipArrow = styled.div<{
  tipPosition?: TipPositions;
  topPos: number;
}>`
  display: block;
  background-color: ${(props) =>
    props.theme.colors[props.theme?.tooltipConfig?.backgroundColor]};
  width: ${(props) => props.theme.spacing._2};
  height: ${(props) => props.theme.spacing._2};
  position: absolute;
  z-index: 999;
  transform: rotate(45deg);
  ${(props) =>
    props.tipPosition === TipPositions.Bottom
      ? `margin-top: ${props.theme.spacing._05}`
      : `margin-top: -${props.topPos + 20}px;`};
`;

const TextContent = styled.p`
  margin: 0;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
`;

export interface TooltipProps {
  tipPosition: TipPositions;
  arrowPosition: ArrowPositions;
  width?: number;
  text: React.ReactNode;
  scrollingParent?: React.RefObject<HTMLDivElement>;
  className?: string;
  iconMode?: boolean;
  children?: React.ReactNode;
}

const defaultProps = {
  tipPosition: TipPositions.Bottom,
  arrowPosition: ArrowPositions.Center,
  width: 0,
};

export const Tooltip = ({
  tipPosition,
  arrowPosition,
  width = defaultProps.width,
  text,
  scrollingParent,
  className,
  iconMode,
  children,
}: TooltipProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const [tipLeftPos, setTipLeftPos] = useState(0);
  const [topPos, setTopPos] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const clearAllToolTips = () => {
    const tooltips = document.querySelectorAll<HTMLElement>('[role="tooltip"]');
    tooltips.forEach((tooltip) => {
      tooltip.style.display = '';
    });
  };

  const toggleTooltip = () => {
    if (tipRef.current && wrapperRef.current) {
      if (tipRef.current.style.display === 'block') {
        clearAllToolTips();
        wrapperRef.current.style.cursor = '';
      } else {
        clearAllToolTips();
        tipRef.current.style.display = 'block';
        wrapperRef.current.style.cursor = 'pointer';
      }
    }
  };

  const setArrowLeftPos = (pos: number) => {
    if (arrowRef.current) {
      arrowRef.current.style.left = `${pos}px`;
    }
  };

  useEffect(() => {
    const minSideMargin = 10;
    const arrowWidth = 16;
    let windowWidth, wrapperWidth, wrapperLeft;
    const scrollingParentRef = scrollingParent?.current;
    let calculatedLeftPos = 0;
    let calculatedRightPos = 0;
    let calculatedArrowLeftPos = 0;

    const isTouchDevice =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if (scrollingParentRef && isTouchDevice) {
      // if the tooltip is inside a scrolling parent, we need to hide it when the parent scrolls
      const hideTooltipOnScroll = () => {
        setScrollLeft(scrollingParentRef.scrollLeft);
        if (tipRef.current) {
          tipRef.current.style.display = 'none';
        }
      };
      scrollingParentRef.addEventListener('scroll', hideTooltipOnScroll);
    }
    if (tipRef.current && arrowRef.current) {
      const setPos = () => {
        windowWidth = window.innerWidth;
        wrapperWidth = wrapperRef.current?.clientWidth;
        wrapperLeft = wrapperRef.current?.offsetLeft;
        if (scrollingParentRef && wrapperLeft && wrapperWidth) {
          // if in a scrolling parent, calculate the left and right positions based on the scroll position
          setScrollLeft(scrollingParentRef.scrollLeft);
          calculatedLeftPos =
            wrapperLeft - scrollLeft + (wrapperWidth - width) / 2;
          calculatedRightPos = calculatedLeftPos + width;
          setTipLeftPos(calculatedLeftPos);
        } else {
          setArrowLeftPos(arrowWidth);
          switch (arrowPosition) {
            case ArrowPositions.Left:
              setTipLeftPos(0);
              break;
            case ArrowPositions.Right:
              setTipLeftPos(-width + arrowWidth * 3);
              break;
            default:
              setTipLeftPos(-width / 2 + arrowWidth * 1.5);
          }
        }
        if (scrollingParentRef) {
          scrollingParentRef.addEventListener('scroll', setPos);
          // set the arrow position to the centre of the tooltip based on the calulated left position
          calculatedArrowLeftPos =
            calculatedLeftPos + width / 2 - arrowWidth / 2;
          // ensure the arrow is not overflowing off the left side of the browser window
          if (calculatedArrowLeftPos < minSideMargin) {
            calculatedArrowLeftPos = minSideMargin * 2;
          }
          // ensure the arrow is not overflowing off the right side of the browser window
          if (
            calculatedArrowLeftPos >
            windowWidth - minSideMargin - arrowWidth
          ) {
            calculatedArrowLeftPos =
              windowWidth - minSideMargin * 2 - arrowWidth;
          }
          setArrowLeftPos(calculatedArrowLeftPos);
          // if the tooltip is overflowing off the left side of the browser window, move it to the right
          if (calculatedLeftPos < minSideMargin) {
            setTipLeftPos(minSideMargin);
          }
          // if the tooltip is overflowing off the right side of the browser window, move it to the left
          if (calculatedRightPos > windowWidth - minSideMargin) {
            setTipLeftPos(windowWidth - minSideMargin - width);
          }
        }
      };
      setPos();
      window.addEventListener('resize', setPos);
    }
    // if the tooltip is positioned above the element, calculate the top position based on the height of the tooltip wrapper
    if (wrapperRef.current && tipPosition === TipPositions.Top) {
      const calculatedTopPos = wrapperRef.current.clientHeight;
      setTopPos(calculatedTopPos);
    }
  }, [arrowPosition, scrollLeft, scrollingParent, text, tipPosition, width]);

  return (
    <TipWrapper
      ref={wrapperRef}
      onClick={toggleTooltip}
      tabIndex={0}
      className={className}
      iconMode={iconMode}
    >
      {children}
      <div ref={tipRef} role="tooltip">
        <TipShape
          tipPosition={tipPosition}
          tipWidth={width}
          tipLeftPos={tipLeftPos}
          topPos={topPos}
        >
          <TextContent>{text}</TextContent>
          <XLg ariaLabel="Close Tooltip" height={12} width={12} />
        </TipShape>
        <TipArrow ref={arrowRef} tipPosition={tipPosition} topPos={topPos} />
      </div>
    </TipWrapper>
  );
};

Tooltip.defaultProps = defaultProps;
