import { createContext, useContext } from 'react';
import { Store, Slices } from '@supersonic/store';
import { useStore as useZustandStore } from 'zustand';

export const StoreContext = createContext<Store | null>(null);

export const useStore = <T>(
  selector: (state: Slices) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(StoreContext);
  if (!store) throw new Error('Missing StoreContext.Provider in the tree');
  return useZustandStore(store, selector, equalityFn);
};
