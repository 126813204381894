import React from 'react';
import styled, { ThemeColor } from 'styled-components';
import { device } from '../../../css/sizes';
import { SubnavLink } from '../subnavLink';
import {
  ConfigNavigationSubsection,
  ConfigNavigationSubnavTheme,
} from '@supersonic/config';
import { tracking } from '../utils';

const SubNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  @media ${device.lg} {
    background: ${(props) => props.theme.colors.white};
    padding: ${(props) => props.theme.spacing._5} 0 0
      ${(props) => props.theme.spacing._5};
  }
`;
const LinksList = styled.ul.attrs({
  className: 'font-foxtrot',
})`
  display: grid;
  list-style: none;
  margin-left: -${(props) => props.theme.spacing._1};
  padding: ${(props) => props.theme.spacing._2} 0 0;
  @media ${device.lg} {
    padding-right: ${(props) => props.theme.spacing._5};
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SectionHeading = styled.span.attrs({
  className: 'font-echo',
})<{
  headingColor?: ThemeColor;
}>`
  color: ${(props) =>
    props.headingColor
      ? props.theme.colors[props.headingColor]
      : props.theme.colors.black};
  display: none;
  @media ${device.lg} {
    display: block;
  }
`;

interface SubNavProps {
  name: string;
  subnav: ConfigNavigationSubsection[];
  theme?: ConfigNavigationSubnavTheme;
}

export const SubNav2Level = ({ name, subnav, theme }: SubNavProps) => {
  return (
    <SubNavContainer>
      <SectionHeading headingColor={theme?.headingColor}>
        {subnav[0].title}
      </SectionHeading>

      <LinksList>
        {subnav[0].links &&
          subnav[0].links.map((link, idx) => {
            return (
              <li
                key={idx}
                onClick={() => tracking(`${name}`, `${link.text}`, 'select')}
              >
                <SubnavLink href={link.link} text={link.text} />
              </li>
            );
          })}
      </LinksList>
    </SubNavContainer>
  );
};
