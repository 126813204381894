import React, {
  useRef,
  useEffect,
  useState,
  KeyboardEventHandler,
} from 'react';
import { SearchResultsTypes } from '@supersonic/store';
import styled, { css } from 'styled-components';
import { device } from '../../../css/sizes';
import { Image } from '../../image';
import { ChevronRight } from '../../icon';

export interface InCardCarouselProps {
  imageList: SearchResultsTypes.ImageList[];
}

const Wrapper = styled.div`
  width: 100%;
  height: 200px;
  @media ${device.md} {
    height: 220px;
  }
  overflow: hidden;
  position: relative;
`;

const CarouselScrollWrapper = styled.div.attrs({
  className: 'no-scrollbar',
})`
  width: 100%;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overflow-x: auto;
  display: flex;
  &:focus-visible {
    &:after {
      content: '';
      border: 4px solid ${(props) => props.theme.colors.grey7};
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
`;

const ImageWrapper = styled.div`
  scroll-snap-align: start;
  width: 100%;
  height: 200px;
  min-width: 100%;
  @media ${device.md} {
    height: 220px;
  }
  > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const carouselButtonStyles = css`
  background: rgba(255, 255, 255, 0.9);
  border: 0;
  position: absolute;
  padding: ${(props) => props.theme.spacing._1};
  border-radius: ${(props) => props.theme.spacing._2};
  z-index: 1;
`;

const carouselMouseEnterButtonStyles = ({
  mouseOver,
}: {
  mouseOver: boolean | undefined;
}) => css`
  @media ${device.lg} {
    opacity: ${mouseOver ? '1' : '0!important'};
    transition: opacity 0.15s;
    &:focus {
      opacity: 1;
    }
  }
`;

const SlidesPosition = styled.span.attrs({
  className: 'font-hotel',
})<{
  mouseOver: boolean;
}>`
  ${({ mouseOver }) => carouselMouseEnterButtonStyles({ mouseOver })}

  background: rgba(255, 255, 255, 0.9);
  bottom: ${(props) => props.theme.spacing._1};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: ${(props) => props.theme.spacing._05}
    ${(props) => props.theme.spacing._1};
  border-radius: ${(props) => props.theme.spacing._05};
`;

const DirectionButton = styled.button<{
  prevBtn?: boolean;
  mouseOver: boolean;
}>`
  ${carouselButtonStyles}
  ${({ mouseOver }) => carouselMouseEnterButtonStyles({ mouseOver })}
  top: 50%;
  transform: translateY(-50%);
  &:focus-visible {
    outline: 4px solid ${(props) => props.theme.colors.grey7};
  }
  &[disabled] {
    cursor: not-allowed;
  }
  ${(props) =>
    props.prevBtn &&
    css`
      left: ${(props) => props.theme.spacing._1};
      > svg {
        transform: rotate(180deg);
      }
    `}
  ${(props) =>
    !props.prevBtn &&
    css`
      right: ${(props) => props.theme.spacing._1};
    `}
`;

export const InCardCarousel = ({ imageList }: InCardCarouselProps) => {
  const scrollerRef = useRef<HTMLDivElement>(null);

  const [mouseOver, setMouseOver] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const totalSlides = imageList.length;

  const calcWidths = () => {
    if (scrollerRef.current) {
      setWrapperWidth(scrollerRef.current.offsetWidth);
    }
  };

  const handleScroll = () => {
    if (scrollerRef.current) {
      setCurrentSlide(
        Math.round(scrollerRef.current.scrollLeft / wrapperWidth)
      );
    }
  };

  useEffect(() => {
    if (scrollerRef.current) {
      const nextImageWrapper = scrollerRef.current.children[currentSlide + 1];
      if (nextImageWrapper) {
        const nextImage = nextImageWrapper.children[0] as HTMLImageElement;
        nextImage.setAttribute('loading', '');
      }
    }
  }, [currentSlide]);

  useEffect(() => {
    calcWidths();
    window.addEventListener('resize', calcWidths);
  }, []);

  const goToSlide = (target: number) => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollLeft = wrapperWidth * target;
    }
  };

  const handleMouseEnter = () => {
    setMouseOver(true);
  };
  const handleMouseLeave = () => {
    setMouseOver(false);
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'ArrowRight') {
      goToSlide(currentSlide + 1);
    }
    if (e.code === 'ArrowLeft') {
      goToSlide(currentSlide - 1);
    }
  };

  return (
    <Wrapper
      data-testid="carousel-wrapper"
      onFocus={() => handleMouseEnter()}
      onBlur={() => handleMouseLeave()}
      onMouseOver={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      onKeyDown={handleKeyDown}
    >
      <CarouselScrollWrapper
        ref={scrollerRef}
        onScroll={handleScroll}
        tabIndex={0}
      >
        {imageList &&
          imageList.map((image, index) => {
            return (
              <ImageWrapper key={index}>
                <Image
                  src={image.sources[0].url}
                  alt=""
                  width={300}
                  height={200}
                  sizes={{
                    sm: 500,
                    md: 500,
                    lg: 150,
                  }}
                />
              </ImageWrapper>
            );
          })}
      </CarouselScrollWrapper>

      <DirectionButton
        prevBtn={true}
        mouseOver={mouseOver}
        onClick={() => goToSlide(currentSlide - 1)}
        disabled={currentSlide === 0}
        aria-label="Previous photo"
      >
        <ChevronRight height={16} width={16} />
      </DirectionButton>
      <DirectionButton
        mouseOver={mouseOver}
        onClick={() => goToSlide(currentSlide + 1)}
        disabled={currentSlide === totalSlides - 1}
        aria-label="Next photo"
      >
        <ChevronRight height={16} width={16} />
      </DirectionButton>

      <SlidesPosition mouseOver={mouseOver} data-testid="slide-counter">
        {' '}
        {(currentSlide + 1).toString()}/{totalSlides}
      </SlidesPosition>
    </Wrapper>
  );
};
