/*
TODO:
fix type error
*/

import React, { useState, useEffect } from 'react';
import { SearchResultsTypes } from '@supersonic/store';
import styled, { ThemeColor, useTheme } from 'styled-components';
import { Accordion } from '../accordion';
import { Button } from '../button';
import { Checkbox } from '../checkbox';
import { InfoCircle } from '../icon';
import { Radio } from '../radio';
import { Select } from '../select';
import { Tooltip, TipPositions, ArrowPositions } from '../tooltip';
import { BudgetHistogram } from '../budgetHistogram';
import { SkeletonBlock } from '../skeleton';

const FilterGroupWrapper = styled.div<{ borderColor?: ThemeColor }>`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.spacing._1};
  padding: ${(props) => props.theme.spacing._2};
  margin-bottom: ${(props) => props.theme.spacing._2};
  border: 1px solid
    ${(props) =>
      props.borderColor
        ? props.theme.colors[props.borderColor]
        : props.theme.colors.black};

  label > div:last-of-type {
    white-space: nowrap;
  }
`;

const FilterGroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterGroupHeading = styled.h3.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})`
  margin: 0;
`;

const StyledTooltip = styled(Tooltip)`
  flex: 1;
  margin-left: ${(props) => props.theme.spacing._1};
`;

const FilterGroupButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledAccordion = styled(Accordion)`
  > label {
    display: flex;
    justify-content: space-between;
    margin-top: ${(props) => props.theme.spacing._2};
    margin-right: ${(props) => props.theme.spacing._1};
  }

  > :first-of-type {
    margin-top: ${(props) => props.theme.spacing._3};
  }

  /* Prevent checkbox outline being clipped  */
  margin: 0 -${(props) => props.theme.spacing._2};
  padding: 0 ${(props) => props.theme.spacing._2};
`;

const TextButton = styled(Button)<{ visible: boolean }>`
  opacity: ${(props) => (props.visible ? '1' : '0')};
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
  transition: opacity 0.25s ease-in;
`;

const RadiosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface FilterGroupProps {
  loaded: boolean;
  displayName: string;
  presentation?: string;
  tooltipText?: string;
  borderColor?: ThemeColor;
  deselectAllFiltersInAllGroups?: boolean;
  values?: Array<SearchResultsTypes.FilterDataValue>;
  pricingBuckets?: number[];
  highestPrice?: number;
  lowestPrice?: number;
}

export const FilterGroup = ({
  loaded,
  displayName,
  presentation,
  tooltipText,
  borderColor,
  values,
  deselectAllFiltersInAllGroups,
  pricingBuckets,
  highestPrice,
  lowestPrice,
}: FilterGroupProps) => {
  const theme = useTheme();
  const [filterAccordionExpanded, setFilterAccordionExpanded] = useState(true);
  const toggleFilterAccordionExpanded = () => {
    setFilterAccordionExpanded(!filterAccordionExpanded);
  };

  const filterGroupId = `filtergroup-${displayName
    .toLowerCase()
    .replaceAll(' ', '-')}`;
  const accordionControlButtonId = `button-filtergroup-${displayName
    .toLowerCase()
    .replaceAll(' ', '-')}`;

  const [selectAllText, setSelectAllText] = useState(
    deselectAllFiltersInAllGroups ? 'Deselect all' : 'Select all'
  );

  const [selectValue, setSelectValue] = useState('');
  const updateSelectValue = (selectValue: string) => {
    setSelectValue(selectValue);
  };

  const [chosenFilterData, setChosenFilterData] = useState(values || []);
  const [selectedPriceLimitation, setSelectedPriceLimitation] = useState<
    [number | null, number | null]
  >([null, null]);

  const toolTipProps = {
    width: 260,
    text: tooltipText,
    tipPosition: TipPositions.Bottom,
    arrowPosition: ArrowPositions.Center,
    iconMode: true,
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat(theme.currency.locale, {
      style: 'currency',
      currency: theme.currency.code,
      minimumFractionDigits: 0,
    }).format(price);
  };

  const toggleSelectAllFilters = () => {
    const allFiltersSelected = chosenFilterData.every(
      (filter) => filter.selected
    );
    const updatedFilterData = chosenFilterData.map((filter) => ({
      ...filter,
      selected: !allFiltersSelected,
    }));
    setChosenFilterData(updatedFilterData);
    setSelectAllText(allFiltersSelected ? 'Select all' : 'Deselect all');
  };

  useEffect(() => {
    setSelectedPriceLimitation([null, null]);
    if (values) {
      const updatedFilterData = values.map((filter) => ({
        ...filter,
        selected: deselectAllFiltersInAllGroups ? false : filter.selected,
      }));
      setChosenFilterData(updatedFilterData);
      setSelectAllText('Select all');
    }
  }, [deselectAllFiltersInAllGroups, values]);

  const renderSelect = (displayName: string) => {
    const placeholder = `Select ${displayName.toLowerCase()}…`;
    const generateOptions = (
      items: SearchResultsTypes.FilterDataValue[]
    ): { label: string; value: string }[] => {
      const options = items.map(({ name = '', value = '' }) => ({
        label: name,
        value,
      }));
      options.unshift({ label: placeholder, value: placeholder });
      return options;
    };

    return (
      <Select
        options={generateOptions(values || [])}
        selectedOption={selectValue || placeholder}
        onSelect={(selectValue) => {
          updateSelectValue(String(selectValue));
        }}
        disableBodyScrollWhenOpen={false}
        width="100%"
      />
    );
  };

  const renderCheckboxes = () => {
    return (
      chosenFilterData &&
      chosenFilterData.map((filter, index) => {
        if (presentation === 'stars' && Number(filter.value) === 0) {
          return null;
        }
        const checkboxProps =
          presentation === 'stars'
            ? {
                starRating: Number(filter.value),
                key: filter.value,
                ariaLabel: `${filter.value} stars`,
              }
            : {
                text: filter.name,
                key: filter.name,
              };
        return (
          <Checkbox
            {...checkboxProps}
            checked={filter.selected ? true : false}
            additionalText={
              filter.price ? `from ${formatPrice(filter.price.total)}` : ''
            }
            onChange={() => {
              const updatedFilterData = [...chosenFilterData];
              updatedFilterData[index].selected = !filter.selected;
              setChosenFilterData(updatedFilterData);

              // if all filters are selected, change the text to 'Deselect all'
              const allFiltersSelected = updatedFilterData.every(
                (filter) => filter.selected
              );
              setSelectAllText(
                allFiltersSelected ? 'Deselect all' : 'Select all'
              );
            }}
          />
        );
      })
    );
  };

  const renderRadios = () => {
    const isAnyFilterSelected = chosenFilterData.some(
      (filter) => filter.selected
    );
    return (
      <RadiosWrapper>
        {chosenFilterData &&
          chosenFilterData.map((filter, index) => {
            let checked = false;
            if (index === 0) {
              checked = (!isAnyFilterSelected || filter.selected) as boolean;
            } else {
              checked = (filter.selected || false) as boolean;
            }
            return (
              <Radio
                key={filter.name}
                appearance="integratedLabel"
                text={filter.name}
                value={filter.value ? filter.value : '0'}
                name={filterGroupId}
                checked={checked}
                onChange={() => {
                  let updatedFilterData;
                  if (index === 0) {
                    // click first item - toggle selected
                    updatedFilterData = chosenFilterData.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, selected: !item.selected };
                      } else {
                        return { ...item, selected: false };
                      }
                    });
                  } else {
                    // click other item - select it and deselect others
                    updatedFilterData = chosenFilterData.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, selected: true };
                      } else {
                        return { ...item, selected: false };
                      }
                    });
                  }
                  setChosenFilterData(updatedFilterData);
                }}
              />
            );
          })}
      </RadiosWrapper>
    );
  };

  const renderBudgetHistogram = () => {
    return (
      <BudgetHistogram
        priceBuckets={pricingBuckets || []}
        selectedPriceLimitation={selectedPriceLimitation}
        priceLimitation={[lowestPrice || 0, highestPrice || 0]}
        onSelectedPriceLimitationChange={setSelectedPriceLimitation}
        delayWhenTypingForOnSelectedPriceLimitationChange={0}
      />
    );
  };

  return (
    <FilterGroupWrapper borderColor={borderColor} data-testid="filter-group">
      <FilterGroupHeader>
        <FilterGroupHeading>{displayName}</FilterGroupHeading>
        {tooltipText && (
          <StyledTooltip {...toolTipProps}>
            <InfoCircle
              width={16}
              height={16}
              color={theme.radio.primaryColor}
            />
          </StyledTooltip>
        )}
        <FilterGroupButtons>
          {presentation === 'price-histogram' && (
            <TextButton
              type="aux"
              text="Reset"
              onClick={() => {
                setSelectedPriceLimitation([null, null]);
              }}
              visible={
                filterAccordionExpanded &&
                (selectedPriceLimitation[0] !== null ||
                  selectedPriceLimitation[1] !== null)
              }
              tabIndex={
                filterAccordionExpanded &&
                (selectedPriceLimitation[0] !== null ||
                  selectedPriceLimitation[1] !== null)
                  ? 0
                  : -1
              }
            />
          )}
          {presentation !== 'select' && presentation !== 'price-histogram' && (
            <TextButton
              type="aux"
              text={selectAllText}
              onClick={toggleSelectAllFilters}
              visible={loaded && filterAccordionExpanded}
              tabIndex={filterAccordionExpanded ? 0 : -1}
            />
          )}
          <Button
            type="aux"
            icon={filterAccordionExpanded ? 'Chevron' : 'Chevron'}
            ariaLabel={`${
              filterAccordionExpanded ? 'Hide' : 'Show'
            } ${displayName} filters`}
            iconOnly={true}
            iconRotated={filterAccordionExpanded}
            onClick={toggleFilterAccordionExpanded}
            ariaExpanded={filterAccordionExpanded}
            ariaControls={filterGroupId}
            id={accordionControlButtonId}
          />
        </FilterGroupButtons>
      </FilterGroupHeader>
      <StyledAccordion
        expanded={filterAccordionExpanded}
        id={filterGroupId}
        ariaLabelledby={accordionControlButtonId}
      >
        {(() => {
          if (!loaded) {
            return (
              <>
                <SkeletonBlock bottomMargin />
                <SkeletonBlock bottomMargin />
                <SkeletonBlock bottomMargin />
                <SkeletonBlock bottomMargin />
              </>
            );
          }
          switch (presentation) {
            case 'price-histogram':
              return renderBudgetHistogram();
            case 'select':
              return renderSelect(displayName);
            case 'radio':
              return renderRadios();
            case 'stars':
            default:
              return renderCheckboxes();
          }
        })()}
      </StyledAccordion>
    </FilterGroupWrapper>
  );
};
