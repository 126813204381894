import React from 'react';
import styled from 'styled-components';
import { useFocusRing } from 'react-aria';
import * as Icon from '../icon';
import { StarRating } from '../starRating';

export type Props = {
  checked: boolean;
  onChange: () => void;
  text?: string;
  additionalText?: string;
  ariaLabel?: string;
  disabled?: boolean;
  starRating?: number;
};
type StylingProps = {
  $checked: boolean;
  $focused: boolean;
  $disabled: boolean;
};

const Wrapper = styled.label<StylingProps>`
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
`;
const InputAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Box = styled.div<StylingProps>`
  width: ${(props) => props.theme.spacing.unit * 2.5}px;
  height: ${(props) => props.theme.spacing.unit * 2.5}px;
  border: ${(props) =>
    (props.$disabled && `1px solid ${props.theme.colors.grey3}`) ||
    ((props.$checked || props.$focused) &&
      `1px solid ${props.theme.colors[props.theme.checkbox.primaryColor]}`) ||
    `1px solid ${props.theme.colors.grey4}`};
  border-radius: ${(props) => props.theme.spacing.unit / 2}px;
  outline: ${(props) =>
    props.$focused
      ? `2px solid ${
          props.theme.colors[props.theme.checkbox.focusOutlineColor]
        }`
      : 'none'};
  background: ${(props) =>
    (props.$disabled && props.theme.colors.grey1) ||
    (props.$checked && props.theme.colors[props.theme.checkbox.primaryColor]) ||
    props.theme.colors.white};
  color: ${(props) =>
    props.$disabled ? props.theme.colors.grey3 : props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
const Text = styled.div.attrs({ className: 'font-foxtrot' })<StylingProps>`
  color: ${(props) =>
    props.$disabled ? props.theme.colors.grey3 : props.theme.colors.black};
  margin-left: ${(props) => props.theme.spacing.unit}px;
`;
const StyledStarRating = styled(StarRating)`
  margin-left: ${(props) => props.theme.spacing.unit}px;
`;
const AdditionalText = styled.div.attrs({
  className: 'font-golf',
})<StylingProps>`
  color: ${(props) =>
    props.$disabled ? props.theme.colors.grey2 : props.theme.colors.grey6};
  margin-left: ${(props) => props.theme.spacing.unit * 3.5}px;
`;

export const Checkbox = (props: Props) => {
  const { isFocusVisible, focusProps } = useFocusRing();

  const stylingProps: StylingProps = {
    $checked: props.checked,
    $focused: isFocusVisible,
    $disabled: props.disabled || false,
  };

  return (
    <Wrapper {...stylingProps}>
      <InputAndTextWrapper>
        <input
          {...focusProps}
          className="sr-only"
          aria-label={props.ariaLabel}
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <Box {...stylingProps} data-testid="checkbox-box">
          {props.checked && (
            <Icon.Check width={16} height={16} ariaLabel="Check icon" />
          )}
        </Box>
        {props.text && <Text {...stylingProps}>{props.text}</Text>}
        {props.starRating && <StyledStarRating rating={props.starRating} />}
      </InputAndTextWrapper>
      {props.additionalText && (
        <AdditionalText {...stylingProps}>
          {props.additionalText}
        </AdditionalText>
      )}
    </Wrapper>
  );
};
