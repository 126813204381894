import { DEFAULT_VALUE as AIRPORTS_SELECTOR_DEFAULT_VALUE } from '../airportsSelector/constants';
import { DEFAULT_VALUE as DESTINATION_SELECTOR_DEFAULT_VALUE } from '../destinationSelector/constants';
import { DEFAULT_VALUE as DATE_SELECTOR_DEFAULT_VALUE } from '../dateSelector/constants';
import { DEFAULT_VALUE as DURATION_SELECTOR_DEFAULT_VALUE } from '../durationSelector/constants';
import { DEFAULT_VALUE as PASSENGERS_SELECTOR_DEFAULT_VALUE } from '../passengersSelector/constants';

export const DEFAULT_INITIAL_VALUES = {
  airports: AIRPORTS_SELECTOR_DEFAULT_VALUE,
  destination: DESTINATION_SELECTOR_DEFAULT_VALUE,
  date: DATE_SELECTOR_DEFAULT_VALUE,
  duration: DURATION_SELECTOR_DEFAULT_VALUE,
  passengers: PASSENGERS_SELECTOR_DEFAULT_VALUE,
};
