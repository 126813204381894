import { Value } from './types';
import { getValueLabel } from './utils';
import { DEFAULT_VALUE, EMPTY_INPUT_TEXT } from './constants';

export type UseDestinationsSelector = {
  value: Value;
  onChange: (value: Value) => void;
};

export const useDestinationsSelector = ({
  onChange,
  value,
}: UseDestinationsSelector) => {
  const clear = () => {
    onChange(DEFAULT_VALUE);
  };

  const selectDestination = (destination: Value) => {
    onChange(destination);
  };

  return {
    label: getValueLabel(value) || EMPTY_INPUT_TEXT,
    clear,
    allowClear: value ? true : false,
    selectDestination,
  };
};
