import React from 'react';
import { LineItem } from '../../lineItem';

interface SubnavLinkProps {
  text: string;
  href: string;
}

export const SubnavLink = ({ text, href }: SubnavLinkProps) => {
  return <LineItem text={text} wrapperProps={{ as: 'a', href }} />;
};
