import { Value, AirportsGroup, AirportCode } from './types';
import { AIRPORTS, MAX_NUMBER_OF_AIRPORTS } from './constants';

export const isAirportSelected = (
  airportCode: AirportCode,
  value: Value
): boolean => {
  return value.includes(airportCode);
};

export const isAirportsGroupSelected = (
  airportsGroup: AirportsGroup,
  value: Value
): boolean => {
  return airportsGroup.airportCodes.every((airportCode) =>
    isAirportSelected(airportCode, value)
  );
};

export const canSelectAirport = (value: Value): boolean => {
  return value.length < MAX_NUMBER_OF_AIRPORTS;
};

export const canSelectAirportsGroup = (
  group: AirportsGroup,
  value: Value
): boolean => {
  const numberOfNotSelectedGroupAirports = group.airportCodes.reduce(
    (acc, airportCode) => {
      return isAirportSelected(airportCode, value) ? acc : acc + 1;
    },
    0
  );
  return (
    value.length + numberOfNotSelectedGroupAirports <= MAX_NUMBER_OF_AIRPORTS
  );
};

export const getValueLabel = (value: Value): string => {
  if (value.length === 0) return '';
  return value.reduce((result, airportCode) => {
    const airport = AIRPORTS.find((airport) => airport.code === airportCode);
    if (!airport) return result;
    return result === '' ? airport.name : `${result}, ${airport.name}`;
  }, '');
};

export const validate = (value: Value): string | null => {
  if (!value.length) return 'Please select a departure airport';
  return null;
};
