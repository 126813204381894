import { GA4, GA4Event } from '../../scripts/ga4';

const ga4 = new GA4();

export const tracking = (
  type: 'Logo' | 'menu icon' | 'close icon' | string,
  content_display: string | undefined,
  action:
    | 'click'
    | 'auto-open'
    | 'auto-close'
    | 'select'
    | 'expand'
    | 'collapse'
) => {
  const eventConfig: GA4Event = {
    event: 'top_nav',
    product: undefined,
    module: type,
    action: action,
    content_display: content_display,
  };
  ga4.send(eventConfig);
};
