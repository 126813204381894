import React from 'react';
import { BaseSvg, type BaseSvgProps } from '../../../baseSvg';

export const Check = (baseSvgProps: Omit<BaseSvgProps, 'children'>) => {
  return (
    <BaseSvg {...baseSvgProps}>
      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    </BaseSvg>
  );
};
