import React from 'react';
import styled, { css } from 'styled-components';

const getRatingData = (rating: number) => {
  let data: {
    title: string;
    ratingStyles: ReturnType<typeof css>;
  };
  if (rating >= 9) {
    data = {
      title: 'Excellent',
      ratingStyles: css`
        background-color: ${(props) =>
          props.theme.colors[props.theme.trustYouReviews.excellentColor]};
      `,
    };
  } else if (rating >= 8) {
    data = {
      title: 'Very Good',
      ratingStyles: css`
        background-color: ${(props) =>
          props.theme.colors[props.theme.trustYouReviews.excellentColor]};
      `,
    };
  } else if (rating >= 7) {
    data = {
      title: 'Good',
      ratingStyles: css`
        background-color: ${(props) =>
          props.theme.colors[props.theme.trustYouReviews.goodColor]};
      `,
    };
  } else if (rating >= 6) {
    data = {
      title: 'Fair',
      ratingStyles: css`
        background-color: ${(props) =>
          props.theme.colors[props.theme.trustYouReviews.goodColor]};
      `,
    };
  } else {
    data = {
      title: 'Poor',
      ratingStyles: css`
        background-color: ${(props) =>
          props.theme.colors[props.theme.trustYouReviews.poorColor]};
      `,
    };
  }
  return data;
};

const Wrapper = styled.div`
  display: flex;
`;

const ReviewWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing._1};
`;

const RatingShape = styled.div<RatingShapeProps>`
  ${(props) => props.ratingStyles}
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: ${(props) => props.theme.spacing._4};
  height: ${(props) => props.theme.spacing._4};
`;

const RatingTitle = styled.span.attrs({
  className: 'font-golf font-golf--bold',
})`
  color: ${(props) => props.theme.colors.white};
  display: block;
`;

const RatingContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ReviewTitle = styled.span.attrs({
  className: 'font-golf font-golf--bold',
})<{ isVisible: boolean }>`
  color: ${(props) =>
    props.isVisible
      ? props.theme.colors[props.theme.trustYouReviews.ratingTitleColor]
      : props.theme.colors.black};
  margin: ${(props) => props.theme.spacing._0};
`;

const ReviewDescription = styled.span.attrs({
  className: 'font-hotel',
})<{ isVisible: boolean }>`
  color: ${(props) =>
    props.theme.colors[props.theme.trustYouReviews.ratingDescriptionColor]};
  margin: ${(props) => props.theme.spacing._0};
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
`;

export interface RatingShapeProps {
  rating: number;
  ratingStyles?: ReturnType<typeof css>;
  isVisible?: boolean;
  reviews: number;
}

export const TrustYouReviews = ({ rating, reviews }: RatingShapeProps) => {
  const { title, ratingStyles } = getRatingData(rating);
  const visibility = reviews > 0;
  const formattedReviews = reviews.toLocaleString();
  return (
    <Wrapper>
      <ReviewWrapper>
        <RatingShape
          rating={rating}
          ratingStyles={ratingStyles}
          isVisible={visibility}
          reviews={reviews}
        >
          <RatingTitle>{rating}</RatingTitle>
        </RatingShape>
        <RatingContentWrapper>
          <ReviewTitle isVisible={visibility}>
            {visibility ? title : 'No guest reviews yet'}
          </ReviewTitle>
          <ReviewDescription isVisible={visibility}>
            {formattedReviews} guest reviews
          </ReviewDescription>
        </RatingContentWrapper>
      </ReviewWrapper>
    </Wrapper>
  );
};
