import React, { useEffect } from 'react';

declare global {
  interface Window {
    zaraz: any;
    location: Location;
  }
}

function scrapeZarazCookieConsentValues() {
  const desiredKeys = ['data-purpose-id', 'id'];

  const cfModalContainer = document.querySelector('.cf_modal_container');

  if (!cfModalContainer) return;

  const shadowRoot = cfModalContainer.shadowRoot;

  if (!shadowRoot) return;

  const consentInputs = Array.from(shadowRoot.querySelectorAll('input'));

  const zarazConsentMapping: { [index: string]: any } = consentInputs.reduce(
    (accum, input) => {
      const inputAttributes = Array.from(input.attributes).reduce(
        (accum: { [index: string]: any }, attribute) => {
          const key = attribute.nodeName;
          const val = attribute.nodeValue;
          if (desiredKeys.indexOf(key) >= 0) {
            accum[key] = val;
          }
          return accum;
        },
        {}
      );

      const dataPurposeId: string = inputAttributes['data-purpose-id'];
      const checkboxNumber: string = inputAttributes['id'];
      const consentName: string = (
        shadowRoot.querySelector(`[for="${checkboxNumber}"] h3`) as HTMLElement
      ).innerText;

      accum[dataPurposeId] = consentName;
      return accum;
    },
    {} as Record<string, string>
  );

  function setCookie(name: string, value: string) {
    document.cookie = name + '=' + value + ';' + 'SameSite=Strict';
  }

  setCookie('zaraz-consent-values', JSON.stringify(zarazConsentMapping));
}

function hideModalIfTestSource() {
  const cfModalContainer = document.querySelector('.cf_modal_container');

  const currentUrl = new URL(window.location.href);
  if (
    window.zaraz &&
    cfModalContainer &&
    currentUrl.searchParams.get('source') === 'tiv'
  ) {
    window.zaraz.setConsentChoices({ test: true });
    window.zaraz.hideConsentModal();
  }
}

export const ShowCookieConsentModal = () => {
  useEffect(() => {
    hideModalIfTestSource();
    scrapeZarazCookieConsentValues();
  });

  const showModal = () => {
    if (window.zaraz) {
      window.zaraz.showConsentModal();
    }
  };

  return <button onClick={showModal}>Show Cookie Consent</button>;
};
