export const breakpointsNum = {
  xs: 0,
  sm: 360,
  md: 656,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
};

export const breakpoints = {
  xs: `${breakpointsNum.xs}px`,
  sm: `${breakpointsNum.sm}px`,
  md: `${breakpointsNum.md}px`,
  lg: `${breakpointsNum.lg}px`,
  xl: `${breakpointsNum.xl}px`,
  xxl: `${breakpointsNum.xxl}px`,
};

export const device = {
  xs: `screen and (min-width: ${breakpoints.xs})`,
  sm: `screen and (min-width: ${breakpoints.sm})`,
  md: `screen and (min-width: ${breakpoints.md})`,
  lg: `screen and (min-width: ${breakpoints.lg})`,
  xl: `screen and (min-width: ${breakpoints.xl})`,
  xxl: `screen and (min-width: ${breakpoints.xxl})`,

  smOnly: `screen and (max-width: ${breakpoints.md})`,
  mdOnly: `screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg})`,

  isTouch: '(pointer: coarse), (hover: none)',
  isNotTouch: '(pointer: fine), (hover: hover)',
};
