import { Airport, Value, AirportsGroup } from './types';

export const DEFAULT_VALUE: Value = [];
export const EMPTY_INPUT_TEXT = 'Choose your airports';

export const MAX_NUMBER_OF_AIRPORTS = 8;

export const AIRPORT_GROUPS: AirportsGroup[] = [
  {
    name: 'London - All',
    airportCodes: ['LCY', 'LGW', 'LHR', 'LTN', 'SEN', 'STN'],
  },
  {
    name: 'Midlands',
    airportCodes: ['BHX', 'EMA'],
  },
  {
    name: 'North East',
    airportCodes: ['HUY', 'LBA', 'NCL'],
  },
  {
    name: 'North West',
    airportCodes: ['LPL', 'MAN'],
  },
  {
    name: 'Northern Ireland',
    airportCodes: ['BFS', 'BHD'],
  },
  {
    name: 'Scotland',
    airportCodes: ['ABZ', 'EDI', 'GLA', 'INV', 'PIK'],
  },
  {
    name: 'South East',
    airportCodes: ['BOH', 'SOU'],
  },
  {
    name: 'South West + Wales',
    airportCodes: ['BRS', 'EXT', 'CWL'],
  },
];

export const AIRPORTS: Airport[] = [
  { name: 'Aberdeen', code: 'ABZ' },
  { name: 'Belfast City', code: 'BHD' },
  { name: 'Belfast Intl', code: 'BFS' },
  { name: 'Birmingham', code: 'BHX' },
  { name: 'Bournemouth', code: 'BOH' },
  { name: 'Bristol', code: 'BRS' },
  { name: 'Cardiff', code: 'CWL' },
  { name: 'East Midlands', code: 'EMA' },
  { name: 'Edinburgh', code: 'EDI' },
  { name: 'Exeter', code: 'EXT' },
  { name: 'Gatwick', code: 'LGW' },
  { name: 'Glasgow', code: 'GLA' },
  { name: 'Heathrow', code: 'LHR' },
  { name: 'Humberside', code: 'HUY' },
  { name: 'Inverness', code: 'INV' },
  { name: 'Leeds/Bradford', code: 'LBA' },
  { name: 'Liverpool', code: 'LPL' },
  { name: 'London City', code: 'LCY' },
  { name: 'Luton', code: 'LTN' },
  { name: 'Manchester', code: 'MAN' },
  { name: 'Newcastle', code: 'NCL' },
  { name: 'Norwich', code: 'NWI' },
  { name: 'Prestwick', code: 'PIK' },
  { name: 'Southampton', code: 'SOU' },
  { name: 'Southend', code: 'SEN' },
  { name: 'Stansted', code: 'STN' },
];
