import React from 'react';
import styled from 'styled-components';
import { useFocusRing } from 'react-aria';

export type Props = {
  checked: boolean;
  name: string;
  value: string;
  onChange: () => void;
  text?: string;
  additionalText?: string;
  ariaLabel?: string;
  disabled?: boolean;
  appearance?: 'integratedLabel';
};
type StylingProps = {
  $checked: boolean;
  $focused: boolean;
  $disabled: boolean;
};

const Wrapper = styled.label<StylingProps>`
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
`;
const InputAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Circle = styled.div<StylingProps>`
  width: ${(props) => props.theme.spacing.unit * 2.5}px;
  height: ${(props) => props.theme.spacing.unit * 2.5}px;
  border: ${(props) =>
    (props.$disabled &&
      props.$checked &&
      `6px solid ${props.theme.colors.grey3}`) ||
    (props.$disabled && `1px solid ${props.theme.colors.grey3}`) ||
    (props.$checked &&
      `6px solid ${props.theme.colors[props.theme.radio.primaryColor]}`) ||
    (props.$focused &&
      `1px solid ${props.theme.colors[props.theme.radio.primaryColor]}`) ||
    `1px solid ${props.theme.colors.grey4}`};
  border-radius: 50%;
  outline: ${(props) =>
    props.$focused
      ? `2px solid ${props.theme.colors[props.theme.radio.focusOutlineColor]}`
      : 'none'};
  background: ${(props) =>
    (props.$disabled && props.theme.colors.grey1) || props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IntegratedLabelCircle = styled.div<StylingProps>`
  width: ${(props) => props.theme.spacing.unit * 5}px;
  height: ${(props) => props.theme.spacing.unit * 5}px;
  border: ${(props) =>
    (props.$disabled &&
      props.$checked &&
      `1px solid ${props.theme.colors.grey3}`) ||
    (props.$disabled && `1px solid ${props.theme.colors.grey3}`) ||
    (props.$checked &&
      `1px solid ${props.theme.colors[props.theme.radio.primaryColor]}`) ||
    (props.$focused &&
      `1px solid ${props.theme.colors[props.theme.radio.primaryColor]}`) ||
    `1px solid ${props.theme.colors.grey4}`};
  border-radius: 50%;
  outline: ${(props) =>
    props.$focused
      ? `2px solid ${props.theme.colors[props.theme.radio.focusOutlineColor]}`
      : 'none'};
  background: ${(props) =>
    (props.$disabled && props.theme.colors.grey1) ||
    (props.$checked && props.theme.colors[props.theme.radio.primaryColor]) ||
    props.theme.colors.white};
  color: ${(props) => (props.$checked && props.theme.colors.white) || null};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Text = styled.div.attrs({ className: 'font-foxtrot' })<StylingProps>`
  color: ${(props) =>
    props.$disabled ? props.theme.colors.grey3 : props.theme.colors.black};
  margin-left: ${(props) => props.theme.spacing.unit}px;
`;
const AdditionalText = styled.div.attrs({
  className: 'font-golf',
})<StylingProps>`
  color: ${(props) =>
    props.$disabled ? props.theme.colors.grey2 : props.theme.colors.grey6};
  margin-left: ${(props) => props.theme.spacing.unit * 3.5}px;
`;

export const Radio = (props: Props) => {
  const { isFocusVisible, focusProps } = useFocusRing();

  const stylingProps: StylingProps = {
    $checked: props.checked,
    $focused: isFocusVisible,
    $disabled: props.disabled || false,
  };

  return (
    <Wrapper {...stylingProps}>
      <InputAndTextWrapper>
        <input
          {...focusProps}
          className="sr-only"
          aria-label={props.ariaLabel}
          type="radio"
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        {props.appearance === 'integratedLabel' ? (
          <IntegratedLabelCircle {...stylingProps} data-testid="radio-circle">
            {props.text}
          </IntegratedLabelCircle>
        ) : (
          <>
            <Circle {...stylingProps} data-testid="radio-circle" />
            {props.text && <Text {...stylingProps}>{props.text}</Text>}
          </>
        )}
      </InputAndTextWrapper>
      {props.additionalText && (
        <AdditionalText {...stylingProps}>
          {props.additionalText}
        </AdditionalText>
      )}
    </Wrapper>
  );
};
