import React from 'react';
import styled from 'styled-components';
import { device } from '../../css/sizes';
import { Container } from '../container';
import { Button } from '../button';
import { Image } from '../image';
import { blogPosts, blogHeadContent, numberOfPostsProps } from './types';
import { tracking } from './utils';

const BlogHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlogHeaderTitle = styled.h3.attrs({
  className: 'font-charlie',
})`
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media ${device.md} {
    align-items: center;
  }
`;

const CardContainer = styled.div.attrs({
  className: 'no-scrollbar',
})<numberOfPostsProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3 ? 'row' : 'column'};
  align-self: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3
      ? 'initial'
      : 'center'};
  padding-bottom: ${(props) => props.theme.spacing._3};
  overflow-x: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3 ? 'auto' : 'none'};
  gap: ${(props) =>
    props.numberOfPosts === 4
      ? 'props.theme.spacing._2'
      : 'props.theme.spacing._0'};
  @media ${device.md} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    overflow-x: auto;
    gap: ${(props) => props.theme.spacing._05};
  }
`;

const Card = styled.a<numberOfPostsProps>`
  display: flex;
  flex: 1;
  flex-direction: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3 ? 'column' : 'row'};
  gap: ${(props) => props.theme.spacing._2};
  padding: ${(props) => props.theme.spacing._2};
  margin: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3
      ? props.theme.spacing._1
      : null};
  margin-bottom: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3
      ? null
      : props.theme.spacing._1};
  min-width: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3 ? '75%' : null};
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: ${(props) => props.theme.spacing._1};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  &:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
  }
  &:focus {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border: 2px solid
      ${(props) => props.theme.colors[props.theme.blog.focusColor]};
    outline: 0;
  }
  @media ${device.md} {
    flex-direction: column;
    margin: ${(props) => props.theme.spacing._1};
    min-width: ${(props) =>
      props.numberOfPosts === 4 || props.numberOfPosts === 3 ? '270px' : null};
  }
  @media ${device.lg} {
    min-width: 135px;
  }
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

const ImageWrapper = styled.div<numberOfPostsProps>`
  img {
    width: ${(props) =>
      props.numberOfPosts === 4 || props.numberOfPosts === 3
        ? '100%'
        : '136px'};
    max-height: 232px;
    object-fit: cover;
  }
  @media ${device.md} {
    img {
      height: ${(props) =>
        props.numberOfPosts === 4 || props.numberOfPosts === 3
          ? 'auto'
          : '270px'};
      width: 100%;
    }
  }
  @media ${device.lg} {
    img {
      height: ${(props) =>
        props.numberOfPosts === 4 || props.numberOfPosts === 3
          ? 'auto'
          : '370px'};
    }
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing._05};
  padding-top: 0;
`;

const TagTitle = styled.p.attrs({
  className: 'font-hotel font-hotel--bold',
})`
  color: ${(props) => props.theme.colors[props.theme.blog.tagColor]};
  margin: 0;
  line-height: 17px !important;
`;

const TagSubTitle = styled.p.attrs({
  className: 'font-hotel',
})`
  color: ${(props) => props.theme.colors[props.theme.blog.fadeColor]};
  margin: 0;
  line-height: 17px !important;
  &::before {
    content: '∙';
    margin-right: ${(props) => props.theme.spacing._05};
    font-weight: 600;
  }
`;

const PostTitle = styled.h3.attrs({
  className: 'font-echo',
})`
  color: ${(props) => props.theme.colors.black};
  margin: 0;
  padding-top: ${(props) => props.theme.spacing._1};
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing._1};
  margin-top: ${(props) => props.theme.spacing._2};
`;

const UserImage = styled.img<numberOfPostsProps>`
  width: ${(props) => props.theme.spacing._4};
  height: ${(props) => props.theme.spacing._4};
  object-fit: cover;
  display: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3 ? 'block' : 'none'};
`;

const UserInfo = styled.p.attrs({
  className: 'font-golf',
})<numberOfPostsProps>`
  color: ${(props) => props.theme.colors[props.theme.blog.fadeColor]};
  margin-bottom: ${(props) =>
    props.numberOfPosts === 4 || props.numberOfPosts === 3
      ? 'props.theme.spacing._05}'
      : '0}'};
`;

export interface BlogProps {
  posts: blogPosts[];
  blogHeadContent: blogHeadContent;
}
export const Blog = ({ posts, blogHeadContent }: BlogProps) => {
  return (
    <Container>
      <BlogHeaderContainer>
        <BlogHeaderTitle>{blogHeadContent}</BlogHeaderTitle>
      </BlogHeaderContainer>

      <FlexContainer>
        <CardContainer numberOfPosts={posts.length}>
          {posts.map((blogPosts) => (
            <Card
              key={blogPosts.title}
              href={blogPosts.url}
              data-testid="blog-post"
              numberOfPosts={posts.length}
            >
              <ImageWrapper
                numberOfPosts={posts.length}
                onClick={() => tracking('image', blogPosts.title)}
              >
                <Image
                  src={blogPosts.image}
                  alt={blogPosts.title}
                  width={posts.length === 2 ? 125 : 500}
                  height={posts.length === 2 ? 125 : 500}
                  sizes={{
                    sm: 375,
                    md: 824,
                    lg: 1440,
                  }}
                ></Image>
              </ImageWrapper>
              <CardContentContainer>
                <TagContainer>
                  <TagTitle>{blogPosts.tagTitle}</TagTitle>
                  <TagSubTitle>{blogPosts.tagSubTitle}</TagSubTitle>
                </TagContainer>
                <PostTitle onClick={() => tracking('title', blogPosts.title)}>
                  {blogPosts.title}
                </PostTitle>
                <UserContainer>
                  <UserImage
                    src={blogPosts.userImage}
                    alt={`Photo of ${blogPosts.userInfo}`}
                    numberOfPosts={posts.length}
                  ></UserImage>
                  <UserInfo numberOfPosts={posts.length}>
                    By {blogPosts.userInfo}
                  </UserInfo>
                </UserContainer>
              </CardContentContainer>
            </Card>
          ))}
        </CardContainer>
        <Button
          type={'secondary'}
          size={'large'}
          text={'Discover more on our blog'}
          onClick={() => tracking('discover more', 'discover more')}
        ></Button>
      </FlexContainer>
    </Container>
  );
};
