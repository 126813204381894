import React from 'react';
import styled from 'styled-components';
import { device } from '../../css/sizes';
const ContainerDiv = styled.div`
  max-width: 1280px; /* import these from the theme? */
  margin-right: 16px;
  margin-left: 16px;
  @media ${device.lg} {
    margin-right: 80px;
    margin-left: 80px;
  }
  @media ${device.xxl} {
    margin-right: auto;
    margin-left: auto;
  }
`;
interface ContainerProps {
  children: React.ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
  return <ContainerDiv>{children}</ContainerDiv>;
};
