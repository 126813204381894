import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../css/sizes';
import { Button } from '../button';
import { Elevation } from '../elevation';
import { createPages } from './utils';

const PaginationContainer = styled(Elevation)`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr;
  gap: ${(props) => props.theme.spacing._2};
  padding: ${(props) => props.theme.spacing._2};
  margin-bottom: ${(props) => props.theme.spacing._3};
  border-radius: ${(props) => props.theme.spacing._1};
  background-color: ${(props) => props.theme.colors.white};
  @media ${device.md} {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
  }
`;

const FirstButton = styled(Button)`
  grid-row: 2 / -1;
  @media ${device.md} {
    grid-row: unset;
  }
`;

const PreviousButton = styled(Button)`
  order: -1;
`;

const NumberingContainer = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto auto auto;
  // flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing._1};
  @media ${device.md} {
    grid-column: unset;
    display: flex;
  }
  @media ${device.smOnly} {
    > button:not(:first-of-type):not(:last-of-type) {
      display: none;
    }
  }
`;

const MobilePaginationStatus = styled.span`
  margin: 0 ${(props) => props.theme.spacing._2};
  @media ${device.md} {
    display: none;
  }
`;

const Numbers = styled.span<{ isSelected: boolean }>`
  ${(props) =>
    props.isSelected
      ? css`
          color: ${props.theme.colors.white};
        `
      : css`
          color: ${props.theme.colors.black};
        `}
`;

export type PaginationValue = number;

export interface Props {
  currentPage: number;
  onChange: (value: PaginationValue) => void;
  resultsPerPage: number;
  totalResults: number;
}

export const Pagination = ({
  currentPage,
  onChange,
  resultsPerPage,
  totalResults,
}: Props) => {
  const [currentPageNumber, setCurrentPageNumber] =
    useState<number>(currentPage);
  const [numberOfPages, setNumberOfPages] = useState<number>(
    Math.ceil(totalResults / resultsPerPage)
  );

  useEffect(() => {
    if (totalResults) {
      // TODO: remove console.log when no longer needed
      console.log('detect totalResults changed and update pagination');
      setNumberOfPages(Math.ceil(totalResults / resultsPerPage));
    }
  }, [totalResults, resultsPerPage]);

  useEffect(() => {
    if (currentPage) {
      setCurrentPageNumber(currentPage);
    }
  }, [currentPage]);

  const paginationNumbers = createPages(currentPage, 1, numberOfPages);

  const handleButtonClick = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    onChange(pageNumber);
  };

  return (
    <PaginationContainer level={4}>
      <FirstButton
        ariaLabel={'First button'}
        type={'secondary'}
        text={'First'}
        size={'small'}
        disabled={currentPageNumber === 1}
        onClick={() => handleButtonClick(1)}
      />
      <NumberingContainer>
        <MobilePaginationStatus>
          {currentPage}/{numberOfPages}
        </MobilePaginationStatus>

        <PreviousButton
          ariaLabel={'Previous page'}
          type={'tertiary'}
          disabled={currentPageNumber === 1}
          size={'small'}
          iconOnly={true}
          icon={'ChevronLeft'}
          onClick={() => handleButtonClick(currentPageNumber - 1)}
        />

        {paginationNumbers.map((e) => (
          <Button
            key={e.number}
            type={currentPageNumber === e.number ? 'primary' : 'tertiary'}
            size={'small'}
            iconOnly={true}
            ariaLabel={
              currentPageNumber === e.number
                ? `Current page, Page ${e.number}`
                : e.ariaLabel
                ? e.ariaLabel
                : undefined
            }
            buttonProps={{
              'aria-current':
                currentPageNumber === e.number ? 'true' : undefined,
            }}
            text={
              <Numbers
                isSelected={currentPageNumber === e.number}
              >{`${e.text}`}</Numbers>
            }
            onClick={() => handleButtonClick(e.number)}
          />
        ))}
        <Button
          ariaLabel={'Next page'}
          type={'tertiary'}
          disabled={currentPageNumber === numberOfPages}
          size={'small'}
          iconOnly={true}
          icon={'ChevronRight'}
          onClick={() => handleButtonClick(currentPageNumber + 1)}
        />
      </NumberingContainer>
      <Button
        ariaLabel={'Last button'}
        type={'secondary'}
        disabled={currentPageNumber === numberOfPages}
        text={'Last'}
        size={'small'}
        onClick={() => handleButtonClick(numberOfPages)}
      />
    </PaginationContainer>
  );
};
