import React from 'react';
import { ThemeColor, useTheme } from 'styled-components';
export interface BaseSvgProps {
  children: React.ReactNode;
  ariaLabel?: string;
  ariaHidden?: boolean;
  height?: number;
  width?: number;
  color?: ThemeColor;
  viewBox?: string;
}
const defaultProps = {
  ariaLabel: '',
  ariaHidden: true,
  height: 30,
  width: 30,
  viewBox: '0 0 16 16',
};

export const BaseSvg = ({
  children,
  ariaLabel,
  ariaHidden,
  height,
  width,
  color,
  viewBox,
}: BaseSvgProps) => {
  const theme = useTheme();
  const colorString = color ? theme.colors[color] : 'currentColor';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      width={width}
      height={height}
      fill={colorString}
      viewBox={viewBox}
    >
      {children}
    </svg>
  );
};

BaseSvg.defaultProps = defaultProps;
