import { GA4, GA4Event } from '../../scripts/ga4';

const ga4 = new GA4();

export const tracking = (
  type: 'Links' | 'Legal' | 'Social Media Links',
  content_display: string,
  action: 'click' | `share ${string}`
) => {
  const eventConfig: GA4Event = {
    event: 'footer',
    product: undefined,
    module: type,
    action: action,
    content_display: content_display,
  };
  ga4.send(eventConfig);
};
