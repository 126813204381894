import React from 'react';
import styled, { keyframes, ThemeColor } from 'styled-components';
import { device } from '../../css/sizes';
import { renderLogoIcon } from '../header';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media ${device.md} {
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: ${(props) => props.theme.spacing._7};
    padding-bottom: ${(props) => props.theme.spacing._5};
  }
`;

const fadeInAnimation = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing._2};
  width: 100px;
  padding: ${(props) => props.theme.spacing._3} 0;
  @media ${device.md} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Dot = styled.div<DotProps>`
  min-width: 19px;
  min-height: 19px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.theme.colors[props.theme.interstitial[props.color]]};
  animation: ${fadeInAnimation} 1s infinite linear alternate;
  animation-delay: ${(props) => props.delay}s;
  display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};

  @media ${device.md} {
    animation-duration: 2s;
    animation-delay: ${(props) => props.desktopDelay || props.delay}s;
    display: ${(props) => (props.hideOnDesktop ? 'none' : 'block')};
  }
`;

const BrandLogoWrapper = styled.div``;

const DescriptionMobile = styled.p.attrs({
  className: 'font-delta',
})`
  padding: ${(props) => props.theme.spacing._2} 0;
  text-align: center;
  @media ${device.md} {
    display: none;
  }
`;

const Description = styled.p.attrs({
  className: 'font-delta',
})`
  text-align: center;
  display: none;
  @media ${device.md} {
    display: block;
  }
`;

const Logo = styled.div<{
  logoColor?: ThemeColor;
}>`
  color: ${(props) =>
    props.logoColor
      ? props.theme.colors[props.logoColor]
      : props.theme.colors.black};
  max-width: 136px;
  @media ${device.lg} {
    max-width: none;
  }
`;

export type HeaderLogo = string;

type DotColorKeys = 'dot1Color' | 'dot2Color' | 'dot3Color' | 'dot4Color';

export interface InterstitialProps {
  logo: HeaderLogo;
  logoColor: ThemeColor;
  imageSrc: string;
}
export interface DotProps {
  color: DotColorKeys;
  delay: number;
  desktopDelay?: number;
  hideOnDesktop: boolean;
  hideOnMobile: boolean;
}

const descriptionTitle =
  'Great choice! We are connecting you to your amazing deal.';

export const Interstitial = ({
  logo,
  logoColor,
  imageSrc,
}: InterstitialProps) => {
  const dotProps: DotProps[] = [
    {
      color: 'dot1Color',
      delay: 0.5,
      hideOnMobile: false,
      hideOnDesktop: false,
    },
    { color: 'dot2Color', delay: 1, hideOnMobile: false, hideOnDesktop: false },
    {
      color: 'dot3Color',
      delay: 1.5,
      hideOnMobile: true,
      hideOnDesktop: false,
    },
    {
      color: 'dot4Color',
      delay: 1.5,
      desktopDelay: 2,
      hideOnMobile: false,
      hideOnDesktop: false,
    },
  ];

  return (
    <Wrapper>
      <img src={imageSrc}></img>
      <DescriptionMobile>{descriptionTitle}</DescriptionMobile>
      <Content>
        <Logo logoColor={logoColor} data-testid="interstitial-logo">
          {renderLogoIcon(logo)}{' '}
        </Logo>
        <LoadingWrapper>
          {dotProps.map((dot, index) => (
            <Dot key={index} {...dot} data-testid="dot" />
          ))}
        </LoadingWrapper>
        <BrandLogoWrapper>
          <img
            src="https://d2lqjbz18tdbni.cloudfront.net/120x54/1482.png"
            alt="Partner Logo"
            srcSet="https://d2lqjbz18tdbni.cloudfront.net/120x54/1482.png 1x, https://d2lqjbz18tdbni.cloudfront.net/240x108/1482.png 2x"
          />{' '}
        </BrandLogoWrapper>
      </Content>
      <Description>{descriptionTitle}</Description>
    </Wrapper>
  );
};
