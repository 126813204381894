import { StateCreator } from 'zustand';
import { Slices } from '../types';
import { Destination, DestinationsSlice, DestinationsConfig } from './types';
import debounce from 'lodash.debounce';

export const createDestinationsSlice =
  (
    config: DestinationsConfig
  ): StateCreator<Slices, [], [], DestinationsSlice> =>
  (set, get) => ({
    config,
    popularDestinations: [],
    suggestedDestinations: [],
    currentDestination: null,
    isLoadingSuggestedDestinations: false,
    loadPopularDestinations: async () => {
      const autocompleteApi = get().destinations.config.autocompleteApi;
      const response = await fetch(`${autocompleteApi}/suggest?limit=20`);
      const destinations: Destination[] = await response.json();
      set((state) => ({
        ...state,
        destinations: {
          ...state.destinations,
          popularDestinations: destinations,
        },
      }));
    },
    _fetchSuggestedDestinationDebounced: debounce(async (search: string) => {
      const autocompleteApi = get().destinations.config.autocompleteApi;
      const response = await fetch(
        `${autocompleteApi}/suggest?limit=5&query=${search}`
      );
      const destinations: Destination[] = await response.json();
      set((state) => ({
        ...state,
        destinations: {
          ...state.destinations,
          suggestedDestinations: destinations,
          isLoadingSuggestedDestinations: false,
        },
      }));
    }, 500),
    loadSuggestedDestinations: async (search: string) => {
      set((state) => ({
        ...state,
        destinations: {
          ...state.destinations,
          isLoadingSuggestedDestinations: true,
        },
      }));
      get().destinations._fetchSuggestedDestinationDebounced(search);
    },
    loadCurrentDestination: async (searchParams) => {
      const autocompleteApi = get().destinations.config.autocompleteApi;
      const queryParams = new URLSearchParams();
      const destinationCodes = searchParams.destinationRegion
        ? `${searchParams.destinationCountry}-${searchParams.destinationArea}-${searchParams.destinationRegion}`
        : `${searchParams.destinationCountry}-${searchParams.destinationArea}`;
      queryParams.append('sghCodes', destinationCodes);
      if (searchParams.destinationId) {
        queryParams.append('ttiPlaceKey', searchParams.destinationId);
      }
      try {
        const response = await fetch(
          `${autocompleteApi}/lookup?${queryParams.toString()}`
        );
        const destination: Destination = await response.json();
        set((state) => ({
          ...state,
          destinations: {
            ...state.destinations,
            currentDestination: destination,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    },
  });
