import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../css/sizes';

type Props = {
  advertiserUuid: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Text = styled.div.attrs({ className: 'font-hotel' })`
  color: ${(props) => props.theme.colors.grey6};
`;
const Logo = styled.img`
  width: 60px;
  height: 23px;
  margin-left: ${(props) => props.theme.spacing.unit}px;
  @media ${device.sm} {
    width: 90px;
    height: 35px;
  }
`;

export const AdvertiserLogo = ({ advertiserUuid }: Props) => {
  return (
    <Wrapper>
      <Text>Sponsored by</Text>
      <Logo
        src={`https://d2lqjbz18tdbni.cloudfront.net/90x35/${advertiserUuid}.png`}
        alt="Advertiser logo"
        srcSet={`https://d2lqjbz18tdbni.cloudfront.net/90x35/${advertiserUuid}.png 90w,
               https://d2lqjbz18tdbni.cloudfront.net/180x70/${advertiserUuid}.png 180w`}
      />
    </Wrapper>
  );
};
