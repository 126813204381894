import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { AdvertisingTypes } from '@supersonic/store';
import { throttle } from 'lodash';
import { breakpoints, device } from '../../../css/sizes';
import { useStore } from '../../../storeProvider';
import { generateCloudflareImageSrc } from '../../../utils/generateCloudflareImageSrc';
import { generateCloudflareImageSrcset } from '../../../utils/generateCloudflareImageSrcset';

const Wrapper = styled.div<{
  isVisible: boolean;
  borderTopColor: string;
  backgroundColor: string;
}>`
  background: ${(props) => props.backgroundColor};
  display: block;
  transform: ${(props) =>
    props.isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(0, 100%, 0)'};
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: ${(props) => props.borderTopColor};
  text-decoration: none;
  transition: transform 0.2s ease;
  padding: ${(props) => props.theme.spacing.unit * 2}px;
  @media ${device.lg} {
    padding: ${(props) => props.theme.spacing.unit * 2}px
      ${(props) => props.theme.spacing.unit * 10}px;
  }
`;
const InnerWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media ${device.md} {
    flex-wrap: nowrap;
  }
`;
const Texts = styled.div`
  text-align: center;
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: ${(props) => props.theme.spacing.unit * 2}px;
  @media ${device.md} {
    flex: 1;
    margin-bottom: 0;
    order: 1;
  }
`;
const CustomImage = styled.img`
  display: block;
  width: auto;
  height: 35px;
  flex: 0 0 auto;
  margin-right: ${(props) => props.theme.spacing.unit * 2}px;
  @media ${device.md} {
    width: auto;
    height: 60px;
    order: 0;
  }
`;
const HeroText = styled.div.attrs({ className: 'font-echo' })<{
  color: string;
}>`
  color: ${(props) => props.color};
`;
const HeroSubtitle = styled.div.attrs({ className: 'font-golf' })<{
  color: string;
}>`
  color: ${(props) => props.color};
`;
const CtaButton = styled.button.attrs({
  className: 'font-foxtrot font-foxtrot-bold',
})<{
  color: string;
  backgroundColor: string;
}>`
  flex: 0 0 auto;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: none;
  outline: none;
  padding: ${(props) => props.theme.spacing.unit * 1.5}px
    ${(props) => props.theme.spacing.unit * 3}px;
  border-radius: ${(props) => props.theme.spacing.unit}px;
  margin-left: auto;
  @media ${device.md} {
    margin-left: ${(props) => props.theme.spacing.unit * 2}px;
    order: 2;
  }
`;

type Props = {
  ad: AdvertisingTypes.RibbonAd;
};

export const RibbonAd = ({ ad }: Props) => {
  const theme = useTheme();
  const getClickLink = useStore((state) => state.advertising.getClickLink);
  const [isVisible, setVisible] = useState(false);
  const adRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleDocumentScroll = () => {
      const currentScrollY = window.scrollY;
      const pageHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        0;
      const viewportHeight = window.innerHeight;
      const shouldShowRibbonAd =
        currentScrollY + viewportHeight / 4 > pageHeight / 4;

      setVisible(shouldShowRibbonAd);
    };
    const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 500);

    document.addEventListener('scroll', handleDocumentScrollThrottled);
    return () => {
      document.removeEventListener('scroll', handleDocumentScrollThrottled);
    };
  }, []);
  useEffect(() => {
    if (adRef.current) {
      const adHeight = adRef.current?.getBoundingClientRect().height;
      document.body.style.paddingBottom = `${adHeight}px`;
    }
    return () => {
      document.body.style.paddingBottom = ``;
    };
  }, []);

  return (
    <Wrapper
      {...(ad.has_deeplink
        ? {
            as: 'a',
            href: getClickLink(ad),
            target: '_blank',
            rel: 'noopener noreferrer',
            'aria-label': "Go to advertiser's website",
          }
        : { as: 'div' })}
      // @ts-ignore element could be either A or DIV, but typescript doesn't understand it.
      ref={adRef}
      data-testid="ribbon"
      isVisible={isVisible}
      backgroundColor={ad.content.background_colour}
      borderTopColor={ad.content.border_colour}
    >
      <InnerWrapper>
        <Texts>
          <HeroText color={ad.content.hero_text_colour}>
            {ad.content.hero_text}
          </HeroText>
          <HeroSubtitle color={ad.content.hero_subtitle_colour}>
            {ad.content.hero_subtitle}
          </HeroSubtitle>
        </Texts>
        {ad.content.image_settings.show_custom_image && (
          <CustomImage
            src={generateCloudflareImageSrc(
              ad.content.image_settings.custom_image_path,
              theme.cloudflareImagesUrl,
              { height: 60 }
            )}
            alt="Advertiser image"
            sizes={`(min-width: ${breakpoints.md}) 150px, 90px`}
            srcSet={generateCloudflareImageSrcset(
              ad.content.image_settings.custom_image_path,
              theme.cloudflareImagesUrl,
              [
                [{ height: 35 }, 90],
                [{ height: 35, dpr: 2 }, 180],
                [{ height: 60 }, 150],
                [{ height: 60, dpr: 2 }, 300],
              ]
            )}
          />
        )}
        {ad.cta_enabled && (
          <CtaButton
            type="button"
            color={ad.content.cta_colour}
            backgroundColor={ad.content.cta_background_colour}
          >
            {ad.content.cta_text}
          </CtaButton>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};
