import { StateCreator } from 'zustand';
import { Slices } from '../types';
import {
  AdvertisingSlice,
  InlineAd,
  RibbonAd,
  AdvertisingConfig,
} from './types';

export const createAdvertisingSlice =
  (config: AdvertisingConfig): StateCreator<Slices, [], [], AdvertisingSlice> =>
  (set, get) => ({
    config,
    inlineAdSlot0: null,
    inlineAdSlot1: null,
    ribbonAdSlot0: null,
    loadConditionalUnits: async (device: 'mobile' | 'tablet' | 'desktop') => {
      const searchParams = get().searchResults.searchParams;
      const config = get().advertising.config;
      if (!searchParams) return;
      try {
        const destinationCode = [
          searchParams.destinationCountry,
          searchParams.destinationArea,
          searchParams.destinationRegion,
        ]
          .filter((code) => !!code)
          .join('-');
        const queryParams = new URLSearchParams({
          target: 'package_search',
          site: config.site,
          device: device,
          date: searchParams.departureDate.split('-').reverse().join('/'),
          departureAirports: searchParams.departureAirports,
          destination: destinationCode,
          ...(searchParams.destinationId
            ? { placeKey: searchParams.destinationId }
            : {}),
        });
        const response = await fetch(
          `${config.advertisingApi}/inline/units?${queryParams.toString()}`
        );
        const data: {
          inline?: { 0?: InlineAd; 1?: InlineAd };
          ribbon?: { 0?: RibbonAd };
        } = await response.json();
        set((state) => ({
          ...state,
          advertising: {
            ...state.advertising,
            inlineAdSlot0: data.inline?.['0'] || null,
            inlineAdSlot1: data.inline?.['1'] || null,
            ribbonAdSlot0: data.ribbon?.['0'] || null,
          },
        }));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    getClickLink: (ad) => {
      const searchParams = get().searchResults.searchParams;
      const config = get().advertising.config;
      if (!searchParams) return '';
      const queryParams = new URLSearchParams({
        adults: searchParams.adults,
        childAges: searchParams.childAges,
        country: searchParams.destinationCountry,
        area: searchParams.destinationArea,
        ...(searchParams.destinationRegion
          ? { region: searchParams.destinationRegion }
          : {}),
        departureAirports: searchParams.departureAirports,
        departureDate: searchParams.departureDate,
        duration: searchParams.duration,
        inlineUnitUuid: ad.uuid,
      });
      return `${
        config.brokerApi
      }/v1/packages/search/click?${queryParams.toString()}`;
    },
  });
