import React from 'react';
import styled from 'styled-components';
import { PriceBucketWithAvailability } from '../types';

type Props = {
  bucketsWithAvailability: PriceBucketWithAvailability[];
};

const Wrapper = styled.div`
  height: 75px;
  display: flex;
  align-items: flex-end;
`;
const Bar = styled.div<{ $disabled: boolean }>`
  flex: 1;
  background: ${(props) =>
    props.$disabled
      ? props.theme.colors.grey2
      : props.theme.colors[props.theme.budgetHistogram.secondaryColor]};
  margin-right: 2px;
  border-radius: ${(props) => props.theme.spacing.unit / 2}px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const Histogram = ({ bucketsWithAvailability }: Props) => {
  const maxNumberOfItemsInBuckets = Math.max(
    ...bucketsWithAvailability.map((bucket) => bucket.total)
  );

  return (
    <Wrapper data-testid="histogram">
      {bucketsWithAvailability.map((bucket, index) => (
        <Bar
          data-testid="histogram-bar"
          data-histogram-bar-value={bucket.total}
          key={`${index}-${bucket.total}`}
          $disabled={bucket.disabled}
          style={{
            height: `${(bucket.total / maxNumberOfItemsInBuckets) * 100}%`,
          }}
        />
      ))}
    </Wrapper>
  );
};

export default Histogram;
