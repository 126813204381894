import { Value } from './types';

export const decodeDuration = (value: Value): number => {
  return Number(value.split(':')[1]);
};

export const getValueLabel = (value: Value): string => {
  const duration = decodeDuration(value);
  return duration === 1 ? `${duration} night` : `${duration} nights`;
};
