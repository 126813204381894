import React from 'react';
import styled from 'styled-components';

const NoResultsTitle = styled.h1.attrs({
  className: 'font-delta',
})`
  padding-top: ${(props) => props.theme.spacing._3};
  text-align: center;
  text-wrap: balance;
`;

const NoResultsContent = styled.p.attrs({
  className: 'font-golf',
})`
  text-align: center;
  text-wrap: balance;
  margin-bottom: ${(props) => props.theme.spacing._4};
`;

export interface NoResultsProps {
  isYourSelectedProperty?: boolean;
}

export const NoResults = ({ isYourSelectedProperty }: NoResultsProps) => {
  const title = isYourSelectedProperty
    ? "Oh no! Unfortunately there aren't any holidays available for your selected hotel."
    : "Oh no! Unfortunately, we couldn't find a deal that matched your search criteria";
  const content = isYourSelectedProperty
    ? "You could try changing your departure airport and / or dates of travel, to see if availability changes. Alternatively we've found some similar hotels you may like below."
    : 'Try changing your departure airport and/or dates of travel, to see if availability changes or you can visit our deals page for all of the latest deals.';
  return (
    <>
      <NoResultsTitle>{title}</NoResultsTitle>
      <NoResultsContent>{content}</NoResultsContent>
    </>
  );
};
