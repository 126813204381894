import { BoardBasisLookup, UspCodes } from './types';

export const BOARD_BASIS_LOOKUP: BoardBasisLookup = {
  BB: {
    name: 'Bed & breakfast',
    desc: [
      {
        title: 'Meals',
        desc: 'Breakfast will be included in the price. All other meals will cost extra. ',
      },
      {
        title: 'Drinks',
        desc: 'During breakfast, basics like tea, coffee and fruit juice should be included in the price. All other drinks will cost extra.',
      },
    ],
  },
  SC: {
    name: 'Self Catering',
    desc: [
      {
        title: 'Meals',
        desc: 'Breakfast will be included in the price. All other meals will cost extra. ',
      },
      {
        title: 'Drinks',
        desc: 'During breakfast, basics like tea, coffee and fruit juice should be included in the price. All other drinks will cost extra.',
      },
    ],
  },
  AI: {
    name: 'All inclusive',
    desc: [
      {
        title: 'Meals',
        desc: 'Breakfast will be included in the price. All other meals will cost extra. ',
      },
      {
        title: 'Drinks',
        desc: 'During breakfast, basics like tea, coffee and fruit juice should be included in the price. All other drinks will cost extra.',
      },
    ],
  },
  RO: {
    name: 'Room only',
    desc: [
      {
        title: 'Meals',
        desc: 'Breakfast will be included in the price. All other meals will cost extra. ',
      },
      {
        title: 'Drinks',
        desc: 'During breakfast, basics like tea, coffee and fruit juice should be included in the price. All other drinks will cost extra.',
      },
    ],
  },
  HB: {
    name: 'Half board',
    desc: [
      {
        title: 'Meals',
        desc: 'Breakfast will be included in the price. All other meals will cost extra. ',
      },
      {
        title: 'Drinks',
        desc: 'During breakfast, basics like tea, coffee and fruit juice should be included in the price. All other drinks will cost extra.',
      },
    ],
  },
  FB: {
    name: 'Full board',
    desc: [
      {
        title: 'Meals',
        desc: 'Breakfast will be included in the price. All other meals will cost extra. ',
      },
      {
        title: 'Drinks',
        desc: 'During breakfast, basics like tea, coffee and fruit juice should be included in the price. All other drinks will cost extra.',
      },
    ],
  },
};

export const USP_CODES: UspCodes = {
  BI: {
    tooltip: 'A checked baggage allowance is included',
  },
  TI: {
    tooltip: 'Transfers are included',
  },
  LD: {
    tooltip: 'Low deposits are available',
  },
  KF: {
    tooltip: 'Kids go free',
  },
  RU: {
    tooltip: 'A room upgrade is available with this deal',
  },
  CF: {
    tooltip:
      'This holiday company offers some flexibility - see their website for details',
  },
  CT: {
    tooltip: 'Covid test is included',
  },
  CH: {
    tooltip: 'Car hire is included',
  },
  CR: {
    tooltip: 'A resort, room or spa credit is included',
  },
  DC: {
    tooltip:
      "A discount code is available - see the company's website for details",
  },
  AL: {
    tooltip:
      "Airport lounge access is included - see the company's website for details",
  },
  FT: {
    tooltip:
      "Airport security fast track is included - see the company's website for details",
  },
  default: {
    tooltip: '',
  },
};
