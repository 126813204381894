import { createStore as createZustandStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CreateStoreOptions, Slices } from './types';
import { createSearchResultsSlice } from './searchResults';
import { createDestinationsSlice } from './destinations';
import { createAdvertisingSlice } from './advertising';

export const createStore = ({
  searchResultsConfig,
  advertisingConfig,
  destinationConfig,
}: CreateStoreOptions) => {
  return createZustandStore<Slices>()(
    devtools((...params) => ({
      searchResults: createSearchResultsSlice(searchResultsConfig)(...params),
      destinations: createDestinationsSlice(destinationConfig)(...params),
      advertising: createAdvertisingSlice(advertisingConfig)(...params),
    }))
  );
};
