import { Value } from './types';
import { DestinationsTypes } from '@supersonic/store';

export const getDestinationName = (
  destination: DestinationsTypes.Destination
): string => {
  return destination.names ? destination.names.join(', ') : ''; // TODO when the destinations all use the same ID and exposed results can pass the destination correctly, we can remove the check
};

export const getValueLabel = (value: Value): string => {
  if (!value) return '';
  return getDestinationName(value);
};

export const validate = (value: Value): string | null => {
  if (!value) return 'Please select destination';
  return null;
};
