import React from 'react';
import Histogram from './histogram';
import PriceRange from './priceRange';
import PriceRangeInputs from './priceRangeInputs';
import useBudgetHistogram from './useBudgetHistogram';
import type { SelectedPriceLimitationChangeSource } from './types';

export type Props = {
  priceBuckets: number[];
  priceLimitation: [number, number];
  selectedPriceLimitation: [number | null, number | null];
  onSelectedPriceLimitationChange: (
    newPriceLimitation: [number | null, number | null],
    source: SelectedPriceLimitationChangeSource[]
  ) => void;
  delayWhenTypingForOnSelectedPriceLimitationChange: number;
};

export const BudgetHistogram = ({
  priceBuckets,
  priceLimitation,
  selectedPriceLimitation,
  onSelectedPriceLimitationChange,
  delayWhenTypingForOnSelectedPriceLimitationChange,
}: Props) => {
  const {
    priceRange,
    priceBucketsWithAvailability,
    priceRangeInputMinValue,
    priceRangeInputMaxValue,
    handlePriceRangeChange,
    handlePriceRangeAfterChange,
    handlePriceRangeMinInputChange,
    handlePriceRangeMaxInputChange,
    handlePriceRangeMinInputFocus,
    handlePriceRangeMaxInputFocus,
    handlePriceRangeMinInputBlur,
    handlePriceRangeMaxInputBlur,
  } = useBudgetHistogram({
    priceLimitation,
    priceBuckets,
    selectedPriceLimitation,
    onSelectedPriceLimitationChange,
    delayWhenTypingForOnSelectedPriceLimitationChange,
  });

  return (
    <div data-testid="budget-histogram">
      <Histogram bucketsWithAvailability={priceBucketsWithAvailability} />
      <PriceRange
        priceLimitation={priceLimitation}
        priceRange={priceRange}
        onChange={handlePriceRangeChange}
        onAfterChange={handlePriceRangeAfterChange}
      />
      <div>
        <PriceRangeInputs
          priceRangeInputMinValue={priceRangeInputMinValue}
          priceRangeInputMaxValue={priceRangeInputMaxValue}
          onPriceRangeMinInputChange={handlePriceRangeMinInputChange}
          onPriceRangeMaxInputChange={handlePriceRangeMaxInputChange}
          onPriceRangeMinInputFocus={handlePriceRangeMinInputFocus}
          onPriceRangeMaxInputFocus={handlePriceRangeMaxInputFocus}
          onPriceRangeMinInputBlur={handlePriceRangeMinInputBlur}
          onPriceRangeMaxInputBlur={handlePriceRangeMaxInputBlur}
        />
      </div>
    </div>
  );
};
