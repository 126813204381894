import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import * as Icon from '../icon';
import { isThemeSpacing } from '../../utils/isThemeSpacing';

export type ButtonSizes = 'large' | 'small';
export type ButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'aux';
export type ButtonIconPosition = 'left' | 'right';

export interface ButtonProps {
  ariaLabel?: string;
  ariaExpanded?: boolean;
  ariaControls?: string;
  className?: string;
  size?: ButtonSizes;
  type?: ButtonTypes;
  text?: React.ReactNode;
  icon?: string;
  iconOnly?: boolean;
  iconPos?: ButtonIconPosition;
  iconRotated?: boolean;
  disabled?: boolean;
  id?: string;
  onClick?: () => void;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  tabIndex?: number;
}

const renderIcon = (
  name: string,
  iconOnly?: boolean,
  iconPos?: ButtonIconPosition,
  iconRotated?: boolean,
  size?: ButtonSizes,
  type?: ButtonTypes
) => {
  // @ts-ignore: Too complex to fix because icons are dynamic in this story
  const iconSize = iconOnly
    ? type === 'aux'
      ? size === 'small'
        ? 12
        : 16
      : 20
    : 14;
  const Component = Icon[name as keyof typeof Icon];
  return (
    <IconContainer
      iconPos={iconPos}
      iconOnly={iconOnly}
      iconRotated={iconRotated}
    >
      <Component height={iconSize} width={iconSize} />
    </IconContainer>
  );
};

const IconContainer = styled.span<{
  iconPos?: ButtonIconPosition;
  iconOnly?: boolean;
  iconRotated?: boolean;
}>`
  margin-left: ${(props) =>
    props.iconPos === 'left' && !props.iconOnly
      ? null
      : `${props.theme.spacing._1}`};
  margin-right: ${(props) =>
    props.iconPos === 'left' && !props.iconOnly
      ? `${props.theme.spacing._1}`
      : null};
  margin: ${(props) => (props.iconOnly ? `0` : null)};
  order: ${(props) => (props.iconPos === 'left' ? `-1` : null)};
  transform: ${(props) => (props.iconRotated ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s;
`;

const defaultButtonStyles = css<{
  iconOnly?: boolean;
  iconRotated?: boolean;
  size?: ButtonSizes;
  buttonType?: ButtonTypes;
}>`
  align-items: center;
  border: 0;
  ${(props) =>
    !props.iconOnly &&
    css`
      border-radius: ${props.theme.spacing._1};
    `}
  ${(props) =>
    !isThemeSpacing(
      props.theme.buttonConfig.iconOnlyBorderRadius,
      props.theme
    ) &&
    props.iconOnly &&
    css`
      border-radius: 100%;
    `}
  ${(props) =>
    isThemeSpacing(
      props.theme.buttonConfig.iconOnlyBorderRadius,
      props.theme
    ) &&
    props.iconOnly &&
    css`
      border-radius: ${props.theme.spacing[
        props.theme.buttonConfig.iconOnlyBorderRadius
      ]};
    `}
  display: flex;
  ${(props) =>
    props.size === 'small' &&
    props.iconOnly &&
    props.buttonType !== 'aux' &&
    css`
      height: ${props.theme.spacing._5};
      width: ${props.theme.spacing._5};
      padding: 0;
    `}
  ${(props) =>
    props.buttonType === 'aux' &&
    props.iconOnly &&
    css`
      padding: 0;
      height: ${props.theme.spacing._3};
      width: ${props.theme.spacing._3};
    `}
  ${(props) =>
    props.buttonType === 'aux' &&
    !props.iconOnly &&
    css`
      padding: ${props.theme.spacing._05} ${props.theme.spacing._1};
    `}
  ${(props) =>
    props.size === 'small' &&
    !props.iconOnly &&
    css`
      height: ${props.theme.spacing._5};
      padding: ${props.theme.spacing._1} ${props.theme.spacing._2};
    `}
  ${(props) =>
    props.size === 'large' &&
    props.iconOnly &&
    props.buttonType !== 'aux' &&
    css`
      height: ${props.theme.spacing._8};
      width: ${props.theme.spacing._8};
      padding: 0;
    `}
  ${(props) =>
    props.size === 'large' &&
    props.buttonType !== 'aux' &&
    !props.iconOnly &&
    css`
      height: ${props.theme.spacing._7};
      padding: ${props.theme.spacing._2};
    `}
    justify-content: center;
  &[disabled] {
    background: ${(props) => props.theme.colors.grey1};
    color: ${(props) => props.theme.colors.grey3};
    cursor: not-allowed;
    &:hover {
      background: ${(props) => props.theme.colors.grey1};
    }
  }
`;

const PrimaryButton = styled.button.attrs((props) => ({
  className: `${props.theme.buttonConfig.primary.font}`,
}))`
  ${defaultButtonStyles}
  ${(props) =>
    props.theme?.buttonConfig?.primary?.backgroundColor &&
    css`
      background: ${props.theme.colors[
        props.theme?.buttonConfig?.primary?.backgroundColor
      ]};
    `}
  color: ${(props) => props.theme.colors.white};
  &:hover,
  &:focus-visible {
    ${(props) =>
      props.theme?.buttonConfig?.primary?.hoverColor &&
      css`
        background: ${props.theme.colors[
          props.theme?.buttonConfig?.primary?.hoverColor
        ]};
      `}
  }
  &:focus-visible {
    ${(props) =>
      props.theme?.buttonConfig?.primary?.focusColor &&
      css`
        outline: 2px solid
          ${props.theme.colors[props.theme?.buttonConfig?.primary?.focusColor]};
      `}
  }
`;

const SecondaryButton = styled.button.attrs((props) => ({
  className: `${props.theme.buttonConfig.secondary.font}`,
}))`
  ${defaultButtonStyles}
  ${(props) =>
    props.theme?.buttonConfig?.secondary?.borderColor &&
    css`
      border: 1px solid
        ${props.theme.colors[props.theme?.buttonConfig?.secondary?.borderColor]};
    `}

    ${(props) =>
      props.theme?.buttonConfig?.secondary?.backgroundColor &&
      css`
        background: ${props.theme.colors[
          props.theme?.buttonConfig?.secondary?.backgroundColor
        ]};
      `}

      ${(props) =>
        props.theme?.buttonConfig?.secondary?.textColor &&
        css`
          color: ${props.theme.colors[
            props.theme?.buttonConfig?.secondary?.textColor
          ]};
        `}


  &:hover,
  &:focus-visible {
      ${(props) =>
        props.theme?.buttonConfig?.secondary?.hoverColor &&
        css`
          background: ${props.theme.colors[
            props.theme?.buttonConfig?.secondary?.hoverColor
          ]};
        `}

  }
  &:focus-visible {
      ${(props) =>
        props.theme?.buttonConfig?.secondary?.focusColor &&
        css`
          outline: 2px solid
            ${props.theme.colors[
              props.theme?.buttonConfig?.secondary?.focusColor
            ]};
        `}
  }
  &[disabled] {
    border: 1px solid transparent;
  }
}`;

const TertiaryButton = styled.button.attrs((props) => ({
  className: `${props.theme.buttonConfig.tertiary.font}`,
}))`
  ${defaultButtonStyles}
  background: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.theme?.buttonConfig?.tertiary?.textColor &&
    css`
      color: ${props.theme.colors[
        props.theme?.buttonConfig?.tertiary?.textColor
      ]};
    `}
  &:hover,
  &:focus-visible {
    ${(props) =>
      props.theme?.buttonConfig?.tertiary?.hoverColor &&
      css`
        background: ${props.theme.colors[
          props.theme?.buttonConfig?.tertiary?.hoverColor
        ]};
      `}
  }
  &:focus-visible {
    ${(props) =>
      props.theme?.buttonConfig?.tertiary?.focusBorderColor &&
      css`
        outline: 1px solid
          ${props.theme.colors[
            props.theme?.buttonConfig?.tertiary?.focusBorderColor
          ]};
      `}
  }
  &[disabled] {
    background: none;
  }
}`;

const AuxButton = styled.button.attrs((props) => ({
  className: `${props.theme.buttonConfig.aux.font}`,
}))`
  ${defaultButtonStyles}
  background: transparent;
  height: ${(props) => props.theme.spacing._3};
  ${(props) =>
    props.theme?.buttonConfig?.aux?.textColor &&
    !props.iconOnly &&
    css`
      color: ${props.theme.colors[props.theme?.buttonConfig?.aux?.textColor]};
    `}
  ${(props) =>
    props.theme?.buttonConfig?.aux?.textColor &&
    props.iconOnly &&
    css`
      color: ${props.theme.colors.black};
    `}
  &:hover,
  &:focus-visible {
    ${(props) =>
      props.theme?.buttonConfig?.aux?.hoverColor &&
      !props.iconOnly &&
      css`
        background: ${props.theme.colors[
          props.theme?.buttonConfig?.aux?.hoverColor
        ]};
      `}
      ${(props) =>
        props.theme?.buttonConfig?.aux?.hoverColor &&
        props.iconOnly &&
        css`
          color: ${props.theme.colors.grey6};
        `}
    }
  &:focus-visible {
    ${(props) =>
      props.theme?.buttonConfig?.aux?.focusBorderColor &&
      !props.iconOnly &&
      css`
        outline: 1px solid
          ${props.theme.colors[
            props.theme?.buttonConfig?.aux?.focusBorderColor
          ]};
      `}
      ${(props) =>
        props.theme?.buttonConfig?.aux?.focusBorderColor &&
        props.iconOnly &&
        css`
          outline: 1px solid ${props.theme.colors.grey6};
        `}
    }
}`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonProps,
      className,
      type,
      size,
      text,
      icon,
      iconPos,
      iconOnly,
      iconRotated,
      ariaLabel,
      ariaExpanded,
      ariaControls,
      disabled,
      id,
      onClick,
      tabIndex,
    },
    ref
  ) => {
    return type === 'primary' ? (
      <PrimaryButton
        type="button"
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        className={className}
        iconOnly={iconOnly}
        iconRotated={iconRotated}
        size={size}
        disabled={disabled}
        id={id}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
        {...buttonProps}
      >
        {text}
        {icon ? renderIcon(icon, iconOnly, iconPos, iconRotated) : null}
      </PrimaryButton>
    ) : type === 'secondary' ? (
      <SecondaryButton
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        className={className}
        iconOnly={iconOnly}
        iconRotated={iconRotated}
        size={size}
        disabled={disabled}
        id={id}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
        {...buttonProps}
      >
        {text}
        {icon ? renderIcon(icon, iconOnly, iconPos, iconRotated) : null}
      </SecondaryButton>
    ) : type === 'tertiary' ? (
      <TertiaryButton
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        className={className}
        iconOnly={iconOnly}
        iconRotated={iconRotated}
        size={size}
        disabled={disabled}
        id={id}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
        {...buttonProps}
      >
        {text}
        {icon ? renderIcon(icon, iconOnly, iconPos, iconRotated) : null}
      </TertiaryButton>
    ) : type === 'aux' ? (
      <AuxButton
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        className={className}
        iconOnly={iconOnly}
        iconRotated={iconRotated}
        size={size}
        disabled={disabled}
        id={id}
        onClick={onClick}
        tabIndex={tabIndex}
        buttonType={type}
        ref={ref}
        {...buttonProps}
      >
        {text}
        {icon
          ? renderIcon(icon, iconOnly, iconPos, iconRotated, size, type)
          : null}
      </AuxButton>
    ) : null;
  }
);

Button.defaultProps = {
  iconOnly: false,
  size: 'large',
  type: 'primary',
  iconPos: 'right',
  disabled: false,
};
