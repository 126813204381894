import { Airport, AirportsGroup, Value } from './types';
import { DEFAULT_VALUE, EMPTY_INPUT_TEXT } from './constants';
import { getValueLabel } from './utils';

export type UseAirportsSelectorInput = {
  value: Value;
  onChange: (value: Value) => void;
};

export const useAirportsSelector = ({
  onChange,
  value,
}: UseAirportsSelectorInput) => {
  const clear = () => {
    onChange(DEFAULT_VALUE);
  };

  const selectAirport = (airport: Airport) => {
    onChange(value.concat(airport.code));
  };

  const unselectAirport = (airport: Airport) => {
    onChange(value.filter((code) => code !== airport.code));
  };

  const selectAirportsGroup = (airportsGroup: AirportsGroup) => {
    const airportCodesToSelect = airportsGroup.airportCodes.filter(
      (code) => !value.includes(code)
    );
    onChange(value.concat(airportCodesToSelect));
  };

  const unselectAirportsGroup = (airportsGroup: AirportsGroup) => {
    onChange(
      value.filter((code) => !airportsGroup.airportCodes.includes(code))
    );
  };

  return {
    label: getValueLabel(value) || EMPTY_INPUT_TEXT,
    clear,
    allowClear: value.length > 0,
    allowDone: value.length > 0,
    selectAirport,
    unselectAirport,
    selectAirportsGroup,
    unselectAirportsGroup,
  };
};
