import { Value } from './types';
import { getValueLabel } from './utils';

export type UseDurationSelectorInput = {
  value: Value;
  onChange: (value: Value) => void;
};

export const useDurationSelector = ({
  onChange,
  value,
}: UseDurationSelectorInput) => {
  const selectDuration = (newValue: Value) => {
    onChange(newValue);
  };

  return {
    label: getValueLabel(value),
    selectDuration,
  };
};
