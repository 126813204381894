import { CalendarState } from '@react-stately/calendar';
import { useCalendarGrid } from 'react-aria';
import styled from 'styled-components';
import { endOfMonth, getWeeksInMonth } from '@internationalized/date';
import { CalendarCell } from '../calendarCell';
import React from 'react';
import { device } from '../../../../css/sizes';

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: ${(props) => props.theme.spacing.unit / 2}px;
  justify-items: center;
  align-items: center;
  @media ${device.sm} {
    gap: ${(props) => props.theme.spacing.unit}px
      ${(props) => props.theme.spacing.unit * 1.5}px;
  }
  @media ${device.md} {
    gap: ${(props) => props.theme.spacing.unit * 0.5}px
      ${(props) => props.theme.spacing.unit}px;
  }
`;

type Props = {
  state: CalendarState;
  monthsOffset?: number;
  locale: string;
};

export const CalendarGrid = ({ state, monthsOffset, locale }: Props) => {
  const startDate = state.visibleRange.start.add({ months: monthsOffset });
  const endDate = endOfMonth(startDate);
  const { gridProps } = useCalendarGrid({ startDate, endDate }, state);

  const weeksInMonth = getWeeksInMonth(startDate, locale);

  return (
    <Table {...gridProps}>
      {[...new Array(weeksInMonth).keys()].map((weekIndex) =>
        state
          .getDatesInWeek(weekIndex, startDate)
          .map((date, i) =>
            date ? (
              <CalendarCell
                key={`${weekIndex}-${i}`}
                state={state}
                date={date}
                currentMonth={startDate}
              />
            ) : (
              <div key={`${weekIndex}-${i}`} />
            )
          )
      )}
    </Table>
  );
};
