import React from 'react';
import { BaseSvg, type BaseSvgProps } from '../../../baseSvg';

export const DashLg = (baseSvgProps: Omit<BaseSvgProps, 'children'>) => {
  return (
    <BaseSvg {...baseSvgProps}>
      <path
        fillRule="evenodd"
        d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
      />
    </BaseSvg>
  );
};
