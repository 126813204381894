import { Value } from './types';
import { DateFormatter } from '@internationalized/date';

export const getValueLabel = (
  value: Value,
  locale: string,
  timezone: string
): string => {
  if (!value) return '';
  const formatter = new DateFormatter(locale, {
    dateStyle: 'medium',
    timeZone: timezone,
  });
  return formatter.format(value.toDate(timezone));
};

export const validate = (value: Value): string | null => {
  if (!value) return 'Please select a departure date';
  return null;
};
