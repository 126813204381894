import React from 'react';
import styled, { ThemeColor, useTheme } from 'styled-components';
import { generateCloudflareImageSrc } from '../../../utils/generateCloudflareImageSrc';
import { generateCloudflareImageSrcset } from '../../../utils/generateCloudflareImageSrcset';
import { breakpoints, device } from '../../../css/sizes';
const ImageWrapper = styled.div`
  border-radius: ${(props) => props.theme.spacing._1};
  margin-bottom: ${(props) => props.theme.spacing._2};
  overflow: hidden;
`;

const WrapperLink = styled.a.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})<{
  focusBorderColor?: ThemeColor;
}>`
  border-radius: ${(props) => props.theme.spacing._1};
  display: block;
  text-decoration: none;
  width: 100%;
  &:focus-visible {
    outline: ${(props) =>
      props.focusBorderColor
        ? `3px solid ${props.theme.colors[props.focusBorderColor]}`
        : `3px solid ${props.theme.colors.black}`};
    outline-offset: 3px;
  }
  @media ${device.lg} {
    width: 220px;
  }
`;

const Image = styled.img`
  transition: transform 0.5s;
  width: 100%;
  ${WrapperLink}:hover & , ${WrapperLink}:focus & {
    transform: scale(1.1);
  }
`;

const Tag = styled.span.attrs({
  className: 'font-hotel font-hotel--bold',
})<{
  tagColor?: ThemeColor;
}>`
  color: ${(props) =>
    props.tagColor
      ? props.theme.colors[props.tagColor]
      : props.theme.colors.grey5};
  display: block;
`;

const Title = styled.span.attrs({
  className: 'font-echo',
})`
  color: ${(props) => props.theme.colors.black};
  display: block;
`;

const SubText = styled.span.attrs({
  className: 'font-golf',
})`
  color: ${(props) => props.theme.colors.grey6};
  display: block;
`;

interface NavCardProps {
  image: string;
  subText: string;
  tag: string;
  tagColor?: ThemeColor;
  title: string;
  link: string;
}

export const NavCard = ({
  image,
  subText,
  tag,
  tagColor,
  title,
  link,
}: NavCardProps) => {
  const theme = useTheme();
  return (
    <WrapperLink focusBorderColor={tagColor} href={link}>
      <ImageWrapper>
        <picture>
          <source
            media={`(max-width: ${breakpoints.lg})`}
            srcSet={generateCloudflareImageSrc(
              image,
              theme.cloudflareImagesUrl,
              { width: 343, height: 160 }
            )}
          />
          <source
            media={device.lg}
            srcSet={generateCloudflareImageSrc(
              image,
              theme.cloudflareImagesUrl,
              { width: 240, height: 216 }
            )}
          />
          <Image
            src={generateCloudflareImageSrc(image, theme.cloudflareImagesUrl, {
              width: 240,
              height: 216,
            })}
            loading="lazy"
            width="240"
            height="216"
            alt="Navigation Card image"
          />
        </picture>
      </ImageWrapper>
      <Tag tagColor={tagColor}>{tag}</Tag>
      <Title>{title}</Title>
      <SubText>{subText}</SubText>
    </WrapperLink>
  );
};
