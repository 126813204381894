import React from 'react';
import styled, { useTheme, css } from 'styled-components';
import { AdvertisingTypes } from '@supersonic/store';
import { device } from '../../../css/sizes';
import * as Icon from '../../icon';
import { Button } from '../../button';
import { CustomImage } from './customImage';
import { AdvertiserLogo } from './advertiserLogo';
import { useStore } from '../../../storeProvider';

type StyledProps = { $hasImage: boolean; $hasLogo: boolean; $hasCta: boolean };

const Wrapper = styled.div`
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.spacing._1};
  margin-bottom: ${(props) => props.theme.spacing._3};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  @media ${device.md} {
    display: flex;
  }
`;
const ContentWrapper = styled.div<StyledProps>`
  width: 100%;
  padding: ${(props) => props.theme.spacing.unit * 2}px;
  padding-top: ${(props) =>
    !props.$hasImage && !props.$hasLogo
      ? props.theme.spacing.unit * 7
      : props.theme.spacing.unit * 2}px;
  @media ${device.md} {
    display: flex;
    flex-direction: ${(props) => (props.$hasImage ? 'column' : 'row')};
    padding-top: ${(props) =>
      props.$hasImage && !props.$hasLogo
        ? props.theme.spacing.unit * 7
        : props.theme.spacing.unit * 2}px;
    padding-right: ${(props) =>
      !props.$hasImage && !props.$hasLogo && !props.$hasCta
        ? props.theme.spacing.unit * 8
        : props.theme.spacing.unit * 2}px;
  }
`;
const HeroTextAndUspsWrapper = styled.div`
  flex: 1;
`;
const HeroText = styled.h3.attrs({ className: 'font-echo font-echo--bold' })`
  margin: 0 0 ${(props) => props.theme.spacing.unit}px 0;
`;
const UspsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const UspItem = styled.li`
  display: flex;
  align-items: center;
`;
const UspIconWrapper = styled.div`
  flex: 0 0 auto;
  margin: 0 ${(props) => props.theme.spacing.unit}px;
`;
const UspText = styled.span.attrs({ className: 'font-golf' })`
  color: ${(props) => props.theme.colors.grey7};
`;
const AdvertiserAndCtaWrapper = styled.div<StyledProps>`
  padding-top: ${(props) => props.theme.spacing.unit * 2}px;
  display: flex;
  align-items: center;
  @media ${device.md} {
    ${(props) =>
      props.$hasImage
        ? css`
            flex-direction: row;
            margin-top: auto;
          `
        : css<StyledProps>`
            flex-direction: column;
            padding-top: ${(props) =>
              props.$hasLogo ? 0 : props.theme.spacing._5};
            padding-left: ${(props) => props.theme.spacing.unit * 2}px;
          `};
  }
`;
const CtaWrapper = styled.div<StyledProps>`
  margin-left: auto;
  @media ${device.md} {
    margin-top: ${(props) => (props.$hasImage ? 0 : 'auto')};
  }
`;
const AdvertiserLogoWrapper = styled.div<StyledProps>`
  @media ${device.md} {
    margin-bottom: ${(props) => (props.$hasImage ? 0 : props.theme.spacing._2)};
  }
`;
const AdPill = styled.div.attrs({ className: 'font-hotel' })`
  background: ${(props) =>
    props.theme.colors[props.theme.inlineAd.adPillBackgroundColor]};
  position: absolute;
  padding: ${(props) => props.theme.spacing.unit / 2}px
    ${(props) => props.theme.spacing.unit}px;
  border-radius: ${(props) => props.theme.spacing.unit / 2}px;
  top: ${(props) => props.theme.spacing.unit * 2}px;
  right: ${(props) => props.theme.spacing.unit * 2}px;
`;
type Props = {
  ad: AdvertisingTypes.InlineAd;
};

export const InlineAd = ({ ad }: Props) => {
  const theme = useTheme();
  const getClickLink = useStore((state) => state.advertising.getClickLink);
  const styledProps: StyledProps = {
    $hasLogo: ad.content.image_settings.show_brand_logo,
    $hasImage: ad.content.image_settings.show_custom_image,
    $hasCta: ad.cta_enabled,
  };
  return (
    <Wrapper
      {...(ad.has_deeplink
        ? {
            as: 'a',
            href: getClickLink(ad),
            target: '_blank',
            rel: 'noopener noreferrer',
            'aria-label': "Go to advertiser's website",
          }
        : { as: 'div' })}
    >
      {ad.content.image_settings.show_custom_image && (
        <CustomImage path={ad.content.image_settings.custom_image_path} />
      )}
      <ContentWrapper {...styledProps}>
        <HeroTextAndUspsWrapper>
          <HeroText>{ad.content.hero_text}</HeroText>
          <UspsList>
            {ad.content.usps.map((usp, index) => (
              <UspItem key={index}>
                <UspIconWrapper>
                  <Icon.Check
                    width={16}
                    height={16}
                    color={theme.inlineAd.uspCheckColor}
                  />
                </UspIconWrapper>
                <UspText>{usp}</UspText>
              </UspItem>
            ))}
          </UspsList>
        </HeroTextAndUspsWrapper>
        {(ad.content.image_settings.show_brand_logo || ad.cta_enabled) && (
          <AdvertiserAndCtaWrapper {...styledProps}>
            {ad.content.image_settings.show_brand_logo && (
              <AdvertiserLogoWrapper {...styledProps}>
                <AdvertiserLogo advertiserUuid={ad.advertiser_uuid} />
              </AdvertiserLogoWrapper>
            )}
            {ad.cta_enabled && (
              <CtaWrapper {...styledProps}>
                <Button text="More info" size="small" type="secondary" />
              </CtaWrapper>
            )}
          </AdvertiserAndCtaWrapper>
        )}
        {!ad.content.image_settings.show_brand_logo && <AdPill>Ad</AdPill>}
      </ContentWrapper>
    </Wrapper>
  );
};
