import { useEffect } from 'react';

export type GetElementFn = () => Element | null;
export type OnRequestCloseFn = (
  reason: 'click-out' | 'escape-key' | 'focus-out'
) => void;

type UseDropdownInput = {
  getTriggerElement: GetElementFn;
  getDropdownElement: GetElementFn;
  onRequestClose: OnRequestCloseFn;
  isOpen: boolean;
};

export const useDropdown = ({
  isOpen,
  getTriggerElement,
  getDropdownElement,
  onRequestClose,
}: UseDropdownInput) => {
  useEffect(() => {
    if (!isOpen) return;
    const handleClick = ({ target }: MouseEvent | TouchEvent) => {
      const triggerElement = getTriggerElement();
      if (target instanceof Element && !triggerElement?.contains(target)) {
        onRequestClose('click-out');
      }
    };
    const handleKeyDown = ({ code }: KeyboardEvent) => {
      if (code === 'Escape') {
        onRequestClose('escape-key');
      }
    };
    const handleFocus = ({ target, ...e }: FocusEvent) => {
      const gatsbyFocusWrapper = document.getElementById(
        'gatsby-focus-wrapper'
      );
      const triggerElement = getTriggerElement();
      const dropdownElement = getDropdownElement();
      if (
        target instanceof Element &&
        target &&
        target !== gatsbyFocusWrapper &&
        !dropdownElement?.contains(target) &&
        !triggerElement?.contains(target)
      ) {
        onRequestClose('focus-out');
      }
    };
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);
    document.addEventListener('focusin', handleFocus);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
      document.removeEventListener('focusin', handleFocus);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);
};
