import equal from 'fast-deep-equal';
import { Value } from './types';
import { DEFAULT_VALUE } from './constants';
import {
  canAddPassenger,
  canRemoveAdult,
  canRemoveChild,
  getValueLabel,
} from './utils';

export type UsePassengersSelectorInput = {
  value: Value;
  onChange: (value: Value) => void;
};

export const usePassengersSelector = ({
  onChange,
  value,
}: UsePassengersSelectorInput) => {
  const clear = () => {
    onChange(DEFAULT_VALUE);
  };
  const addAdult = () => {
    if (!canAddPassenger(value)) return;
    onChange({ ...value, adults: value.adults + 1 });
  };
  const removeAdult = () => {
    if (!canRemoveAdult(value)) return;
    onChange({ ...value, adults: value.adults - 1 });
  };

  const addChild = () => {
    if (!canAddPassenger(value)) return;
    onChange({ ...value, childAges: value.childAges.concat(null) });
  };

  const removeChild = () => {
    if (!canRemoveChild(value)) return;
    onChange({ ...value, childAges: value.childAges.slice(0, -1) });
  };

  const setChildAge = (childAge: number, childIndex: number) => {
    onChange({
      ...value,
      childAges: value.childAges.map((currentChildAge, currentIndex) => {
        if (childIndex === currentIndex) {
          return childAge;
        } else {
          return currentChildAge;
        }
      }),
    });
  };

  return {
    label: getValueLabel(value),
    clear,
    allowClear: !equal(value, DEFAULT_VALUE),
    addAdult,
    allowAddAdult: canAddPassenger(value),
    addChild,
    allowAddChild: canAddPassenger(value),
    removeAdult,
    allowRemoveAdult: canRemoveAdult(value),
    removeChild,
    allowRemoveChild: canRemoveChild(value),
    setChildAge,
  };
};
