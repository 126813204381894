import { SearchResultsTypes, DestinationsTypes } from '@supersonic/store';
import { FormValues, SubmittedFormValues } from './types';
import { validate as validateAirports } from '../airportsSelector/utils';
import { validate as validateDestination } from '../destinationSelector/utils';
import { validate as validateDate } from '../dateSelector/utils';
import {
  DEFAULT_VALUE as DURATION_SELECTOR_DEFAULT_VALUE,
  OTHER_DURATIONS,
} from '../durationSelector/constants';
import { validateChildAges } from '../passengersSelector/utils';
import { POPULAR_DURATIONS } from '../durationSelector/constants';
import { parseDate } from '@internationalized/date';
import { FormikErrors } from 'formik';

export const mapFormValuesToSearchParams = (
  values: SubmittedFormValues
): SearchResultsTypes.SearchParams => {
  const [destinationCountry, destinationArea, destinationRegion] =
    values.destination.sgh_codes.split('-');
  return {
    duration: values.duration.split(':')[1],
    departureDate: values.date.toString(),
    departureAirports: values.airports.join(','),
    ...(values.destination.tti_place_key && {
      destinationId: values.destination.tti_place_key,
    }),
    destinationCountry,
    destinationArea,
    destinationRegion,
    adults: String(values.passengers.adults),
    childAges: values.passengers.childAges.join(','),
  };
};

export const getInitialValues = (
  searchParams: SearchResultsTypes.SearchParams,
  currentDestination: DestinationsTypes.Destination
): FormValues => {
  const durationCode =
    POPULAR_DURATIONS.find(
      (duration) => duration.value === `0:${searchParams.duration}`
    )?.value ||
    OTHER_DURATIONS.find(
      (duration) => duration.value === `1:${searchParams.duration}`
    )?.value ||
    DURATION_SELECTOR_DEFAULT_VALUE;
  return {
    airports: searchParams.departureAirports.split(','),
    destination: currentDestination,
    date: parseDate(searchParams.departureDate),
    duration: durationCode,
    passengers: {
      adults: Number(searchParams.adults),
      childAges: searchParams.childAges
        ? searchParams.childAges.split(',').map((age: string) => Number(age))
        : [],
    },
  };
};

export const validate = (values: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {};

  const destinationError = validateDestination(values.destination);
  // @ts-ignore destination is an object, so FormikErrors expects error text for every field of destination object.
  if (destinationError) errors.destination = destinationError;

  const airportsError = validateAirports(values.airports);
  if (airportsError) errors.airports = airportsError;

  const dateError = validateDate(values.date);
  if (dateError) errors.date = dateError;

  const childAgesError = validateChildAges(values.passengers.childAges);
  if (childAgesError) errors.passengers = { childAges: childAgesError };

  return errors;
};
