import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useStore } from '../../storeProvider';

const Wrapper = styled.div``;

const breatheAnimation = keyframes`
  0% { opacity: 1; }
  25% { opacity: .1; }
  50% { opacity: 1; }
  75% { opacity: .1; }
  100% { opacity: 1; }
`;

const SearchLabel = styled.span.attrs({
  className: 'font-hotel',
})`
  animation: ${breatheAnimation} 4s infinite;
  animation-direction: alternate;
  color: ${(props) => props.theme.colors.grey6};
  display: inline-block;
  margin-bottom: ${(props) => props.theme.spacing._1};
`;

const ProgressBarWrapper = styled.div`
  background: ${(props) => props.theme.colors.sand_lighter1};
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: ${(props) => props.theme.spacing._2};
  padding: ${(props) => props.theme.spacing._05} 0;
`;

const ProgressBarInner = styled.div`
  border-radius: ${(props) => props.theme.spacing._2};
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors[props.theme.progressBar.gradientStart]} 0%,
    ${(props) => props.theme.colors[props.theme.progressBar.gradientStop]} 100%
  );
  height: ${(props) => props.theme.spacing._1};
  transition: width 1s ease;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const PartnerLogoContainer = styled.div`
  display: flex;
  position: relative;
  ul {
    list-style: none;
    padding: 0 ${(props) => props.theme.spacing._1};
    margin: ${(props) => props.theme.spacing._1} 0;
    li {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100px;
      height: auto;
      opacity: 0;
      transition: opacity 1s ease-in;
      &.show {
        visibility: visible;
        opacity: 1;
        animation-direction: alternate;
      }
    }
  }
`;

export interface ProgressBarProps {
  now: number | undefined;
}

export const ProgressBar = ({ now }: ProgressBarProps) => {
  const [currentPartner, setCurrentPartner] = useState(0);
  const store = useStore((state) => state.searchResults);
  const watchUrlPartnerIds = store.watchUrlsPartnerIds;
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPartner(
        (prevPartner) => (prevPartner + 1) % watchUrlPartnerIds.length
      );
    }, 1000);
    return () => clearInterval(timer);
  }, [watchUrlPartnerIds]);

  return (
    <Wrapper>
      <SearchContainer>
        <SearchLabel id="progressbarlabel">Searching&hellip;</SearchLabel>
        <PartnerLogoContainer>
          <ul aria-hidden="true">
            {watchUrlPartnerIds &&
              watchUrlPartnerIds.map((watchUrlPartnerId, index) => (
                <li
                  key={watchUrlPartnerId}
                  className={index === currentPartner ? 'show' : ''}
                >
                  <img
                    width="124"
                    alt={watchUrlPartnerId}
                    src={`https://d2lqjbz18tdbni.cloudfront.net/88x40/${watchUrlPartnerId}.png`}
                    loading={'lazy'}
                  />
                </li>
              ))}
          </ul>
        </PartnerLogoContainer>
      </SearchContainer>

      <ProgressBarWrapper
        role="progressbar"
        aria-labelledby="progressbarlabel"
        aria-valuenow={now}
      >
        <ProgressBarInner style={{ width: `${now}%` }}></ProgressBarInner>
      </ProgressBarWrapper>
    </Wrapper>
  );
};
