import React, { useRef } from 'react';
import styled from 'styled-components';
import { Value } from './types';
import { useDurationSelector } from './useDurationSelector';
import { InputField, InputFieldHandle } from '../../inputField';
import { DesktopGadgetDropdown } from '../desktopGadgetDropdown';
import { MobileGadgetModal } from '../mobileGadgetModal';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { device } from '../../../css/sizes';
import { POPULAR_DURATIONS, OTHER_DURATIONS } from './constants';
import { Radio } from '../../radio';

export type Props = {
  value: Value;
  fieldLabel: string;
  isOpen: boolean;
  onChange: (value: Value) => void;
  onOpen: () => void;
  onClose: () => void;
  onBack: () => void;
};

const Wrapper = styled.div`
  position: relative;
`;
const RadioGroupLabel = styled.div.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})`
  margin-bottom: ${(props) => props.theme.spacing.unit * 2}px;
`;
const RadioGroupWrapper = styled.div`
  display: grid;
  gap: ${(props) => props.theme.spacing.unit * 2}px;
  margin-bottom: ${(props) => props.theme.spacing.unit * 2}px;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const DurationSelector = (props: Props) => {
  const isTabletOrDesktop = useMediaQuery(device.md);
  const selector = useDurationSelector({
    value: props.value,
    onChange: props.onChange,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<InputFieldHandle>(null);

  const content = (
    <div data-testid="duration-selector-content">
      <RadioGroupLabel>Popular Durations</RadioGroupLabel>
      <RadioGroupWrapper data-testid="duration-selector-content-popular-destinations">
        {POPULAR_DURATIONS.map((durationOption) => (
          <Radio
            key={durationOption.value}
            checked={durationOption.value === props.value}
            text={durationOption.text}
            name="duration"
            value={durationOption.value}
            ariaLabel={durationOption.text}
            onChange={() => {
              selector.selectDuration(durationOption.value);
            }}
          />
        ))}
      </RadioGroupWrapper>
      <RadioGroupLabel>Other Durations</RadioGroupLabel>
      <RadioGroupWrapper data-testid="duration-selector-content-other-destinations">
        {OTHER_DURATIONS.map((durationOption) => (
          <Radio
            key={durationOption.value}
            checked={durationOption.value === props.value}
            text={durationOption.text}
            name="duration"
            value={durationOption.value}
            ariaLabel={durationOption.text}
            onChange={() => {
              selector.selectDuration(durationOption.value);
            }}
          />
        ))}
      </RadioGroupWrapper>
    </div>
  );

  return (
    <Wrapper ref={wrapperRef} data-testid="duration-selector">
      <InputField
        type="button"
        size="regular"
        label={selector.label}
        labelProps={{
          htmlFor: 'duration-selector-button',
          'data-testid': 'duration-selector-input-field',
        }}
        leftIcon="CalendarWeek"
        fieldLabel={props.fieldLabel}
        ref={inputFieldRef}
        active={props.isOpen}
        buttonProps={{
          id: 'duration-selector-button',
          'aria-label': 'Select duration',
          onClick: () => (props.isOpen ? props.onClose() : props.onOpen()),
        }}
      />
      {isTabletOrDesktop ? (
        <DesktopGadgetDropdown
          testId="duration-selector-desktop-dropdown"
          isOpen={props.isOpen}
          onRequestClose={(reason) => {
            props.onClose();
            if (reason === 'escape-key') {
              inputFieldRef.current?.focus();
            }
          }}
          getTriggerElement={() => wrapperRef.current}
          headerText="For how long?"
          footerPrimaryButton={{
            onClick: props.onClose,
            text: 'Done',
          }}
          restrictHeightToFitInViewport
          maxHeight={400}
        >
          {content}
        </DesktopGadgetDropdown>
      ) : (
        <MobileGadgetModal
          testId="duration-selector-mobile-modal"
          isOpen={props.isOpen}
          headerText="For how long?"
          onClose={props.onClose}
          onBack={props.onBack}
          footerPrimaryButton={{
            onClick: props.onClose,
            text: 'Done',
          }}
        >
          {content}
        </MobileGadgetModal>
      )}
    </Wrapper>
  );
};
