import * as React from 'react';
import { type HeadFC } from 'gatsby';

const AboutPage = ({ data }) => {
  return (
    <main>
      <h1>Aout page</h1>
      <p>packages/theme-search-results/src/pages/about/index.tsx</p>
    </main>
  );
};

export default AboutPage;

export const Head: HeadFC = () => {
  //maybe move this stuff to a seperate SEO component?
  //issue with Gatsby Head API: https://github.com/gatsbyjs/gatsby/discussions/35841
  return (
    <>
      <title>About</title>;
    </>
  );
};
