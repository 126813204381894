import React, {
  forwardRef,
  ButtonHTMLAttributes,
  ComponentProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  useImperativeHandle,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';
import { useFocusRing, mergeProps } from 'react-aria';
import * as Icon from '../icon';
import { IconByName } from '../iconByName';
import { Tooltip } from '../tooltip';

type Size = 'regular' | 'small';

type SharedProps = {
  size: Size;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement> & {
    'data-testid'?: string;
  };
  disabled?: boolean;
  error?: boolean;
  active?: boolean;
  fieldLabel?: string;
  leftIcon?: keyof typeof Icon;
  leftIconWidth?: number;
  leftIconHeight?: number;
  leftIconAriaLabel?: string;
  leftIconClickableAriaLabel?: string;
  onLeftIconClick?: () => void;
  rightIcon?: keyof typeof Icon;
  rightIconWidth?: number;
  rightIconHeight?: number;
  rightIconAriaLabel?: string;
  rightIconClickableAriaLabel?: string;
  onRightIconClick?: () => void;
  tooltipProps?: ComponentProps<typeof Tooltip>;
};
export type ButtonFieldProps = SharedProps & {
  type: 'button';
  label: string;
  buttonProps: ButtonHTMLAttributes<HTMLButtonElement>;
};
export type TextInputFieldProps = SharedProps & {
  type: 'text-input';
  inputPrefix?: string;
  inputProps: InputHTMLAttributes<HTMLInputElement>;
};
export type Props = ButtonFieldProps | TextInputFieldProps;
type StylingProps = {
  $size: Size;
  $active: boolean;
  $disabled: boolean;
  $error: boolean;
};

const Wrapper = styled.label<StylingProps>`
  ${(props) =>
    (props.$disabled &&
      css`
        box-shadow: inset 0 0 0 1px ${props.theme.colors.grey3};
      `) ||
    (props.$active &&
      css`
        box-shadow: inset 0 0 0 2px
          ${props.theme.colors[props.theme.inputField.primaryColor]};
      `) ||
    (props.$error &&
      css`
        box-shadow: inset 0 0 0 3px ${props.theme.colors.semantic_error},
          0 0 0 2px ${props.theme.colors.semantic_error_outline};
      `) ||
    css`
      box-shadow: inset 0 0 0 1px ${props.theme.colors.grey3};
    `}
  height: ${(props) =>
    props.theme.spacing.unit * (props.$size === 'regular' ? 8 : 7)}px;
  padding: 0 ${(props) => props.theme.spacing.unit * 2}px;
  border-radius: ${(props) => props.theme.spacing.unit}px;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  width: 100%;
  background: ${(props) =>
    props.$disabled ? props.theme.colors.grey1 : props.theme.colors.white};
`;

const LabelsWrapper = styled.div`
  overflow: hidden;
  flex: 1;
`;

const FieldLabel = styled.div.attrs({
  className: 'font-hotel',
})<StylingProps>`
  color: ${(props) =>
    (props.$disabled && props.theme.colors.grey3) ||
    (props.$active && props.theme.colors.grey6) ||
    (props.$error && props.theme.colors.semantic_error) ||
    props.theme.colors.grey6};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ValueButton = styled.button.attrs<StylingProps>((props) => ({
  className:
    props.$error && !props.$active
      ? 'font-foxtrot font-foxtrot--bold'
      : 'font-foxtrot',
}))<StylingProps>`
  color: ${(props) =>
    (props.$disabled && props.theme.colors.grey3) ||
    (props.$active && props.theme.colors.black) ||
    (props.$error && props.theme.colors.semantic_error) ||
    props.theme.colors.black};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const ValueInput = styled.input.attrs<StylingProps>((props) => ({
  className:
    props.$error && !props.$active
      ? 'font-foxtrot font-foxtrot--bold'
      : 'font-foxtrot',
}))<StylingProps>`
  color: ${(props) =>
    (props.$disabled && props.theme.colors.grey3) ||
    (props.$active && props.theme.colors.black) ||
    (props.$error && props.theme.colors.semantic_error) ||
    props.theme.colors.black};
  padding: 0;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  overflow: hidden;
  text-overflow: ellipsis;
  ::placeholder {
    color: ${(props) =>
      (props.$disabled && props.theme.colors.grey3) ||
      (props.$active && props.theme.colors.grey4) ||
      (props.$error && props.theme.colors.semantic_error) ||
      props.theme.colors.grey4};
  }
`;

const InputPrefix = styled.div.attrs({ className: 'font-foxtrot' })`
  display: inline-block;
`;

const iconButtonWrapperCss = css<StylingProps>`
  padding: ${(props) => props.theme.spacing.unit}px;
  margin: -${(props) => props.theme.spacing.unit}px;
  border: none;
  background: none;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  flex: 0 0 auto;
  &:focus-visible {
    outline: 2px solid
      ${(props) => props.theme.colors[props.theme.inputField.primaryColor]};
    border-radius: ${(props) => props.theme.spacing.unit}px;
  }
`;

const LeftIconWrapper = styled.div<StylingProps>`
  ${iconButtonWrapperCss};
  color: ${(props) =>
    (props.$disabled && props.theme.colors.grey3) ||
    (props.$active &&
      props.theme.colors[props.theme.inputField.primaryColor]) ||
    (props.$error && props.theme.colors.semantic_error) ||
    props.theme.colors[props.theme.inputField.primaryColor]};
  margin-right: 0;
`;

const RightIconWrapper = styled.div<StylingProps>`
  ${iconButtonWrapperCss};
  color: ${(props) =>
    (props.$disabled && props.theme.colors.grey3) ||
    (props.$active && props.theme.colors.black) ||
    (props.$error && props.theme.colors.semantic_error) ||
    props.theme.colors.black};
  margin-left: auto;
`;

export type InputFieldHandle = {
  focus: () => void;
};

export const InputField = forwardRef(
  (props: Props, ref: React.Ref<InputFieldHandle>) => {
    const buttonFocusRing = useFocusRing();
    const inputFocusRing = useFocusRing({ isTextInput: true });
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        buttonRef.current?.focus();
        inputRef.current?.focus();
      },
    }));
    const stylingProps: StylingProps = {
      $error: props.error || false,
      $active:
        props.active ||
        buttonFocusRing.isFocusVisible ||
        inputFocusRing.isFocused ||
        false,
      $disabled: props.disabled || false,
      $size: props.size,
    };

    const leftIcon = props.leftIcon ? (
      <IconByName
        name={props.leftIcon}
        width={props.leftIconWidth || 16}
        height={props.leftIconHeight || 16}
        ariaLabel={props.leftIconAriaLabel}
      />
    ) : null;

    const rightIcon = props.rightIcon ? (
      <IconByName
        name={props.rightIcon}
        width={props.rightIconWidth || 12}
        height={props.rightIconHeight || 12}
        ariaLabel={props.rightIconAriaLabel}
      />
    ) : null;

    return (
      <Wrapper {...stylingProps} {...props.labelProps}>
        {props.leftIcon && (
          <LeftIconWrapper
            {...stylingProps}
            {...(props.onLeftIconClick
              ? {
                  as: 'button',
                  type: 'button',
                  disabled: props.disabled,
                  onClick: (e) => {
                    e.preventDefault();
                    if (props.onLeftIconClick) {
                      props.onLeftIconClick();
                    }
                  },
                  'aria-label': props.leftIconClickableAriaLabel,
                }
              : { as: 'div' })}
          >
            {props.tooltipProps ? (
              <Tooltip {...props.tooltipProps}>{leftIcon}</Tooltip>
            ) : (
              leftIcon
            )}
          </LeftIconWrapper>
        )}
        <LabelsWrapper>
          {props.fieldLabel && (
            <FieldLabel {...stylingProps}>{props.fieldLabel}</FieldLabel>
          )}
          {props.type === 'button' ? (
            <ValueButton
              ref={buttonRef}
              type="button"
              aria-invalid={props.error}
              {...stylingProps}
              {...mergeProps(props.buttonProps, buttonFocusRing.focusProps)}
              disabled={props.disabled}
            >
              {props.label}
            </ValueButton>
          ) : (
            <InputWrapper>
              {props.inputPrefix && (
                <InputPrefix>{props.inputPrefix}</InputPrefix>
              )}
              <ValueInput
                ref={inputRef}
                aria-invalid={props.error}
                {...stylingProps}
                {...mergeProps(props.inputProps, inputFocusRing.focusProps)}
                disabled={props.disabled}
              />
            </InputWrapper>
          )}
        </LabelsWrapper>
        {props.rightIcon && (
          <RightIconWrapper
            {...stylingProps}
            {...(props.onRightIconClick
              ? {
                  as: 'button',
                  type: 'button',
                  disabled: props.disabled,
                  onClick: (e) => {
                    e.preventDefault();
                    if (props.onRightIconClick) {
                      props.onRightIconClick();
                    }
                  },
                  'aria-label': props.rightIconClickableAriaLabel,
                }
              : { as: 'div' })}
          >
            {rightIcon}
          </RightIconWrapper>
        )}
      </Wrapper>
    );
  }
);
