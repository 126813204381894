import React from 'react';
import { UseComboboxGetItemPropsOptions } from 'downshift';
import { DestinationsTypes } from '@supersonic/store';
import { SkeletonBlock } from '../../../skeleton';
import { LineItem } from '../../../lineItem';
import { getDestinationName } from '../utils';
import { device } from '../../../../css/sizes';
import styled from 'styled-components';

const ListLabel = styled.div.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})`
  padding: ${(props) => props.theme.spacing.unit * 2}px;
  @media ${device.md} {
    padding: ${(props) => props.theme.spacing.unit * 3}px
      ${(props) => props.theme.spacing.unit * 2}px;
  }
`;

const SkeletonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing.unit}px
    ${(props) => props.theme.spacing.unit * 2}px;
`;

const SkeletonIcon = styled(SkeletonBlock)`
  width: ${(props) => props.theme.spacing.unit * 2}px;
  height: ${(props) => props.theme.spacing.unit * 2}px;
  margin-right: ${(props) => props.theme.spacing.unit * 1.5}px; ;
`;

const SkeletonText = styled(SkeletonBlock)`
  flex: 1;
  height: ${(props) => props.theme.spacing.unit * 3}px;
`;

const NoResultsMessage = styled.div`
  padding: ${(props) => props.theme.spacing.unit}px
    ${(props) => props.theme.spacing.unit * 2}px;
  word-break: break-word;
`;

type Props = {
  items: DestinationsTypes.Destination[];
  menuType: 'popular destinations' | 'suggested destinations';
  isSuggestedDestinationsLoading: boolean;
  inputValue: string;
  getItemProps: (
    options: UseComboboxGetItemPropsOptions<DestinationsTypes.Destination>
  ) => any;
  highlightedIndex: number;
};

export const Menu = (props: Props) => {
  switch (props.menuType) {
    case 'suggested destinations':
      if (props.isSuggestedDestinationsLoading) {
        return (
          <div>
            {[...Array(5)].map((_, index) => (
              <SkeletonWrapper key={index}>
                <SkeletonIcon />
                <SkeletonText />
              </SkeletonWrapper>
            ))}
          </div>
        );
      }
      return props.items.length ? (
        <>
          {props.items.map((destination, index) => (
            <LineItem
              key={`${destination.sgh_codes}${index}`}
              wrapperProps={props.getItemProps({ item: destination, index })}
              text={getDestinationName(destination)}
              active={props.highlightedIndex === index}
              icon={destination.kind === 'place' ? 'GeoAlt' : 'Building'}
            />
          ))}
        </>
      ) : (
        <NoResultsMessage>No results for {props.inputValue}</NoResultsMessage>
      );
    case 'popular destinations':
    default:
      return (
        <>
          <ListLabel>Popular Destinations</ListLabel>
          {props.items.map((destination, index) => (
            <LineItem
              key={`${destination.sgh_codes}${index}`}
              wrapperProps={props.getItemProps({ item: destination, index })}
              text={getDestinationName(destination)}
              active={props.highlightedIndex === index}
              icon={destination.kind === 'place' ? 'GeoAlt' : 'Building'}
            />
          ))}
        </>
      );
  }
};
