import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Star } from '../icon';

const Wrapper = styled.div`
  display: flex;
  & svg {
    margin-right: 2px;
  }
`;

export interface StarRatingProps {
  rating: number;
  className?: string;
}

export const StarRating = ({ rating, className }: StarRatingProps) => {
  const theme = useTheme();
  const renderStars = (rating: number) => {
    const content = [];
    for (let i = 0; i < rating; i++) {
      content.push(
        <Star
          key={`filled${i}`}
          ariaLabel="star"
          height={16}
          width={16}
          color={theme?.starRatingConfig?.filledStarColor}
        />
      );
    }
    for (let i = 0; i < 5 - rating; i++) {
      content.push(
        <Star
          key={`unfilled${i}`}
          ariaLabel="star"
          height={16}
          width={16}
          color={theme?.starRatingConfig?.emptyStarColor}
        />
      );
    }
    return content;
  };

  return (
    <Wrapper
      role="img"
      className={className}
      aria-label={`Rated ${rating} out of 5 stars`}
      data-testid="star-rating"
    >
      {renderStars(rating)}
    </Wrapper>
  );
};
