import {
  generateCloudflareImageSrc,
  type CloudflareImageOptions,
} from '../generateCloudflareImageSrc';

type SourceWidthDescriptor = number;

type SourceOption = [CloudflareImageOptions, SourceWidthDescriptor];

export const generateCloudflareImageSrcset = (
  imgPath: string,
  cloudflareImagesUrl: string,
  sourceOptions: SourceOption[]
): string => {
  return sourceOptions
    .map(
      ([cloudflareImageOptions, sourceWithDescriptor]) =>
        `${generateCloudflareImageSrc(
          imgPath,
          cloudflareImagesUrl,
          cloudflareImageOptions
        )} ${sourceWithDescriptor}w`
    )
    .join(', ');
};
