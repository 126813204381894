import {
  PriceBucketWithAvailability,
  UseBudgetHistogramHookState,
} from './types';

const STEP = 50;

export const calculateSelectedPriceLimitationFromPriceRange = (
  priceRange: [number, number],
  priceLimitation: [number, number]
): [number | null, number | null] => {
  return [
    priceRange[0] <= priceLimitation[0]
      ? null
      : Math.min(priceRange[0], priceLimitation[1]),
    priceRange[1] >= priceLimitation[1]
      ? null
      : Math.max(priceRange[1], priceLimitation[0]),
  ];
};

export const calculateSelectedPriceLimitationAfterInputValueChange = (
  state: UseBudgetHistogramHookState,
  priceLimitation: [number, number],
  selectedPriceLimitation: [number | null, number | null]
): [number | null, number | null] => {
  const priceRangeMinInputValueNumber = state.isPriceRangeMinInputFocused
    ? parseInt(state.priceRangeMinInputValue, 10) || 0
    : selectedPriceLimitation[0] || priceLimitation[0];
  const priceRangeMaxInputValueNumber = state.isPriceRangeMaxInputFocused
    ? parseInt(state.priceRangeMaxInputValue, 10) || priceLimitation[1]
    : selectedPriceLimitation[1] || priceLimitation[1];
  let priceRange: [number, number] = priceLimitation;

  if (state.isPriceRangeMinInputFocused) {
    priceRange = [
      priceRangeMinInputValueNumber,
      priceRangeMinInputValueNumber > priceRangeMaxInputValueNumber
        ? priceRangeMinInputValueNumber + STEP
        : priceRangeMaxInputValueNumber,
    ];
  } else if (state.isPriceRangeMaxInputFocused) {
    priceRange = [
      priceRangeMaxInputValueNumber < priceRangeMinInputValueNumber
        ? priceRangeMaxInputValueNumber - STEP
        : priceRangeMinInputValueNumber,
      priceRangeMaxInputValueNumber,
    ];
  }

  return calculateSelectedPriceLimitationFromPriceRange(
    priceRange,
    priceLimitation
  );
};

export const initializePriceRangeAndInputs = (
  priceLimitation: [number, number],
  selectedPriceLimitation: [number | null, number | null]
): {
  priceRange: [number, number];
  priceRangeMinInputValue: string;
  priceRangeMaxInputValue: string;
} => {
  return {
    priceRange: [
      selectedPriceLimitation[0] || priceLimitation[0],
      selectedPriceLimitation[1] || priceLimitation[1],
    ],
    priceRangeMinInputValue: `${
      selectedPriceLimitation[0] || priceLimitation[0]
    }`,
    priceRangeMaxInputValue: selectedPriceLimitation[1]
      ? `${selectedPriceLimitation[1]}`
      : `${priceLimitation[1]}+`,
  };
};

export const reinitializePriceRangeAndInputs = (
  state: UseBudgetHistogramHookState,
  priceLimitation: [number, number],
  selectedPriceLimitation: [number | null, number | null]
): {
  priceRange: [number, number];
  priceRangeMinInputValue: string;
  priceRangeMaxInputValue: string;
} => {
  const initialPriceRangeAndInputs = initializePriceRangeAndInputs(
    priceLimitation,
    selectedPriceLimitation
  );
  return {
    priceRange: initialPriceRangeAndInputs.priceRange,
    priceRangeMinInputValue: state.isPriceRangeMinInputFocused
      ? state.priceRangeMinInputValue
      : initialPriceRangeAndInputs.priceRangeMinInputValue,
    priceRangeMaxInputValue: state.isPriceRangeMaxInputFocused
      ? state.priceRangeMaxInputValue
      : initialPriceRangeAndInputs.priceRangeMaxInputValue,
  };
};

export const calculatePriceBucketsWithAvailability = (
  priceBuckets: number[],
  priceLimitation: [number, number],
  priceRange: [number, number]
): PriceBucketWithAvailability[] => {
  const numberOfBuckets = priceBuckets.length;
  const bucketValue =
    (priceLimitation[1] - priceLimitation[0]) / numberOfBuckets;
  return priceBuckets.map((total, index) => {
    const bucketRange = [
      priceLimitation[0] + index * bucketValue,
      priceLimitation[0] + (index + 1) * bucketValue,
    ];
    const isBucketPartOfPriceRange =
      (priceRange[0] <= bucketRange[0] && priceRange[1] >= bucketRange[0]) ||
      (priceRange[0] <= bucketRange[1] && priceRange[1] >= bucketRange[1]);
    const isPriceRangePartOfBucket =
      (bucketRange[0] <= priceRange[0] && bucketRange[1] >= priceRange[0]) ||
      (bucketRange[0] <= priceRange[1] && bucketRange[1] >= priceRange[1]);
    return {
      total,
      disabled: !(isBucketPartOfPriceRange || isPriceRangePartOfBucket),
    };
  });
};
