import React from 'react';
import { SearchResultsTypes } from '@supersonic/store';
import styled, { ThemeColor, useTheme } from 'styled-components';
import { Airplane, ArrowRight, Clock } from '../../icon';

const FlightDateInfo = styled.div.attrs({
  className: 'font-hotel',
})`
  display: flex;
  color: ${(props) => props.theme.colors.grey6};
  margin-top: ${(props) => props.theme.spacing._3};
`;

const OutboundInboundLabel = styled.div<{
  outboundInboundLabelColor?: ThemeColor;
  direction: string;
}>`
  color: ${(props) =>
    props.outboundInboundLabelColor
      ? props.theme.colors[props.outboundInboundLabelColor]
      : props.theme.colors.primary};
  display: flex;
  margin-right: ${(props) => props.theme.spacing._1};
  > svg {
    transform: rotate(
      ${(props) => (props.direction === 'outbound' ? `90` : `270`)}deg
    );
    margin-right: ${(props) => props.theme.spacing._1};
  }
`;

const FlightTimeLabel = styled.div.attrs({
  className: 'font-echo',
})`
  margin-top: ${(props) => props.theme.spacing._2};
`;

const FlightDestinationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const FlightDestinationLabel = styled.div.attrs({
  className: 'font-golf',
})`
  display: flex;
  color: ${(props) => props.theme.colors.grey7};
  > svg {
    margin: 0 ${(props) => props.theme.spacing._05};
  }
`;

const FlightInfoWrapper = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing._1};
`;

const FlightInfoItem = styled.p.attrs({
  className: 'font-hotel',
})<{
  flightInfoItemBackgroundColor?: ThemeColor;
  flightInfoItemTextColor?: ThemeColor;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${(props) => props.theme.spacing._05};
  padding: ${(props) => props.theme.spacing._05}
    ${(props) => props.theme.spacing._1};
  border-radius: ${(props) => props.theme.spacing._2};
  color: ${(props) =>
    props.flightInfoItemTextColor
      ? props.theme.colors[props.flightInfoItemTextColor]
      : props.theme.colors.black};
  background-color: ${(props) =>
    props.flightInfoItemBackgroundColor
      ? props.theme.colors[props.flightInfoItemBackgroundColor]
      : props.theme.colors.grey3};

  > svg {
    margin-right: ${(props) => props.theme.spacing._05};
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

export interface FlightInfoProps {
  deal: SearchResultsTypes.Deal;
  direction: string;
  outboundInboundLabelColor?: ThemeColor;
  flightInfoItemBackgroundColor?: ThemeColor;
  flightInfoItemTextColor?: ThemeColor;
}

export const FlightInfo = ({
  deal,
  direction,
  outboundInboundLabelColor,
  flightInfoItemBackgroundColor,
  flightInfoItemTextColor,
}: FlightInfoProps) => {
  const theme = useTheme();
  const data = deal.flights[direction].segments[0];
  const directionLabel = direction.charAt(0).toUpperCase() + direction.slice(1);
  const formatFlightDate = (inputDate: string) => {
    const date = new Date(inputDate);
    return date.toLocaleDateString(theme.dates.locale, {
      timeZone: data.departureAirport.timezone,
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  const formatFlightTime = (inputDate: string) => {
    const dateObj = new Date(inputDate);
    return dateObj.toLocaleTimeString(theme.dates.locale, {
      // TODO: timezone needs to be correct outbound/inbound timezone taken from offers.flights in the response when we have API V2 in place
      timeZone: data.departureAirport.timezone,
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    });
  };

  const formatFlightDuration = (duration: number) => {
    const hours = Math.floor(duration / 60);
    const mins = duration % 60;
    const hoursLabel = hours > 1 ? 'hrs' : 'hr';
    const minsLabel = mins > 1 ? 'mins' : 'min';
    return `${hours} ${hoursLabel} ${mins} ${minsLabel}`;
  };

  const formatFlightSegments = (segments: number) => {
    const stops = segments - 1;
    const stopsLabel = stops > 1 ? 'stops' : 'stop';
    return segments < 2 ? 'Direct' : `${stops} ${stopsLabel}`;
  };

  return (
    <>
      <FlightDateInfo>
        <OutboundInboundLabel
          outboundInboundLabelColor={outboundInboundLabelColor}
          direction={direction}
          data-testid="inbound-outbound-label"
        >
          <Airplane ariaLabel="Airplane" height={16} width={16} />
          {directionLabel}
        </OutboundInboundLabel>
        {formatFlightDate(data.departureDatetime)}
      </FlightDateInfo>
      <FlightTimeLabel>
        {formatFlightTime(data.departureDatetime)} -{' '}
        {formatFlightTime(data.arrivalDatetime)}
      </FlightTimeLabel>
      <FlightDestinationWrapper>
        <FlightDestinationLabel>
          {data.departureAirport.name}
          <ArrowRight ariaLabel="Arrow right" height={16} width={16} />
          {data.arrivalAirport.name}
        </FlightDestinationLabel>
        <FlightInfoWrapper>
          <FlightInfoItem
            flightInfoItemBackgroundColor={flightInfoItemBackgroundColor}
            flightInfoItemTextColor={flightInfoItemTextColor}
            data-testid="flight-info-item"
          >
            <Clock ariaLabel="clock" height={12} width={12} />
            {formatFlightDuration(data.durationMinutes)}
          </FlightInfoItem>
          <FlightInfoItem
            flightInfoItemBackgroundColor={flightInfoItemBackgroundColor}
            flightInfoItemTextColor={flightInfoItemTextColor}
            data-testid="flight-info-item"
          >
            {data.flightProvider}
          </FlightInfoItem>
          <FlightInfoItem
            flightInfoItemBackgroundColor={flightInfoItemBackgroundColor}
            flightInfoItemTextColor={flightInfoItemTextColor}
            data-testid="flight-info-item"
          >
            {formatFlightSegments(deal.flights[direction].segments.length)}
          </FlightInfoItem>
        </FlightInfoWrapper>
      </FlightDestinationWrapper>
    </>
  );
};
