import React from 'react';
import * as Icon from '../icon';
import { BaseSvgProps } from '../baseSvg';

type Props = Omit<BaseSvgProps, 'children'> & {
  name: keyof typeof Icon;
};

export const IconByName = ({ name, ...baseSvgProps }: Props) => {
  const IconComponent = Icon[name];
  return <IconComponent {...baseSvgProps} />;
};
