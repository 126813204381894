import React, { useRef } from 'react';
import { createStore, Store } from '@supersonic/store';
import { ThemeProvider } from '../../themeProvider';
import { Footer } from '../footer';
import { Header } from '../header';
import { SecondaryGadget } from '../gadget/secondaryGadget';
import GlobalStyles from '../../css/globalStyle';
import {
  ConfigTheme,
  ConfigFooter,
  ConfigHeader,
  ConfigGadget,
  ConfigResultsPage,
} from '@supersonic/config';
import { StoreContext } from '../../storeProvider';

interface LayoutProps {
  footerConfig: ConfigFooter;
  headerConfig: ConfigHeader;
  themeConfig: ConfigTheme;
  gadgetConfig: ConfigGadget;
  resultsPageConfig: ConfigResultsPage;
  children: React.ReactNode;
}

export const Layout = ({
  themeConfig,
  footerConfig,
  headerConfig,
  gadgetConfig,
  resultsPageConfig,
  children,
}: LayoutProps) => {
  const storeRef = useRef<Store>();
  if (!storeRef.current) {
    storeRef.current = createStore({
      searchResultsConfig: {
        brokerApi: resultsPageConfig.brokerApi,
        resultsPageUrlStem: resultsPageConfig.urlStem,
        resultsPerPage: resultsPageConfig.resultsPerPage,
      },
      advertisingConfig: {
        brokerApi: resultsPageConfig.brokerApi,
        advertisingApi: resultsPageConfig.advertisingApi,
        site: resultsPageConfig.site,
      },
      destinationConfig: {
        autocompleteApi: gadgetConfig.destinationSelector.autocompleteApi,
      },
    });
  }

  return (
    <ThemeProvider theme={themeConfig}>
      <StoreContext.Provider value={storeRef.current}>
        <GlobalStyles />
        <Header
          backgroundColor={headerConfig?.theme?.backgroundColor}
          dividerColor={headerConfig?.theme?.dividerColor}
          logo={headerConfig?.logo}
          logoColor={headerConfig?.theme?.logoColor}
          logoHoverColor={headerConfig?.theme?.logoHoverColor}
          navigation={headerConfig?.navigation}
          navToggleBtnColor={headerConfig?.theme?.navToggleBtnColor}
        />
        <SecondaryGadget
          config={gadgetConfig}
          locale={themeConfig.dates.locale}
          timezone={themeConfig.dates.timezone}
        />
        {children}
        <Footer
          borderColor={footerConfig?.theme?.borderColor}
          backgroundColor={footerConfig?.theme?.backgroundColor}
          columns={footerConfig.columns ? footerConfig.columns : undefined}
          copyright={
            footerConfig.copyright ? footerConfig.copyright : undefined
          }
          headingColor={footerConfig?.theme?.headingColor}
          linkColor={footerConfig?.theme?.linkColor}
          linkHoverColor={footerConfig?.theme?.linkHoverColor}
          logos={footerConfig?.logos}
          logosColor={footerConfig?.theme?.logosColor}
          secondaryLinks={footerConfig?.secondaryLinks}
          socialLinks={footerConfig?.socialLinks}
          textColor={footerConfig?.theme?.textColor}
          socialMediaIconColor={footerConfig?.theme?.socialMediaIconColor}
        />
      </StoreContext.Provider>
    </ThemeProvider>
  );
};
