/*
TODO:
----
PageBackground colour should be set somewhere else?
*/

import React, { useEffect, useState } from 'react';
import { SearchResultsTypes, AdvertisingTypes } from '@supersonic/store';
import { useStore } from '../../storeProvider';
import { ResultCard, ResultCardProps } from './resultCard';
import styled, { ThemeColor, useTheme } from 'styled-components';
import { Button } from '../button';
import { device } from '../../css/sizes';
import { Elevation } from '../elevation';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { FilterGroup } from '../filtergroup';
import { InfoCircle } from '../icon';
import { Modal } from '../modal';
import { NoResults } from './noResults';
import { ToggleSwitch } from '../toggleSwitch';
import { Select } from '../select';
import { ProgressBar } from '../progressBar';
import { Pagination, PaginationValue } from '../pagination';
import { Adsense } from '../adsense';
import { Radio } from '../radio';
import { TipPositions, ArrowPositions, Tooltip } from '../tooltip';
import { InlineAd } from '../advertising/inlineAd';
import { RibbonAd } from '../advertising/ribbonAd';
export interface Props {
  dividerColor?: ThemeColor;
  compareMoreRowColor?: ThemeColor;
  linkColor?: ThemeColor;
  linkHoverColor?: ThemeColor;
  labelTextColor?: ThemeColor;
  priceTextColor?: ThemeColor;
  uspBackgroundColor?: ThemeColor;
  uspIconColor?: ThemeColor;
  outboundInboundLabelColor?: ThemeColor;
  flightInfoItemBackgroundColor?: ThemeColor;
  flightInfoItemTextColor?: ThemeColor;
  boardBasisBackgroundColor?: ThemeColor;
  yourSelectedPropertyBackgroundColor?: ThemeColor;
  offers: SearchResultsTypes.ResultItem[];
  filterData?: SearchResultsTypes.FilterData[] | undefined;
  totalDeals?: number | undefined;
  totalResults: number;
  currentPage: number;
  resultsPerPage: number;
  searchProgress?: number | undefined;
  inlineAdSlot0?: AdvertisingTypes.InlineAd;
  inlineAdSlot1?: AdvertisingTypes.InlineAd;
  ribbonAdSlot0?: AdvertisingTypes.RibbonAd;
}

export type OrderByValues = 'recommended' | 'price';

export type OrderDirection = 'asc' | 'desc' | null;

export type OrderByOption = {
  filterValue: OrderByValues;
  label: string;
  orderDirection: OrderDirection;
  value: string;
};

const PageBackground = styled.div`
  background-color: ${(props) => props.theme.colors.sand};
  padding-bottom: ${(props) => props.theme.spacing._6};
`;

const containerWidth = '628px';

const ResultsColumns = styled.div`
  margin: 0 ${(props) => props.theme.spacing._2};

  @media ${device.lg} {
    padding-top: ${(props) => props.theme.spacing._3};
    display: grid;
    grid-template-columns: 302px ${containerWidth} 302px;
    column-gap: ${(props) => props.theme.spacing._3};
    margin: auto;
    max-width: 1280px;
  }
`;

const ResultsColumnLeft = styled.div``;

const ResultsColumnCenter = styled.div``;

const ResultsColumnRight = styled.div`
  display: none;
  @media ${device.lg} {
    display: block;
  }
`;

const ProgressBarWrapper = styled.div<{
  show: boolean;
}>`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  padding: ${(props) => props.theme.spacing._4}
    ${(props) => props.theme.spacing._2} 0;
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 0.5s ease-in;
  @media ${device.lg} {
    width: ${containerWidth};
    padding: ${(props) => props.theme.spacing._3} 0;
  }
`;

export interface ResultsHeaderProps {
  show: boolean;
  inert?: string | null;
}

const ResultsHeader = styled.div.attrs<ResultsHeaderProps>((props) => ({
  'aria-hidden': props.show ? null : true,
  inert: props.show ? null : '',
}))<ResultsHeaderProps>`
  @media ${device.lg} {
    display: flex;
  }
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing._3};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 0.5s ease-in;
`;

const DealsCountWrapper = styled.p.attrs({
  className: 'font-echo',
})`
  margin: ${(props) => props.theme.spacing._5} 0
    ${(props) => props.theme.spacing._1};
  @media ${device.lg} {
    margin-top: 0;
  }
`;

const DealsCount = styled.span.attrs({
  className: 'font-echo',
})``;

const SortByWrapper = styled.div`
  display: none;
  @media ${device.lg} {
    display: block;
  }
`;

const FiltersAndSort = styled.div<{
  dividerColor?: ThemeColor;
}>`
  display: grid;
  grid-template-columns: repeat(2, auto);
  @media ${device.md} {
    grid-template-columns: ${(props) => props.theme.spacing.unit * 20}px ${(
        props
      ) => props.theme.spacing.unit * 20}px;
    justify-content: center;
  }
  column-gap: ${(props) => props.theme.spacing._2};
  background: ${(props) => props.theme.colors.sand_lighter1};
  border-bottom: 2px solid
    ${(props) =>
      props.dividerColor
        ? props.theme.colors[props.dividerColor]
        : props.theme.colors.black};

  margin: 0 -${(props) => props.theme.spacing._2};
  padding: 0 ${(props) => props.theme.spacing._2}
    ${(props) => props.theme.spacing._2};
  @media ${device.lg} {
    display: none;
  }
`;

const FilterSortButton = styled(Button)`
  width: 100%;
  height: auto;
  padding: ${(props) => props.theme.spacing._1};
`;

const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FiltersModalHeading = styled.h2.attrs({
  className: 'font-echo',
})<{
  dividerColor?: ThemeColor;
}>`
  margin: 0;
  text-align: center;
  padding: ${(props) => props.theme.spacing._2};
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid
    ${(props) =>
      props.dividerColor
        ? props.theme.colors[props.dividerColor]
        : props.theme.colors.black};
`;

const FiltersModalContent = styled.div`
  padding: ${(props) => props.theme.spacing._2};
  background-color: ${(props) => props.theme.colors.sand};
  > div {
    max-width: ${(props) => props.theme.spacing.unit * 64}px;
    margin-left: auto;
    margin-right: auto;
  }
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const FiltersModalFooterElevated = styled(Elevation)`
  padding: ${(props) => props.theme.spacing._2};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.colors.white};
`;

const FiltersClearAllButton = styled(Button)`
  padding: ${(props) => props.theme.spacing._1}
    ${(props) => props.theme.spacing._2};
  height: auto;
`;

const FiltersApplyButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing._2};
  padding: ${(props) => props.theme.spacing._1}
    ${(props) => props.theme.spacing._2};
  height: auto;
`;

const SortRadioGroupWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.spacing._1};
  padding: ${(props) => props.theme.spacing._2};
  > label:not(:last-child) {
    display: block;
    margin-bottom: ${(props) => props.theme.spacing._2};
  }
`;

const InfoTooltip = styled(Tooltip)`
  position: absolute;
  right: ${(props) => props.theme.spacing._2};

  > div {
    transform: translateX(-${(props) => props.theme.spacing._2});
  }
`;

export const Results = ({
  dividerColor,
  compareMoreRowColor,
  linkColor,
  linkHoverColor,
  labelTextColor,
  priceTextColor,
  uspBackgroundColor,
  uspIconColor,
  outboundInboundLabelColor,
  flightInfoItemBackgroundColor,
  flightInfoItemTextColor,
  boardBasisBackgroundColor,
  yourSelectedPropertyBackgroundColor,
  offers,
  totalDeals,
  totalResults,
  resultsPerPage,
  currentPage,
  searchProgress,
  filterData,
  inlineAdSlot0,
  inlineAdSlot1,
  ribbonAdSlot0,
}: Props) => {
  const destinationsStore = useStore((state) => state.destinations);
  const searchResults = useStore((state) => state.searchResults);
  const theme = useTheme();
  const isDesktop = useMediaQuery(device.lg);
  const [priceMode, setPriceMode] = useState('perperson');
  const [adsenseQuery, setAdsenseQuery] = useState<string | null>();

  useEffect(() => {
    if (destinationsStore?.currentDestination?.names) {
      setAdsenseQuery(destinationsStore.currentDestination.names.join());
    }
  }, [destinationsStore.currentDestination]);

  const handlePaginationChange = (pageNumber: PaginationValue) => {
    searchResults.setCurrentPage(pageNumber);
    searchResults.changeResults();
  };

  const priceModeToggleHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPriceMode(event.target.value);
  };

  const sortByOptions: OrderByOption[] = [
    {
      filterValue: 'recommended',
      value: 'recommended',
      orderDirection: null,
      label: 'Our recommendations',
    },
    {
      filterValue: 'price',
      value: 'cheapest',
      orderDirection: 'asc',
      label: 'Price low to high',
    },
    {
      filterValue: 'price',
      value: 'starrating',
      orderDirection: 'desc',
      label: 'Price high to low',
    },
  ];

  const [sortByValue, setSortByValue] = useState(sortByOptions[0].value);
  const updateSortByValue = (sortByValue: string) => {
    const selectedOption = sortByOptions.find(
      (opt) => opt.value === sortByValue
    );
    if (selectedOption) {
      searchResults.setOrderBy(selectedOption);
    }
    setSortByValue(sortByValue);
    searchResults.changeResults();
  };

  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [activeModalContent, setActiveModalContent] = useState('filters');

  const toggleFiltersModal = () => {
    if (showFiltersModal) {
      setShowFiltersModal(!showFiltersModal);
    } else {
      setShowFiltersModal(!showFiltersModal);
    }
  };

  const toggleModalContent = (content: string) => {
    setActiveModalContent(content);
  };

  const [deselectAllFiltersInAllGroups, setDeselectAllFiltersInAllGroups] =
    useState(false);

  const toggleSelectAllFilters = () => {
    setDeselectAllFiltersInAllGroups(!deselectAllFiltersInAllGroups);
  };

  const sortByToolTipProps = {
    width: 260,
    text: [
      <b key="recommendations">Our recommendations</b>,
      <span key="criteria">
        {' '}
        reflect your search criteria, the package's value for money and the
        renumeration provided by advertisers when a customer clicks an offer.
      </span>,
    ],
    tipPosition: isDesktop ? TipPositions.Top : TipPositions.Bottom,
    arrowPosition: isDesktop ? ArrowPositions.Center : ArrowPositions.Right,
  };

  const resultCardProps: ResultCardProps = {
    dividerColor,
    compareMoreRowColor,
    linkColor,
    linkHoverColor,
    labelTextColor,
    priceTextColor,
    uspBackgroundColor,
    uspIconColor,
    outboundInboundLabelColor,
    flightInfoItemBackgroundColor,
    flightInfoItemTextColor,
    boardBasisBackgroundColor,
    yourSelectedPropertyBackgroundColor,
    priceMode,
    loaded: false,
  };

  const filtersContent = () => {
    return (
      <div>
        {filterData?.length
          ? filterData.map(
              (filterData: SearchResultsTypes.FilterData, index: number) => {
                return (
                  <FilterGroup
                    key={index}
                    loaded={true}
                    borderColor={dividerColor}
                    displayName={filterData.displayName}
                    presentation={filterData.presentation}
                    values={filterData.values}
                    pricingBuckets={filterData.pricing?.buckets}
                    highestPrice={filterData.pricing?.highestPrice.total}
                    lowestPrice={filterData.pricing?.lowestPrice.total}
                    deselectAllFiltersInAllGroups={
                      deselectAllFiltersInAllGroups
                    }
                  />
                );
              }
            )
          : Array.from({ length: 7 }).map((_, index) => (
              <FilterGroup
                key={index}
                loaded={false}
                borderColor={dividerColor}
                displayName=""
              />
            ))}
      </div>
    );
  };

  const sortContent = () => {
    return (
      <SortRadioGroupWrapper>
        <InfoTooltip {...sortByToolTipProps}>
          <InfoCircle width={16} height={16} color={linkColor} />
        </InfoTooltip>
        {sortByOptions.map((sortByOption) => (
          <Radio
            key={sortByOption.value}
            checked={sortByOption.value === sortByValue}
            text={sortByOption.label}
            name="sortby"
            value={sortByOption.value}
            onChange={() => {
              updateSortByValue(sortByOption.value);
            }}
          />
        ))}
      </SortRadioGroupWrapper>
    );
  };

  const offersAndInlineAds: Array<
    | { offer: SearchResultsTypes.ResultItem }
    | { inlineAd: AdvertisingTypes.InlineAd }
  > = offers.map((offer) => ({ offer }));
  if (inlineAdSlot0) {
    offersAndInlineAds.splice(1, 0, { inlineAd: inlineAdSlot0 });
  }
  if (inlineAdSlot1) {
    offersAndInlineAds.splice(3, 0, { inlineAd: inlineAdSlot1 });
  }

  return (
    <PageBackground>
      {searchProgress === 100 && !offers.length ? (
        <ResultsColumns>
          <ResultsColumnLeft></ResultsColumnLeft>
          <ResultsColumnCenter>
            <NoResults isYourSelectedProperty={false} />
          </ResultsColumnCenter>
        </ResultsColumns>
      ) : (
        <>
          <ResultsColumns>
            {isDesktop && (
              <ResultsColumnLeft>{filtersContent()}</ResultsColumnLeft>
            )}
            <ResultsColumnCenter>
              <ProgressBarWrapper show={searchProgress === 100 ? false : true}>
                <ProgressBar now={searchProgress}></ProgressBar>
              </ProgressBarWrapper>
              <ResultsHeader show={searchProgress === 100 ? true : false}>
                <div>
                  {!isDesktop && (
                    <>
                      <FiltersAndSort dividerColor={dividerColor}>
                        <FilterSortButton
                          id="filter-button"
                          type="secondary"
                          text="Filter"
                          icon="Filter"
                          iconPos="left"
                          onClick={() => {
                            toggleFiltersModal();
                            toggleModalContent('filters');
                          }}
                        />
                        <FilterSortButton
                          id="sort-button"
                          type="secondary"
                          text="Sort"
                          icon="ArrowDownUp"
                          iconPos="left"
                          onClick={() => {
                            toggleFiltersModal();
                            toggleModalContent('sort');
                          }}
                        />
                      </FiltersAndSort>

                      <Modal
                        showModal={showFiltersModal}
                        onClose={toggleFiltersModal}
                        ariaLabelledby="filter-button"
                        fullscreen={true}
                      >
                        <ModalContent>
                          <FiltersModalHeading dividerColor={dividerColor}>
                            {activeModalContent === 'filters'
                              ? 'Filter'
                              : 'Sort'}
                          </FiltersModalHeading>
                          <FiltersModalContent>
                            {activeModalContent === 'filters'
                              ? filtersContent()
                              : sortContent()}
                          </FiltersModalContent>
                          <FiltersModalFooterElevated level={2}>
                            {activeModalContent === 'filters' && (
                              <FiltersClearAllButton
                                type="tertiary"
                                text="Clear all"
                                onClick={toggleSelectAllFilters}
                              />
                            )}
                            <FiltersApplyButton
                              type="primary"
                              text="Apply"
                              onClick={toggleFiltersModal}
                            />
                          </FiltersModalFooterElevated>
                        </ModalContent>
                      </Modal>
                    </>
                  )}

                  <DealsCountWrapper>
                    We found <DealsCount>{totalDeals} deals!</DealsCount>
                  </DealsCountWrapper>

                  <ToggleSwitch
                    name="pricemodeswitch"
                    legend="Show prices by"
                    labels={['Price per person', 'Total price']}
                    values={['perperson', 'total']}
                    defaultValue="perperson"
                    onChange={priceModeToggleHandler}
                  />
                </div>
                {isDesktop && (
                  <SortByWrapper>
                    <Select
                      fieldLabel="Sort by"
                      leftIcon="InfoCircle"
                      options={sortByOptions}
                      selectedOption={sortByValue}
                      onSelect={(sortByValue) =>
                        updateSortByValue(String(sortByValue))
                      }
                      disableBodyScrollWhenOpen={false}
                      width="15.5em"
                      tooltipProps={sortByToolTipProps}
                    />
                  </SortByWrapper>
                )}
              </ResultsHeader>

              {offersAndInlineAds.length ? (
                offersAndInlineAds.map((item, index) => {
                  if ('offer' in item) {
                    return (
                      <div key={item.offer.accommodation.accommodationId}>
                        <ResultCard
                          {...resultCardProps}
                          loaded={true}
                          accommodation={item.offer.accommodation}
                          deals={item.offer.partnerOffers}
                          priceMode={priceMode}
                          isYourSelectedProperty={
                            item.offer.accommodation.pinned
                          }
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={item.inlineAd.uuid}>
                        <InlineAd ad={item.inlineAd} />
                      </div>
                    );
                  }
                })
              ) : (
                <>
                  {[...Array(3)].map((_, index) => (
                    <ResultCard key={index} {...resultCardProps} />
                  ))}
                </>
              )}

              {searchProgress === 100 && totalResults ? (
                <Pagination
                  onChange={handlePaginationChange}
                  totalResults={totalResults}
                  resultsPerPage={resultsPerPage}
                  currentPage={currentPage}
                />
              ) : null}
            </ResultsColumnCenter>
            <ResultsColumnRight></ResultsColumnRight>
          </ResultsColumns>
          <ResultsColumns>
            <ResultsColumnLeft></ResultsColumnLeft>
            <ResultsColumnCenter>
              {adsenseQuery && (
                <Adsense
                  slotId={theme.advertising.adsense.holidaysResultsPageSlotId}
                  query={`Holidays in ${adsenseQuery}`}
                />
              )}
            </ResultsColumnCenter>
          </ResultsColumns>
        </>
      )}
      {ribbonAdSlot0 && <RibbonAd ad={ribbonAdSlot0} />}
    </PageBackground>
  );
};
