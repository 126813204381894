import React, { useState } from 'react';
import FocusLock from 'react-focus-lock';
import styled, { ThemeColor, css } from 'styled-components';
import { device } from '../../css/sizes';
import { Container } from '../container';
import { Icelolly, List, TravelSupermarket, XLg } from '../icon';
import { NavItem } from '../navigation/navItem';
import { ConfigHeaderLogo, ConfigNavigation } from '@supersonic/config';
import { tracking } from '../navigation/utils';

export interface HeaderProps {
  backgroundColor?: ThemeColor;
  dividerColor?: ThemeColor;
  logo?: ConfigHeaderLogo;
  logoColor?: ThemeColor;
  logoHoverColor?: ThemeColor;
  navigation?: ConfigNavigation;
  navToggleBtnColor?: ThemeColor;
}

const iconComponents = {
  icelolly: {
    name: Icelolly,
    width: 133,
    height: 40,
  },
  travelsupermarket: {
    name: TravelSupermarket,
    width: 225,
    height: 40,
  },
};

const HeaderContainer = styled.header<{
  backgroundColor?: ThemeColor;
  dividerColor?: ThemeColor;
}>`
  background: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.grey4};
  border-bottom: ${(props) =>
    props.dividerColor
      ? `1px solid ${props.theme.colors[props.dividerColor]}`
      : '0'};
  position: sticky;
  z-index: 2;
  top: 0;
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: ${(props) => props.theme.spacing._7};
  @media ${device.lg} {
    min-height: ${(props) => props.theme.spacing._11};
  }
  position: relative;
`;

const MenuButton = styled.button<{
  navToggleBtnColor?: ThemeColor;
  buttonType?: 'menu' | 'close';
  mobileNavOpen?: boolean;
}>`
  align-items: center;
  background: 0;
  border: 0;
  color: ${(props) =>
    props.navToggleBtnColor
      ? props.theme.colors[props.navToggleBtnColor]
      : props.theme.colors.black};
  display: flex;
  justify-content: center;
  ${(props) => (props.buttonType === 'close' ? 'right: 0;' : 'left: 0;')}
  ${(props) =>
    props.buttonType === 'close' &&
    css`
      opacity: ${props.mobileNavOpen ? '1' : '0'};
    `};
  transition: opacity 0.25s;
  padding: 0;
  position: absolute;
  &:focus-visible {
    outline: ${(props) =>
      props.navToggleBtnColor
        ? `3px solid ${props.theme.colors[props.navToggleBtnColor]}`
        : `3px solid ${props.theme.colors.black}`};
    outline-offset: 3px;
  }
  @media ${device.lg} {
    display: none;
  }
`;

const LogoContainer = styled.a<{
  logoColor?: ThemeColor;
  logoHoverColor?: ThemeColor;
}>`
  color: ${(props) =>
    props.logoColor
      ? props.theme.colors[props.logoColor]
      : props.theme.colors.black};
  max-width: 136px; /* logos are different sizes on mobile - how to scale/set different logos */
  transition: color 0.2s ease-out;
  &:hover,
  &:focus-visible {
    color: ${(props) =>
      props.logoHoverColor
        ? props.theme.colors[props.logoHoverColor]
        : props.theme.colors.grey7};
  }
  &:focus-visible {
    outline: ${(props) =>
      props.logoColor
        ? `3px solid ${props.theme.colors[props.logoColor]}`
        : `3px solid ${props.theme.colors.black}`};
    outline-offset: 3px;
  }
  @media ${device.lg} {
    max-width: none;
  }
`;

const NavContainer = styled.nav<{ toggle: boolean }>`
  align-self: stretch;
  background: ${(props) => props.theme.colors.white};
  display: ${(props) => (props.toggle ? 'block' : 'none')};
  height: 100vh;
  left: -${(props) => props.theme.spacing._2};
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100vw;
  @media ${device.lg} {
    background: transparent;
    display: flex;
    height: auto;
    margin-left: auto;
    position: static;
    width: auto;
  }
`;

const NavList = styled.ul`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const renderLogoIcon = (name: undefined | string) => {
  const iconName = name?.toLowerCase();
  if (iconName && iconName in iconComponents) {
    const ComponentObj =
      iconComponents[iconName as keyof typeof iconComponents];
    const Component = ComponentObj.name;
    return (
      <Component width={ComponentObj.width} height={ComponentObj.height} />
    );
  }
  return null;
};

export const Header = ({
  backgroundColor,
  dividerColor,
  logo,
  logoColor,
  logoHoverColor,
  navigation,
  navToggleBtnColor,
}: HeaderProps) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const toggleNav = (val: boolean) => {
    if (val) {
      document.body.classList.add('overflow-hidden');
      tracking('menu icon', undefined, 'click');
    } else {
      document.body.classList.remove('overflow-hidden');
      tracking('close icon', undefined, 'click');
    }
    setMobileNavOpen(val);
  };

  return (
    <FocusLock disabled={!mobileNavOpen} autoFocus={false}>
      <HeaderContainer
        backgroundColor={backgroundColor}
        dividerColor={dividerColor}
        data-testid="header-container"
      >
        <Container>
          <FlexContainer>
            <LogoContainer
              logoColor={logoColor}
              logoHoverColor={logoHoverColor}
              href="/"
              onClick={() => tracking('Logo', undefined, 'click')}
            >
              {renderLogoIcon(logo)}
              <span className="sr-only">Homepage</span>
            </LogoContainer>

            <MenuButton
              navToggleBtnColor={navToggleBtnColor}
              onClick={() => toggleNav(!mobileNavOpen)}
              aria-label={
                mobileNavOpen ? 'Close navigation menu' : 'Open navigation menu'
              }
              aria-expanded={mobileNavOpen}
              aria-controls="navigation-menu"
            >
              <List height={23} width={23} />
            </MenuButton>
            <MenuButton
              buttonType="close"
              mobileNavOpen={mobileNavOpen}
              navToggleBtnColor={navToggleBtnColor}
              onClick={() => toggleNav(!mobileNavOpen)}
              aria-label="Close navigation menu"
              aria-expanded={mobileNavOpen}
              aria-controls="navigation-menu"
            >
              <XLg height={20} width={20} />
            </MenuButton>

            <NavContainer toggle={mobileNavOpen} id="navigation-menu">
              <NavList>
                {navigation &&
                  navigation.links.map((item, index) => {
                    return (
                      <NavItem
                        key={index}
                        name={item.channel}
                        link={item.channelLink}
                        subnav={item.subnav}
                        subnavTheme={navigation?.theme?.subnav}
                        cards={item.cards}
                        borderColor={navigation?.theme?.borderColor}
                        chevronColor={navigation?.theme?.chevronColor}
                        focusColor={navigation?.theme?.focusColor}
                        linkColor={navigation?.theme?.linkColor}
                        linkHoverColor={navigation?.theme?.linkHoverColor}
                        mobileChevronColor={
                          navigation?.theme?.mobileChevronColor
                        }
                        tagColor={navigation?.theme?.card?.tagColor}
                      />
                    );
                  })}
              </NavList>
            </NavContainer>
          </FlexContainer>
        </Container>
      </HeaderContainer>
    </FocusLock>
  );
};

/* to do:
  stop focussing on subnav on mobile unless subnav is open
  any/more keyboard controls
  aria tags on show/hide elements
*/
