import React from 'react';
import styled, { css, ThemeColor } from 'styled-components';

//probably going to need a 'type' of link
//one that is underline by default, one that is not. different hover styles but share same focus styles
//both will need styles passing to it

const linkStyles = css<{
  $linkColor?: ThemeColor;
  $linkHoverColor?: ThemeColor;
}>`
  color: ${(props) =>
    props.$linkColor
      ? props.theme.colors[props.$linkColor]
      : props.theme.colors.black};
  display: inline-flex;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-skip-ink: auto;
    text-underline-offset: 2px;
  }
  &:hover,
  &:focus {
    color: ${(props) =>
      props.$linkHoverColor
        ? props.theme.colors[props.$linkHoverColor]
        : props.theme.colors.black};
  }
  &:focus {
    outline: ${(props) =>
      props.$linkHoverColor
        ? `3px solid ${props.theme.colors[props.$linkHoverColor]}`
        : `3px solid ${props.theme.colors.black}`};
    outline-offset: 3px;
  }
`;

const StyledLink = styled.a`
  ${linkStyles}
`;

interface LinkProps {
  ariaLabel?: string;
  to: string;
  linkColor?: ThemeColor;
  linkHoverColor?: ThemeColor;
  children: React.ReactNode;
  rel?: string;
}

export const Link = ({
  ariaLabel,
  children,
  linkColor,
  linkHoverColor,
  rel,
  to,
}: LinkProps) => {
  //nicked from https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
  // needs more testing and what types of links might there be? eg files
  const internal = /^\/(?!\/)/.test(to);

  return (
    <StyledLink
      aria-label={ariaLabel}
      $linkColor={linkColor}
      $linkHoverColor={linkHoverColor}
      href={to}
      rel={rel}
      target={internal ? undefined : '_blank'}
    >
      {children}
      {!internal && <span className="sr-only">(opens in new window)</span>}
    </StyledLink>
  );
};
