import React from 'react';
import { useStore } from '../../../storeProvider';
import { getSummaryTexts } from './utils';
import { InputField } from '../../inputField';

type Props = {
  locale: string;
  timezone: string;
  onClick: () => void;
};

export const SearchSummary = ({ locale, timezone, onClick }: Props) => {
  const [searchParams, currentDestination] = useStore((state) => [
    state.searchResults.searchParams,
    state.destinations.currentDestination,
  ]);
  let summaryTexts;
  if (searchParams && currentDestination) {
    summaryTexts = getSummaryTexts(
      searchParams,
      currentDestination,
      locale,
      timezone
    );
  }
  return (
    <InputField
      size="regular"
      type="button"
      label={summaryTexts?.primary || 'Where to?'}
      fieldLabel={summaryTexts?.secondary}
      leftIcon="Search"
      buttonProps={{ onClick }}
    />
  );
};
