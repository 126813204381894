import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../button';

type Props = {
  text: string;
  subText?: string;
  counter: number;
  onMinusButtonClick: () => void;
  onPlusButtonClick: () => void;
  isMinusButtonDisabled: boolean;
  isPlusButtonDisabled: boolean;
  minusButtonAriaLabel: string;
  plusButtonAriaLabel: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.unit * 3}px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Text = styled.div.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})``;
const SubText = styled.div.attrs({
  className: 'font-hotel',
})``;
const TextAndSubTextWrapper = styled.div`
  margin-right: auto;
`;
const Counter = styled.div.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})`
  margin: 0 ${(props) => props.theme.spacing.unit * 3}px;
  width: 10px;
`;

export const Controls = (props: Props) => {
  return (
    <Wrapper>
      <TextAndSubTextWrapper>
        <Text>{props.text}</Text>
        {props.subText && <SubText>{props.subText}</SubText>}
      </TextAndSubTextWrapper>
      <Button
        iconOnly={true}
        icon="DashLg"
        size="small"
        onClick={props.onMinusButtonClick}
        disabled={props.isMinusButtonDisabled}
        ariaLabel={props.minusButtonAriaLabel}
      />
      <Counter>{props.counter}</Counter>
      <Button
        iconOnly={true}
        icon="PlusLg"
        size="small"
        onClick={props.onPlusButtonClick}
        disabled={props.isPlusButtonDisabled}
        ariaLabel={props.plusButtonAriaLabel}
      />
    </Wrapper>
  );
};
