import React, { useState } from 'react';
import { useCalendar, useDateFormatter } from 'react-aria';
import styled, { css } from 'styled-components';
import { useCalendarState } from '@react-stately/calendar';
import { createCalendar, CalendarDate } from '@internationalized/date';
import { CalendarGrid } from '../calendarGrid';
import { Button } from '../../../button';

type Props = {
  value: CalendarDate | null;
  onChange: (newDate: CalendarDate) => void;
  minValue: CalendarDate;
  timezone: string;
  locale: string;
};

const Wrapper = styled.div``;
const MonthName = styled.div.attrs({
  className: 'font-foxtrot font-foxtrot--bold',
})`
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing.unit * 3}px;
`;
const MonthWrapper = styled.div<{ isLastMonth: boolean }>`
  margin-bottom: ${(props) => props.theme.spacing.unit * 3}px;
  ${(props) =>
    !props.isLastMonth &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
      padding-bottom: ${(props) => props.theme.spacing.unit * 3}px;
    `}
`;
const ShowMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const MobileCalendar = (props: Props) => {
  const [visibleMonths, setVisibleMonths] = useState(12);
  const state = useCalendarState({
    defaultFocusedValue: props.minValue,
    value: props.value,
    onChange: props.onChange,
    minValue: props.minValue,
    visibleDuration: { months: visibleMonths },
    locale: props.locale,
    createCalendar,
  });

  const { calendarProps } = useCalendar(
    {
      value: props.value,
      onChange: props.onChange,
      minValue: props.minValue,
    },
    state
  );

  const monthDateFormatter = useDateFormatter({
    month: 'long',
    year: 'numeric',
    timeZone: props.timezone,
  });

  const handleShowMoreMonthsClick = () => {
    setVisibleMonths(visibleMonths + 12);
  };

  return (
    <Wrapper {...calendarProps} data-testid="mobile-calendar">
      {[...new Array(visibleMonths).keys()].map((monthIndex) => (
        <MonthWrapper
          key={`month-${monthIndex}`}
          isLastMonth={visibleMonths === monthIndex + 1}
        >
          <MonthName>
            {monthDateFormatter.format(
              state.visibleRange.start
                .add({ months: monthIndex })
                .toDate(props.timezone)
            )}
          </MonthName>
          <CalendarGrid
            state={state}
            monthsOffset={monthIndex}
            locale={props.locale}
          />
        </MonthWrapper>
      ))}
      <ShowMoreButtonWrapper>
        <Button
          size="small"
          type="secondary"
          iconOnly={true}
          icon="Chevron"
          ariaLabel="Next"
          onClick={handleShowMoreMonthsClick}
        />
      </ShowMoreButtonWrapper>
    </Wrapper>
  );
};
