/* 
TODO:
----
Show provider logos rather than name
Should airport be departure and arrival for outbound, or departure for outbound and departure for inbound?
*/

import React from 'react';
import styled, { ThemeColor } from 'styled-components';
import { device } from '../../../css/sizes';
import { Image } from '../../image';
import { StarRating } from '../../starRating';

const AccommodationHeader = styled.div<{
  backgroundColor?: ThemeColor;
}>`
  padding: ${(props) => props.theme.spacing._2} 0;
  background: ${(props) => props.theme.colors.white};
  @media ${device.md} {
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing._4} 0;
    &:not(:has(img)) {
      margin-left: ${(props) => props.theme.spacing._3};
    }
  }
`;

const AccommodationHeading = styled.h3.attrs({
  className: 'font-golf font-golf--bold',
})`
  margin: 0 0 ${(props) => props.theme.spacing._05};
  color: ${(props) => props.theme.colors.black};
`;

const AccommodationLocation = styled.p.attrs({
  className: 'font-hotel',
})`
  color: ${(props) => props.theme.colors.grey7};
  margin: ${(props) => props.theme.spacing._05} 0 0;
`;

const AccommodationInfo = styled.div`
  color: ${(props) => props.theme.colors.black};
  padding: ${(props) => props.theme.spacing._2};
  @media ${device.md} {
    padding: 0;
    width: 70%;
  }
`;

const RoundedImage = styled.div`
  display: none;
  @media ${device.md} {
    border-radius: ${(props) => props.theme.spacing._1};
    display: block;
    min-width: 120px;
    overflow: hidden;
  }
`;

const ProviderLogo = styled.img`
  margin: 0 auto;
  @media ${device.md} {
    margin-right: ${(props) => props.theme.spacing._2};
  }
`;

export interface ModalHeaderProps {
  accommodationName: string;
  accommodationLocation: string;
  imageUrl?: string;
  provider?: string;
  providerImage?: string;
  starRating: number;
}

export const ModalHeader = ({
  accommodationName,
  accommodationLocation,
  imageUrl,
  provider,
  providerImage,
  starRating,
}: ModalHeaderProps) => {
  return (
    <AccommodationHeader>
      {providerImage && (
        <ProviderLogo
          width="80"
          alt={provider}
          src={providerImage}
          loading="lazy"
        />
      )}

      <AccommodationInfo data-testid="results-accommodation-description">
        <AccommodationHeading>{accommodationName}</AccommodationHeading>

        <StarRating rating={starRating} />

        <AccommodationLocation>{accommodationLocation}</AccommodationLocation>
      </AccommodationInfo>

      {imageUrl && (
        <RoundedImage>
          <Image
            src={imageUrl}
            alt={accommodationName}
            width={120}
            height={80}
            sizes={{
              sm: 120,
              md: 120,
              lg: 120,
            }}
          />
        </RoundedImage>
      )}
    </AccommodationHeader>
  );
};
