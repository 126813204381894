import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';

const SwitchFieldset = styled.fieldset.attrs({
  className: 'font-golf',
})`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;

  > label {
    line-height: ${(props) => props.theme.spacing._3};
    display: inline-flex;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  > label:first-of-type {
    padding-right: ${(props) => props.theme.spacing._3};
    margin-right: -${(props) => props.theme.spacing._5};
  }

  > label:last-of-type {
    padding-left: ${(props) => props.theme.spacing._5};
    margin-left: -${(props) => props.theme.spacing._11};
    order: 4;
  }

  > input:last-of-type:checked + label {
    padding-left: 0;
    margin-left: -${(props) => props.theme.spacing._6};
  }

  > input:last-of-type {
    transform: translateX(-${(props) => props.theme.spacing._6});
    background-color: transparent;
  }

  > label:first-of-type:after {
    content: '';
    width: ${(props) => props.theme.spacing._2};
    height: ${(props) => props.theme.spacing._2};
    background-color: ${(props) =>
      props.theme.colors[props.theme?.toggleSwitchConfig?.handleColor]};
    border-radius: ${(props) => props.theme.spacing._2};
    transform: translate(
      ${(props) => props.theme.spacing._3},
      ${(props) => props.theme.spacing._05}
    );
    transition: transform 0.1s;
  }

  > input:first-of-type:checked + label:after {
    transform: translate(
      ${(props) => props.theme.spacing._1},
      ${(props) => props.theme.spacing._05}
    );
  }
`;

const SwitchLegend = styled.legend.attrs({
  className: 'sr-only',
})``;

const Radio = styled.input`
  appearance: none;
  width: ${(props) => props.theme.spacing._5};
  height: ${(props) => props.theme.spacing._3};
  margin: 0 ${(props) => props.theme.spacing._05};
  background-color: ${(props) =>
    props.theme.colors[props.theme?.toggleSwitchConfig?.trackColor]};
  border-radius: ${(props) => props.theme.spacing._3};
  order: 2;
`;

export interface ToggleSwitchProps {
  name: string;
  legend: string;
  labels: Array<string>;
  values: Array<string>;
  defaultValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const handleKeydown = (e: KeyboardEvent<HTMLInputElement>, id: string) => {
  if (e.key === ' ' || e.key === 'Enter') {
    e.preventDefault();
    const elem = document.getElementById(id);
    if (elem) {
      elem.click();
      elem.focus();
    }
  }
};

export const ToggleSwitch = ({
  name,
  legend,
  labels,
  values,
  defaultValue,
  onChange,
}: ToggleSwitchProps) => {
  return (
    <SwitchFieldset>
      <SwitchLegend>{legend}</SwitchLegend>
      {labels.map((label, index) => {
        const ids = [`${name}_${values[0]}`, `${name}_${values[1]}`];
        const idtoSwitchTo = index === 0 ? ids[1] : ids[0];
        return (
          <React.Fragment key={index}>
            <Radio
              type="radio"
              name={name}
              id={ids[index]}
              value={values[index]}
              defaultChecked={values[index] === defaultValue}
              onChange={onChange}
              onKeyDown={(e) => handleKeydown(e, idtoSwitchTo)}
            />
            <label key={index} htmlFor={ids[index]}>
              {label}
            </label>
          </React.Fragment>
        );
      })}
    </SwitchFieldset>
  );
};
