import React, { useRef } from 'react';
import styled from 'styled-components';
import { Value } from './types';
import { useDateSelector } from './useDateSelector';
import { InputField, InputFieldHandle } from '../../inputField';
import { DesktopGadgetDropdown } from '../desktopGadgetDropdown';
import { MobileGadgetModal } from '../mobileGadgetModal';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { DesktopCalendar } from './desktopCalendar';
import { MobileCalendar } from './mobileCalendar';
import { WeekDays } from './weekDays';
import { device } from '../../../css/sizes';

export type Props = {
  value: Value;
  fieldLabel: string;
  locale: string;
  timezone: string;
  isOpen: boolean;
  error: boolean;
  onChange: (value: Value) => void;
  onOpen: () => void;
  onClose: () => void;
  onBack: () => void;
};

const Wrapper = styled.div`
  position: relative;
`;

const DateSelectorDesktopGadgetDropdown = styled(DesktopGadgetDropdown)`
  width: 475px;
`;

export const DateSelector = (props: Props) => {
  const isTabletOrDesktop = useMediaQuery(device.md);
  const selector = useDateSelector({
    value: props.value,
    onChange: props.onChange,
    timezone: props.timezone,
    locale: props.locale,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<InputFieldHandle>(null);

  return (
    <Wrapper ref={wrapperRef} data-testid="date-selector">
      <InputField
        type="button"
        size="regular"
        label={selector.label}
        labelProps={{
          htmlFor: 'date-selector-button',
          'data-testid': 'date-selector-input-field',
        }}
        leftIcon="CalendarDate"
        fieldLabel={props.fieldLabel}
        ref={inputFieldRef}
        active={props.isOpen}
        error={props.error}
        buttonProps={{
          id: 'date-selector-button',
          'aria-label': 'Select date',
          onClick: () => (props.isOpen ? props.onClose() : props.onOpen()),
        }}
      />
      {isTabletOrDesktop ? (
        <DateSelectorDesktopGadgetDropdown
          testId="date-selector-desktop-dropdown"
          isOpen={props.isOpen}
          onRequestClose={(reason) => {
            props.onClose();
            if (reason === 'escape-key') {
              inputFieldRef.current?.focus();
            }
          }}
          getTriggerElement={() => wrapperRef.current}
          headerText="Select your departure date"
          footerPrimaryButton={{
            onClick: props.onClose,
            text: 'Done',
          }}
        >
          <div data-testid="date-selector-content">
            <DesktopCalendar
              value={props.value}
              minValue={selector.minValue}
              onChange={selector.selectDate}
              locale={props.locale}
              timezone={props.timezone}
            />
          </div>
        </DateSelectorDesktopGadgetDropdown>
      ) : (
        <MobileGadgetModal
          testId="date-selector-mobile-modal"
          isOpen={props.isOpen}
          headerText="Select departure date"
          onClose={props.onClose}
          onBack={props.onBack}
          footerPrimaryButton={{
            onClick: props.onClose,
            text: 'Done',
          }}
          headerAdditionalContent={
            <WeekDays
              size="small"
              locale={props.locale}
              timezone={props.timezone}
            />
          }
        >
          <div data-testid="date-selector-content">
            <MobileCalendar
              value={props.value}
              onChange={selector.selectDate}
              minValue={selector.minValue}
              locale={props.locale}
              timezone={props.timezone}
            />
          </div>
        </MobileGadgetModal>
      )}
    </Wrapper>
  );
};
