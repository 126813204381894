import { DurationOption, Value } from './types';
export const DEFAULT_VALUE: Value = '0:7';

export const POPULAR_DURATIONS: DurationOption[] = [
  { value: '0:4', text: '4 nights' },
  { value: '0:7', text: '7 nights' },
  { value: '0:10', text: '10 nights' },
  { value: '0:14', text: '14 nights' },
];
export const OTHER_DURATIONS: DurationOption[] = [
  { value: '1:2', text: '2 nights' },
  { value: '1:3', text: '3 nights' },
  { value: '1:4', text: '4 nights' },
  { value: '1:5', text: '5 nights' },
  { value: '1:6', text: '6 nights' },
  { value: '1:7', text: '7 nights' },
  { value: '1:8', text: '8 nights' },
  { value: '1:9', text: '9 nights' },
  { value: '1:10', text: '10 nights' },
  { value: '1:11', text: '11 nights' },
  { value: '1:12', text: '12 nights' },
  { value: '1:13', text: '13 nights' },
  { value: '1:14', text: '14 nights' },
  { value: '1:15', text: '15 nights' },
  { value: '1:16', text: '16 nights' },
  { value: '1:17', text: '17 nights' },
  { value: '1:18', text: '18 nights' },
  { value: '1:19', text: '19 nights' },
  { value: '1:20', text: '20 nights' },
  { value: '1:21', text: '21 nights' },
];
