import React from 'react';

type Loading = 'lazy' | 'eager';

export interface ImageProps {
  src: string;
  alt: string;
  width: number;
  height: number;
  sizes: ImageSizes;
  loading?: Loading;
}

const defaultProps = {
  loading: 'lazy',
};

export interface ImageSizes {
  sm: number;
  md: number;
  lg: number;
}

const assembleSrc = (src: string, width: number, height: number) => {
  const srcParts = src.split('/');
  return `${srcParts[0]}//${srcParts[2]}/${srcParts[3]}/${width}x${height}/${srcParts[4]}`;
};

const ImgSrc = (props: ImageProps) => {
  const sizedSrc = assembleSrc(props.src, props.width, props.height);

  let srcSet = '';
  const generatedSizes: Array<number> = [];

  Object.entries(props.sizes).forEach(([size, width]) => {
    if (!generatedSizes.includes(width)) {
      generatedSizes.push(width);
      const multiplier = props.height / props.width;
      const height = Math.round(width * multiplier);
      srcSet += `${assembleSrc(props.src, width, height)} ${width}w,`;
      srcSet += `${assembleSrc(props.src, width * 2, height * 2)} ${
        width * 2
      }w,`;
    }
  });

  srcSet = srcSet.slice(0, -1); //remove last comma

  return (
    <img
      alt={props.alt}
      src={sizedSrc}
      width={props.width}
      height={props.height}
      srcSet={srcSet}
      loading={props.loading}
    />
  );
};

export const Image = ({
  src,
  alt,
  width,
  height,
  sizes,
  loading,
}: ImageProps) => {
  return (
    <ImgSrc
      src={src}
      alt={alt}
      width={width}
      height={height}
      sizes={sizes}
      loading={loading}
    />
  );
};

Image.defaultProps = defaultProps;
