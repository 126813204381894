import { ChildAgeOption, Value } from './types';

export const canAddPassenger = (value: Value) => {
  return value.adults + value.childAges.length < 9;
};

export const canRemoveAdult = (value: Value) => {
  return value.adults > 1;
};

export const canRemoveChild = (value: Value) => {
  return value.childAges.length > 0;
};

export const generateChildAgeOptions = (): ChildAgeOption[] => {
  return Array.from(Array(18), (_, index) => ({
    value: index,
    label: String(index),
  }));
};

export const getValueLabel = ({ adults, childAges }: Value) => {
  return `${adults} ${adults === 1 ? 'Adult' : 'Adults'}, ${childAges.length} ${
    childAges.length === 1 ? 'Child' : 'Children'
  }`;
};

export const validateChildAges = (
  childAges: Value['childAges']
): string | null => {
  if (childAges.some((childAge) => childAge === null)) {
    return 'Please select child age';
  }
  return null;
};
