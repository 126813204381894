interface PaginationItem {
  number: number;
  text: string;
  isActive: boolean;
  ariaLabel?: string;
}
export const createPages = (
  givenNumber: number,
  minNumber: number,
  maxNumber: number
): PaginationItem[] => {
  const numbers: PaginationItem[] = [];
  if (maxNumber === minNumber) {
    numbers.push({
      number: minNumber,
      text: minNumber.toString(),
      isActive: false,
    });
  } else {
    if (minNumber <= givenNumber - 1) {
      numbers.push({
        number: minNumber,
        text: minNumber.toString(),
        isActive: false,
      });
    }

    if (Number(givenNumber) - Number(minNumber) > 2) {
      numbers.push({
        number: givenNumber - 2,
        text: '...',
        isActive: false,
        ariaLabel: `Page ${givenNumber - 2}`,
      });
    }

    for (let i = givenNumber - 1; i <= givenNumber + 1; i++) {
      if (
        i >= minNumber &&
        i <= maxNumber &&
        !numbers.some((item) => item.number === i)
      ) {
        numbers.push({
          number: i,
          text: i.toString(),
          isActive: i === givenNumber,
        });
      }
    }

    if (maxNumber - Number(numbers[numbers.length - 2].number) > 2) {
      numbers.push({
        number: givenNumber + 2,
        text: '...',
        isActive: false,
        ariaLabel: `Page ${givenNumber + 2}`,
      });
    }

    if (
      maxNumber >= givenNumber + 1 &&
      !numbers.some((item) => item.number === maxNumber)
    ) {
      numbers.push({
        number: maxNumber,
        text: maxNumber.toString(),
        isActive: false,
      });
    }
  }

  return numbers;
};
